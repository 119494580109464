import { FC, useState, useMemo } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import Tooltip from "@mui/joy/Tooltip";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

import classes from "./ConfigurationList.module.css";
import ItemConfig from "./ItemConfig";
import { setActiveConfigResourceAsync } from "./AppConfigAPI";
import { AppVersionViewModel, SiteAppType } from "./AppConfigModels";
import { errorHandlerFunc } from "../../common/CommunicationErrorHandling";
import useDebounce from "../../common/Hooks/useDebounce";
import {  AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import SolarGikInput from "../../SolarGikLib/fields/Input";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/UserStore";

import SolarGikAlert from "../../SolarGikLib/alerts/Alert";

interface IConfigurationListProps {
  configList: AppVersionViewModel[] | undefined;
  onChange: () => void;
  updateRemoteDescription: (
    path: string,
    description: string
  ) => Promise<boolean>;
  siteApp: SiteAppType;
}

const ConfigurationList: FC<IConfigurationListProps> = ({
  configList,
  onChange,
  updateRemoteDescription,
  siteApp,
}) => {
  const userType: UserAccessType = useSelector(
    (state: RootState) => state.user.userType
  );

  const [filterValue, setFilterValue] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const debouncedFilteredValue = useDebounce(filterValue, 500);
  const debouncedFilteredList = useMemo(() => {
    if (!configList) {
      return configList;
    }
    const filterTextLower = debouncedFilteredValue.toLowerCase();
    const newFilteredList =
      configList?.filter(
        (version) =>
          version.uiVersion.toLowerCase().includes(filterTextLower) ||
          (version.description &&
            version.description.toLowerCase().includes(filterTextLower))
      ) || [];
    return newFilteredList;
  }, [debouncedFilteredValue, configList]);

  const isDisable = userType < UserAccessType.Engineer;

  const activateVersion = async (versionToActivate: AppVersionViewModel) => {
    try {
      await setActiveConfigResourceAsync(versionToActivate.dtoVersion, siteApp);
      onChange();
    } catch (error) {
      setAlertMessage({
        text: errorHandlerFunc(error, "Activate version"),
        severity: "error",
      });
    }
  };

  const activeVersion = useMemo(() => {
    const filteredList = configList?.filter((version) => version.isActive);
    if (filteredList?.length === 1) {
      return filteredList[0].uiVersion;
    }
    return null;
  }, [configList]);

  const filterInputAdornmentClickHandler = () => {
    if (!filterValue && activeVersion) {
      activeVersion && setFilterValue(activeVersion);
    } else if (!filterValue && !activeVersion) {
      setAlertMessage({
        text: "No active version",
        severity: "info",
      });
    } else {
      setFilterValue("");
    }
  };
  
  const isLoading = configList === undefined;

  return (
    <div className={classes.wrapper}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isLoading && <div>Loading...</div>}
      <div className={classes["filter-line"]}>
        <SolarGikInput
          label="Search term"
          placeholder="Search..."
          type="text"
          value={filterValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilterValue(e.target.value)
          }
        />
        <Tooltip
          title={filterValue ? "clear" : "Filter by active version"}
          variant="outlined"
        >
          <IconButton
            size="small"
            onClick={filterInputAdornmentClickHandler}
            className={classes["filter-icon"]}
          >
            {filterValue && <ClearIcon sx={{ color: "var(--error-color)" }} />}
            {!filterValue && (
              <TurnedInNotIcon sx={{ color: "var(--warning-color)" }} />
            )}
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes["config-list-wrapper"]}>
        <div className={classes["config-list"]}>
          {(debouncedFilteredList || configList)?.map((versionElement) => (
            <ItemConfig
              key={versionElement.uiVersion}
              versionElement={versionElement}
              isDisable={isDisable}
              setAlertMessage={setAlertMessage}
              activateVersionHandler={() => activateVersion(versionElement)}
              updateRemoteDescription={updateRemoteDescription}
              siteApp={siteApp}
            />
          ))}
          {(debouncedFilteredList?.length == 0 || configList?.length == 0) && (
            <div>{"No results found"}</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConfigurationList;
