import APP_CONFIG from "../../app/configuration/AppConfig";
import { toDateInSiteTimeZone } from "../../app/DateTimeUtils";
import { IChartSingleLineConfig } from "../models/TagChartModel";
import {
  IExtendedLineChartInfo,
  IStringToTimeValueListDictionary,
} from "../models/TagsModels";

//return a copy of the object with time in site time zone
export const changeResponseTimeToSiteZoneTime = (
  tagToValues: IStringToTimeValueListDictionary,
  timeZoneOffsetInMinutes: number
) =>
  Object.entries(tagToValues).reduce((acc, [key, values]) => {
    acc[key] = values.map((value) => ({
      ...value,
      time: toDateInSiteTimeZone(value.time, timeZoneOffsetInMinutes),
    }));
    return acc;
  }, {} as IStringToTimeValueListDictionary);

export const createLines = (
  tagToValues: IStringToTimeValueListDictionary,
  tagInfo: IExtendedLineChartInfo[]
): IChartSingleLineConfig[] => {
  const currentLinesConfig: IChartSingleLineConfig[] = [];
  if (tagToValues !== null && tagInfo && tagInfo.length > 0) {
    tagInfo.forEach((tag: IExtendedLineChartInfo, index: number) => {
      const line: IChartSingleLineConfig = {
        id: tag.id,
        yAxisRangeMin: tag.min,
        yAxisRangeMax: tag.max,
        displayName: tag.displayName,
        isCombinedYAxis: tag.isCombinedYAxis,
        values: tagToValues[tag.id],
        order: index,
        color: colors[index],
        isShowLine: true,
      };
      currentLinesConfig.push(line);
    });
  }
  return currentLinesConfig;
};

const colors = [
  "#E3CDFF",
  "#FFC56F",
  "#B2E1AF",
  "#A8D1DE",
  "#D76372",
  "#FFB9C2",
  "#CDFFFF",
  "#FFE99A",
  "#D7E7FF",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
];

export const getSamplingInterval = (start: Date, end: Date): string => {
  const diff = end.getTime() - start.getTime();
  if (diff <= numberOfMillisecondsInOneDay) {
    return APP_CONFIG.minRangeSamplingInterval;
  }
  if (diff <= numberOfMillisecondsInOneDay * 7) {
    return APP_CONFIG.mediumRangeSamplingInterval;
  }
  return APP_CONFIG.longRangeSamplingInterval;
};

export const numberOfMillisecondsInOneDay = 86400000;

export const maxDaysToDisplay = 7;
export const maxMonthsToDisplay = 3;
