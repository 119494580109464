import { FC } from "react";


import classes from "./ShowTrendsButton.module.css";
import SolarGikButton from "../../SolarGikLib/Button";
import {
  animatedButton,
} from "../../SolarGikLib/styles/ButtonsStyle";
interface ShowButtonProps {
  isShowUpdateButton: boolean;
  handleShowButtonClicked: () => void;
}

const ShowTrendsButton: FC<ShowButtonProps> = ({
  isShowUpdateButton,
  handleShowButtonClicked,
}) => {
  return (
    <div className={classes["show-button-container"]} >
      {isShowUpdateButton && (
        <SolarGikButton
          text="Update Trend"
          onClickFunc={handleShowButtonClicked}
          style={animatedButton} 
        ></SolarGikButton>
      )}
    </div>
  );
};
export default ShowTrendsButton;

