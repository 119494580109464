import axios from "axios";

import { ConfigNameAndDescription, SiteAppType } from "./AppConfigModels";
import APP_CONFIG from "../app/configuration/AppConfig";

const baseUrl = APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix;

export const getConfigListAsync = async (
  siteId: string, configVersion: string, siteApp: SiteAppType
): Promise<ConfigNameAndDescription[]> => {
  const url = `${baseUrl}${APP_CONFIG.apiGetConfigList}`;
  const params = { siteId: siteId, configVersionFilter: configVersion, siteApp: siteApp };
  const httpResponse = await axios.request<ConfigNameAndDescription[]>({ url, params });
  return httpResponse.data;
};

export const getConfigResourceAsync = async (resource: string, siteApp: SiteAppType) => {
  const url = `${baseUrl}${APP_CONFIG.apiDownloadConfigResource}/${resource}/${siteApp}`;
  const httpResponse = await axios.request<Uint8Array>({ url, responseType: "arraybuffer" });
  return httpResponse.data;
};

export const getActiveConfigAsync = async (siteId: string, siteApp: SiteAppType): Promise<string> => {
  const url = `${baseUrl}${APP_CONFIG.apiGetActiveConfig}/${siteId}/${siteApp}`;
  const httpResponse = await axios.get<string>(url);
  return httpResponse.data;
};

//uploads new config file and returns the id of the new file
export const uploadConfigResourceAsync = async (
  siteId: string, configSchemaVersion: string, fileData: Uint8Array, siteApp: SiteAppType
): Promise<string> => {
  const url = `${baseUrl}${APP_CONFIG.apiUploadConfigResource}/${siteId}_${configSchemaVersion}/${siteApp}`;
  const params = { file: fileData };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const newFileId = await axios.post<string>(url, params, headers);
  return newFileId.data;
};

export const setActiveConfigResourceAsync = async (resource: string, siteApp: SiteAppType): Promise<void> => {
  const url = `${baseUrl}${APP_CONFIG.apiSetActiveConfigResource}/${resource}/${siteApp}`;
  await axios.post<void>(url);
};

export const updateConfigAsync = async (siteId: string) => {
  const url = `${baseUrl}${APP_CONFIG.apiUpdateConfig}/${siteId}`;
  return await axios.put(url, undefined);
}

export const updateMetadataDescriptionAsync = async (description: string, resource: string, siteApp: SiteAppType) => {
  const url = `${baseUrl}${APP_CONFIG.apiSetMetadata}/${resource}/${siteApp}`;
  const params = { description: description };
  return await axios.post(url, params)
}