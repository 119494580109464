import TrendsController from "../features/multisite_trends/TrendsController";
import classes from "./Trends.module.css";

const Trends = () => {
  return (
    <div className={classes["container"]}>
      <div className={classes["trends-table"]}>
        <TrendsController />
      </div>
    </div>
  );
};
export default Trends;
