import { FC } from "react";

import { FormControlLabel } from "@mui/material";
import { Checkbox } from "antd";
import { ColorPicker } from "antd";

import classes from "./EditTrendPopupContent.module.css";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { ITrendLine, TrendsUserEvents } from "../TrendsModel";
import { ChartTypeEnum } from "../../../SolarGikLib/Model";

interface IEditTrendPopupContentProps {
  line: ITrendLine;
}

const EditTrendPopupContent: FC<IEditTrendPopupContentProps> = ({ line }) => {
  //the value can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (change: string, value: any) => {
    const event = new CustomEvent(change, {
      detail: { lineId: line.id, value: value },
    });
    document.dispatchEvent(event);
  };
  return (
    <div className={classes.container}>
      <div className={`${classes["column"]} ${classes["item"]}`}>
        <div>
          <ColorPicker
            onChange={(color) => {
              handleChange(
                TrendsUserEvents[TrendsUserEvents.ColorChange],
                color.toHexString()
              );
            }}
            styles={{
              popup: {
                borderRadius: "50%",
              },
            }}
          >
            <div
              className={classes["circle-btn"]}
              style={{
                backgroundColor: line.color,
                borderColor: line.color,
              }}
            />
          </ColorPicker>
        </div>
        <div className={`${TextEnum.h8} ${classes["color-text"]}`}>
          {"Color"}
        </div>
      </div>
      {line.chartType === ChartTypeEnum.line && (
        <div className={classes.item}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={line.isLineFill}
                onChange={(event) =>
                  handleChange(
                    TrendsUserEvents[TrendsUserEvents.FillChange],
                    event.target.checked
                  )
                }
                name={line.displayName}
              />
            }
            label={"Fill"}
            labelPlacement="bottom"
            componentsProps={{
              typography: {
                fontFamily: "var(--font-family)",
                fontSize: "10px",
                fontWeight: "regular",
                color: "var(--blue-color-primary)",
              },
            }}
          />
        </div>
      )}
      {line.chartType === ChartTypeEnum.line && (
        <div className={`${classes["item"]}`}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={line.isLineDashed}
                onChange={(event) =>
                  handleChange(
                    TrendsUserEvents[TrendsUserEvents.DashChange],
                    event.target.checked
                  )
                }
                name={line.displayName}
              />
            }
            label={"Dash"}
            labelPlacement="bottom"
            componentsProps={{
              typography: {
                fontFamily: "var(--font-family)",
                fontSize: "10px",
                fontWeight: "regular",
                color: "var(--blue-color-primary)",
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
export default EditTrendPopupContent;
