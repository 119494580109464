//the event types are not defined in the file
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo, useState } from "react";

import { TextField } from "@mui/material";
import { Formik, Form, FormikValues } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import SelectedTagsTable from "./selected_tags_table/SelectedTagsTable";
import classes from "./SiteTagsQueryUI.module.css";
import { HierarchyDic } from "./TrendsController";
import { initSiteTagsProps, InputFieldType, ITrendLine } from "./TrendsModel";
import { trendInputFieldStyling } from "../../common/Mui/MuiStyling";
import SolarGikAutocomplete from "../../SolarGikLib/fields/Autocomplete";
import getIcons from "../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import { METADATA_NUMBER_ID_AS_AGG } from "../data_point/TagUiNameMap";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import SiteNameComponent, { useSiteNamesFormatter } from "../site_visual_info/SiteNameComponent";

interface ISiteTagsQueryUIProps {
  hierarchyDic: HierarchyDic;
  chartType: ChartTypeEnum;
  linesConfig: ITrendLine[];
  handleAddTag: (values: FormikValues) => void;
  setIsShowUpdateButton: (isDataChanged: boolean) => void;
  focusedField?: InputFieldType;
  focusedFieldChanged: (fieldName: InputFieldType) => void;
}

const validationSchema = Yup.object({
  [InputFieldType.SiteId]: Yup.string().required("Site ID is required"),
  [InputFieldType.Category]: Yup.string().required("Select Category"),
  [InputFieldType.UiName]: Yup.string().required("Select a Tag Name"),
});

const SiteTagsQueryUI: FC<ISiteTagsQueryUIProps> = ({
  hierarchyDic,
  chartType,
  linesConfig,
  handleAddTag,
  setIsShowUpdateButton,
  focusedField,
  focusedFieldChanged: FocusedFieldChanged,
}) => {
  const siteIds = useSelector((state: RootState) => state.user.siteIds);
  const siteNameFormatter = useSiteNamesFormatter();
  const [isTagHasDeviceId, setIsTagHasDeviceId] = useState(true);
  const EMPTY_STRING = "";
  const onSubmitHandler = (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    handleAddTag(values);
    setSubmitting(false);
  };

  const ArrowIcon = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);
  const FocusedArrowDown = getIcons(
    FieldIcons.FocusedArrowDown,
    IconCategory.Fields
  );
  const autocompleteTextSize = TextEnum.h5;
  return (
    <>
      <Formik
        initialValues={initSiteTagsProps}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ values, setFieldValue, handleSubmit, handleBlur }) => {
          const handleInputChange = (
            fieldName: InputFieldType,
            newValue: string | number
          ) => {
            let isDeviceId;
            let newValues = { ...values };
            // resetOtherFields
            if (fieldName == InputFieldType.SiteId) {
              if (
                (hierarchyDic[newValue] &&
                  hierarchyDic[newValue][values.category] == null) ||
                hierarchyDic[newValue][values.category][values.uiName] ==
                null ||
                (isTagHasDeviceId &&
                  values.deviceId != null &&
                  hierarchyDic[newValue][values.category][values.uiName][
                  values.deviceId
                  ] == null)
              ) {
                setFieldValue(InputFieldType.Category, EMPTY_STRING); //reset category
                setFieldValue(InputFieldType.UiName, EMPTY_STRING); //reset tag name
                setFieldValue(InputFieldType.DeviceId, EMPTY_STRING); //reset device Id
                setIsTagHasDeviceId(false);
                newValues = {
                  ...values,
                  [InputFieldType.Category]: EMPTY_STRING,
                  [InputFieldType.UiName]: EMPTY_STRING,
                  [InputFieldType.DeviceId]: null,
                };
              }
            }
            if (fieldName == InputFieldType.Category) {
              setFieldValue(InputFieldType.UiName, EMPTY_STRING); //reset tag name
              setFieldValue(InputFieldType.DeviceId, EMPTY_STRING); //reset device Id
              newValues = {
                ...values,
                [InputFieldType.UiName]: EMPTY_STRING,
                [InputFieldType.DeviceId]: null,
              };
            }
            if (fieldName == InputFieldType.UiName) {
              setFieldValue(InputFieldType.DeviceId, EMPTY_STRING); //reset device Id
              newValues = {
                ...values,
                [InputFieldType.DeviceId]: null,
              };
              isDeviceId =
                values.siteId &&
                values.category &&
                Object.keys(
                  hierarchyDic[values.siteId][values.category][newValue]
                )[0] !== METADATA_NUMBER_ID_AS_AGG.toString();
            }
            setFieldValue(fieldName, newValue);
            newValues = { ...newValues, [fieldName]: newValue };
            if (
              newValues.siteId !== EMPTY_STRING &&
              newValues.uiName !== null &&
              newValues.uiName !== EMPTY_STRING &&
              ((isDeviceId && newValues.deviceId !== null) || !isDeviceId)
            ) {
              handleAddTag(newValues);
              setIsShowUpdateButton(true);
            }
            FocusedFieldChanged(fieldName);
          };

          const handleTagNameChange = (value: string) => {
            const isDeviceIds =
              Object.keys(
                hierarchyDic[values.siteId][values.category][value]
              )[0] !== METADATA_NUMBER_ID_AS_AGG.toString();
            setIsTagHasDeviceId(isDeviceIds);
          };
          return (
            <Form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.option}>
                <SolarGikAutocomplete
                  textSize={autocompleteTextSize}
                  options={siteIds}
                  getOptionLabel={(option) => siteNameFormatter(option)}
                  renderOption={(props, option) => <li {...props}>{<SiteNameComponent siteId={option} />}</li>}
                  popUpIcon={
                    focusedField === InputFieldType.SiteId ? (
                      <FocusedArrowDown />
                    ) : (
                      <ArrowIcon />
                    )
                  }
                  key={InputFieldType.SiteId}
                  id={InputFieldType.SiteId}
                  value={values[InputFieldType.SiteId]}
                  renderInputFunc={(params) => (
                    <TextField
                      sx={trendInputFieldStyling}
                      onBlur={handleBlur}
                      {...params}
                      focused={focusedField === InputFieldType.SiteId}
                      placeholder="Site"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(_event: any, value: any) => {
                    if (value && values.siteId !== value) {
                      handleInputChange(InputFieldType.SiteId, value);
                    }
                  }}
                />
              </div>
              <div className={classes.option}>
                <SolarGikAutocomplete
                  textSize={autocompleteTextSize}
                  options={Object.keys(hierarchyDic[values.siteId] || [])}
                  popUpIcon={
                    focusedField === InputFieldType.Category ? (
                      <FocusedArrowDown />
                    ) : (
                      <ArrowIcon />
                    )
                  }
                  key={InputFieldType.Category}
                  id={InputFieldType.Category}
                  value={values[InputFieldType.Category]}
                  disabled={values.siteId === EMPTY_STRING}
                  renderInputFunc={(params) => (
                    <TextField
                      sx={trendInputFieldStyling}
                      focused={focusedField === InputFieldType.Category}
                      onBlur={handleBlur}
                      {...params}
                      placeholder="Category"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(_: any, value: any) => handleInputChange(InputFieldType.Category, value)}
                />
              </div>
              <div className={classes.option}>
                <SolarGikAutocomplete
                  textSize={autocompleteTextSize}
                  isDropdownWidthFitContent={true}
                  popUpIcon={
                    focusedField === InputFieldType.UiName ? (
                      <FocusedArrowDown />
                    ) : (
                      <ArrowIcon />
                    )
                  }
                  disabled={values.category === EMPTY_STRING}
                  value={values[InputFieldType.UiName]}
                  key={InputFieldType.UiName}
                  id={InputFieldType.UiName}
                  options={
                    (hierarchyDic[values.siteId] &&
                      hierarchyDic[values.siteId][values.category] &&
                      Object.keys(
                        hierarchyDic[values.siteId][values.category]
                      )) ||
                    []
                  }
                  renderInputFunc={(params) => (
                    <TextField
                      sx={trendInputFieldStyling}
                      focused={focusedField === InputFieldType.UiName}
                      onBlur={handleBlur}
                      {...params}
                      placeholder="Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(_event: any, value: any) => {
                    if (
                      value !== null &&
                      value !== values.uiName
                    ) {
                      handleTagNameChange(value);
                      handleInputChange(
                        InputFieldType.UiName,
                        value
                      );
                    } else {
                      console.error(`${[InputFieldType.UiName]} is null`);
                    }
                  }}
                />
              </div>
              {isTagHasDeviceId && (
                <div className={classes.option}>
                  <SolarGikAutocomplete
                    textSize={autocompleteTextSize}
                    hidden={!isTagHasDeviceId}
                    popUpIcon={
                      focusedField === InputFieldType.DeviceId ? (
                        <FocusedArrowDown />
                      ) : (
                        <ArrowIcon />
                      )
                    }
                    disabled={values.uiName === EMPTY_STRING}
                    key={InputFieldType.DeviceId}
                    id={InputFieldType.DeviceId}
                    value={
                      values[InputFieldType.DeviceId]?.toString() ?? undefined
                    }
                    options={
                      (hierarchyDic[values.siteId] &&
                        hierarchyDic[values.siteId][values.category] &&
                        hierarchyDic[values.siteId][values.category][
                        values.uiName
                        ] &&
                        Object.keys(
                          hierarchyDic[values.siteId][values.category][
                          values.uiName
                          ]
                        ).filter((el) => el !== null)) ||
                      []
                    }
                    renderInputFunc={(params) => (
                      <TextField
                        sx={trendInputFieldStyling}
                        focused={focusedField === InputFieldType.DeviceId}
                        onBlur={handleBlur}
                        {...params}
                        placeholder="Device ID"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onSelect={(event: any) => {
                      if (
                        event !== null &&
                        event.target.value !== values.deviceId
                      ) {
                        handleInputChange(
                          InputFieldType.DeviceId,
                          event.target.value
                        );
                      } else {
                        console.error(`${[InputFieldType.DeviceId]} is null`);
                      }
                    }}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      <div
        className={classes["lines-box"]}
        style={{ height: linesConfig.length > 0 ? "fit-content" : "100%" }}
      >
        <div className={classes.table}>
          <SelectedTagsTable chartType={chartType} linesConfig={linesConfig} />
        </div>
      </div>
    </>
  );
};
export default memo(SiteTagsQueryUI);
