import { useEffect } from "react";

export function useKeyStrokeDetector(phrase: string, onDetected: () => void) {
    useEffect(() => {
        let currentInput = "";
        const handleKeyPress = (event: KeyboardEvent) => {
            currentInput = (currentInput + event.key).substring(
                currentInput.length + 1 - phrase.length
            );

            if (currentInput === phrase) {
                onDetected();
            }
        };

        document.addEventListener("keypress", handleKeyPress);

        // Clean up event listener
        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [phrase, onDetected]);
}