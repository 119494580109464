import { useEffect, useState } from "react";

const MIN_SCREEN_WIDTH = 750;
const MIN_SCREEN_HEIGHT = 450;
const MIN_WIDTH_2_HEIGHT_SCALE = 1.44;

const IsIncorrectScreenSize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const inBadScreen = width < MIN_SCREEN_WIDTH || height < MIN_SCREEN_HEIGHT || width / height < MIN_WIDTH_2_HEIGHT_SCALE;
  return inBadScreen;
};

export default IsIncorrectScreenSize;
