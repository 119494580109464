import * as Collections from "typescript-collections";

export const SPLIT_NAME_DELIMITER = ":";
const METADATA_NUMBER_ID_AS_MASTER = 0;
const METADATA_NUMBER_ID_AS_DEVICE_NUMBER = 1;
export const METADATA_NUMBER_ID_AS_AGG = 32767;

export enum TagUiCategory {
  Inverters = "Inverters",
  Trackers = "Trackers",
  Weather = "Weather",
  System = "System",
  Energy = "Energy",
  PowerMeter = "PowerMeter",
  Engineer = "Engineer",
  Agriculture = "Agriculture",
}

export class TagUiModel {
  constructor(
    public siteName: string,
    public tagUiCategory: TagUiCategory,
    public tagUiName: string,
    public deviceNumber: number | null,
    public isEngineerTag: boolean,
    public tagId: string
  ) { }
  displayName() {
    return (
      this.tagUiCategory +
      ":" +
      this.tagUiName +
      (this.deviceNumber ? ` ${this.deviceNumber}` : "")
    );
  }
  toString() {
    return (
      this.siteName +
      +SPLIT_NAME_DELIMITER +
      this.tagUiCategory +
      SPLIT_NAME_DELIMITER +
      this.tagUiName +
      (this.deviceNumber ? `:${SPLIT_NAME_DELIMITER}${this.deviceNumber}` : "")
    );
  }
}

export class TagUiModelMetadata {
  constructor(
    public tagUiCategory: TagUiCategory,
    public tagUiName: string,
    public isEngineerTag: boolean,
    public hasDeviceNumber: boolean
  ) { }
  toString() {
    return Collections.util.makeString(this);
  }
}

export class TagNameMetadataDetails {
  public readonly tagPrefixNoDevice: string;
  public readonly deviceNumber: number | null;
  public readonly masterNumberComponent: number | null;

  public constructor(tagName: string) {
    const tagParts = tagName.split(SPLIT_NAME_DELIMITER);
    if (tagParts.length !== 3 && tagParts.length !== 2) {
      throw new Error(`Invalid tag name: ${tagName}`);
    }

    if (tagParts.length == 2) {
      this.tagPrefixNoDevice = tagParts[0] + SPLIT_NAME_DELIMITER + tagParts[1];
      this.masterNumberComponent = null;
      this.deviceNumber = null;
      return;
    }

    const number = parseInt(tagParts[2]);
    if (isNaN(number)) {
      this.tagPrefixNoDevice = tagName;
      this.masterNumberComponent = null;
      this.deviceNumber = null;
    } else {
      this.tagPrefixNoDevice = tagParts[0] + SPLIT_NAME_DELIMITER + tagParts[1];
      this.masterNumberComponent =
        number === METADATA_NUMBER_ID_AS_AGG ||
          number === METADATA_NUMBER_ID_AS_MASTER
          ? number
          : null;
      this.deviceNumber = this.masterNumberComponent ? null : number;
    }
  }

  isDevice(): boolean {
    return this.deviceNumber !== null;
  }

  toString(): string {
    return this.tagPrefixNoDevice + SPLIT_NAME_DELIMITER + this.isDevice();
  }
  toJson(): string {
    return Collections.util.makeString(this);
  }
}

export const tagNameToTagUiMapMetadata: Collections.Dictionary<
  TagNameMetadataDetails,
  TagUiModelMetadata
> = initTagsMetadataMap();

export const tagUiToTagNameMap: Collections.Dictionary<
  TagUiModelMetadata,
  TagNameMetadataDetails
> = convertToTagUiMap(tagNameToTagUiMapMetadata);

function convertToTagUiMap(
  tagNameToTagUiMap: Collections.Dictionary<
    TagNameMetadataDetails,
    TagUiModelMetadata
  >
): Collections.Dictionary<TagUiModelMetadata, TagNameMetadataDetails> {
  const tagUiToTagNameMap = new Collections.Dictionary<
    TagUiModelMetadata,
    TagNameMetadataDetails
  >();
  tagNameToTagUiMap.forEach((k, v) => {
    if (tagUiToTagNameMap.containsKey(v)) {
      throw new Error(`Duplicate tagUiName: ${k} ${v}`);
    }
    tagUiToTagNameMap.setValue(v, k);
  });
  return tagUiToTagNameMap;
}

export function ConvertToTagDetailsDictionary(
  siteName: string,
  tagNames: string[],
  isAgriSite: boolean
): TagUiModel[] {
  const tagUiModels: TagUiModel[] = [];
  for (const tagName of tagNames) {
    const newTagMetadata = new TagNameMetadataDetails(tagName);
    const tagUiModelMetadata = tagNameToTagUiMapMetadata.getValue(newTagMetadata);
    const isUnknownTag = !tagUiModelMetadata;
    if (tagUiModelMetadata?.tagUiCategory == TagUiCategory.Agriculture && !isAgriSite) {
      continue;
    }
    const newModel = new TagUiModel(
      siteName,
      isUnknownTag ? TagUiCategory.Engineer : tagUiModelMetadata.tagUiCategory,
      isUnknownTag ? newTagMetadata.tagPrefixNoDevice : tagUiModelMetadata.tagUiName,
      newTagMetadata.deviceNumber,
      isUnknownTag || tagUiModelMetadata.isEngineerTag,
      tagName
    );
    tagUiModels.push(newModel);
    console.debug(
      `Unknown tag name - engineer tag: ${tagName} ${newModel} ${newTagMetadata}`
    );
  }
  return tagUiModels;
}


function initTagsMetadataMap(): Collections.Dictionary<
  TagNameMetadataDetails,
  TagUiModelMetadata
> {
  const tagUiMap = new Collections.Dictionary<
    TagNameMetadataDetails,
    TagUiModelMetadata
  >();
  // Agri tags
  const agriTags = [
    { name: "SoilHumidity", displayName: "Soil Humidity" },
    { name: "SoilTemperature", displayName: "Soil Temperature" },
    { name: "SoilPH", displayName: "Soil PH" },
    { name: "SoilConductivity", displayName: "Soil Conductivity" },
    { name: "SoilNitrogen", displayName: "Soil Nitrogen" },
    { name: "SoilPotassium", displayName: "Soil Potassium" },
    { name: "SoilPhosphorus", displayName: "Soil Phosphorus" },
    { name: "PlantPhotosyntheticallyActiveRadiationPpfd", displayName: "PAR" },
    { name: "RainFall", displayName: "Rain fall" },
    { name: "StemGrowth", displayName: "Stem Growth" },
  ]
  agriTags.forEach(({ name, displayName }) => {
    tagUiMap.setValue(
      new TagNameMetadataDetails(`Agri:${name}:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
      new TagUiModelMetadata(TagUiCategory.Agriculture, `${displayName} per device`, false, true)
    );
    tagUiMap.setValue(
      new TagNameMetadataDetails(`Agri:${name}:${METADATA_NUMBER_ID_AS_AGG}`),
      new TagUiModelMetadata(TagUiCategory.Agriculture, displayName, false, false)
    );
  });
  //Tracker tags
  tagUiMap.setValue(
    new TagNameMetadataDetails("Trackers:StateCount:Tracking"),
    new TagUiModelMetadata(TagUiCategory.Trackers, "All In Tracking", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Trackers:StateCount:Manual"),
    new TagUiModelMetadata(TagUiCategory.Trackers, "All In Maintenance", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Calculated:McsState:FieldState"),
    new TagUiModelMetadata(TagUiCategory.System, "Field State", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`TrackerStatus:CurrentState:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Trackers, "State Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`TrackerStatus:ShadingRatio:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Trackers, "Shading Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`TrackerStatus:CurrentElevation:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Trackers, "Elevation Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`TrackerStatus:ErrorType:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Trackers, "Issues Per Device", false, true)
  );
  // Power meter tags
  tagUiMap.setValue(
    new TagNameMetadataDetails(`PowerMeter:ProductionDelta:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Production Delta", true, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`PowerMeter:ProductionTotal:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Production Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`PowerMeter:ErrorType:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Issues Per Device", false, true)
  );
  // System tags
  tagUiMap.setValue(
    new TagNameMetadataDetails("PowerMeter:AggrStatus"),
    new TagUiModelMetadata(TagUiCategory.System, "Status", true, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`McsState:UpsEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`),
    new TagUiModelMetadata(TagUiCategory.System, "Emergency Ups", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`McsState:EngineerEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`),
    new TagUiModelMetadata(TagUiCategory.System, "Emergency SG Engineer", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`McsState:CloudEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`),
    new TagUiModelMetadata(TagUiCategory.System, "Emergency Soma User", false, false)
  );
  // Energy tags
  tagUiMap.setValue(
    new TagNameMetadataDetails('Calculated:PotentialPowerProduction'),
    new TagUiModelMetadata(TagUiCategory.Energy, "Potential", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails('Calculated:ContractPowerProduction'),
    new TagUiModelMetadata(TagUiCategory.Energy, "Contract", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`McsState:State:${METADATA_NUMBER_ID_AS_MASTER}`),
    new TagUiModelMetadata(TagUiCategory.Energy, "State", true, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Calculated:TotalDaily:Sp"),
    new TagUiModelMetadata(TagUiCategory.Energy, "Specific Prod' Daily", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Calculated:Current:PerformanceIndex"),
    new TagUiModelMetadata(TagUiCategory.Energy, "Perf' Index", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Inverter:AcPower:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Energy, "Ac Power", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Inverter:DcPower${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Energy, "Dc Power", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Calculated:TotalDaily:AcPower"),
    new TagUiModelMetadata(TagUiCategory.Energy, "Ac Power Daily", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Calculated:TotalDaily:DcPower"),
    new TagUiModelMetadata(TagUiCategory.Energy, "Dc Power Daily", false, false)
  );
  // Inverters tags
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Inverter:AcPower:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Inverters, "AC Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Inverter:ErrorType:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Inverters, "Issues Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Inverter:DcPower:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Inverters, "DC Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Inverter:AggrStatus"),
    new TagUiModelMetadata(TagUiCategory.Inverters, "Status", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Inverter:ErrorType:ErrorCount"),
    new TagUiModelMetadata(TagUiCategory.Inverters, "All Severe Issues", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Inverter:ErrorType:WarnCount"),
    new TagUiModelMetadata(TagUiCategory.Inverters, "All Mild Issues", false, false)
  );
  // Weather tags
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:ErrorType:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Issues Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:WindSpeed:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Speed", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:WindDirection:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Direction", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:BackOfModuleTemp:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Temperature", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:RelativeHumidity:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Humidity", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:BarometricPressure:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Barometric presume", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Weather:AggrStatus"),
    new TagUiModelMetadata(TagUiCategory.Weather, "Status", true, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:WindSpeed:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Speed Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Weather:ErrorType:ErrorCount"),
    new TagUiModelMetadata(TagUiCategory.Weather, "All Severe Issues", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails("Weather:ErrorType:WarnCount"),
    new TagUiModelMetadata(TagUiCategory.Weather, "All Mild Issues", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:EstimatedDni:${METADATA_NUMBER_ID_AS_MASTER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "DNI", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:GlobalIrradiance:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "GHI", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:GlobalIrradiance:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Irradiance Per Device ", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:RelativeHumidity:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Humidity Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:AmbientAirTemp:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Air Temperature", false, false)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:AmbientAirTemp:${METADATA_NUMBER_ID_AS_DEVICE_NUMBER}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "Air Temperature Per Device", false, true)
  );
  tagUiMap.setValue(
    new TagNameMetadataDetails(`Weather:PlaneOfArrayIrradiance:${METADATA_NUMBER_ID_AS_AGG}`),
    new TagUiModelMetadata(TagUiCategory.Weather, "POA", true, false)
  );
  return tagUiMap;
}