import { Fragment, useEffect, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

import classes from "./History.module.css";
import useDebounce from "../common/Hooks/useDebounce";
import { btnStyling } from "../common/Mui/MuiStyling";
import {
  getStartDayFromUtc,
  getEndDayFromUtc,
  getCurrentDateInSiteTimeZone,
  getBeginningOfStartInUtcFromDateOnly,
  getDayEndInUtcFromDateOnly,
  getMidnightInUtcFromDateOnly,
  getDayStartInUtcFromDateOnly,
  getDayStartInUtcFromDateOnlyForMoreThanOneDay,
} from "../features/app/DateTimeUtils";
import { RootState } from "../features/app/Store";
import { getSamplingInterval } from "../features/data_point/charts/ChartTagsUtils";
import DateTimeRange from "../features/History/DateTimeRange";
import HistoryChartsSection from "../features/History/HistoryChartsSection";
import { TextEnum } from "../SolarGikLib/TextStyles";

const History = () => {
  const zoneOffsetTimeInMinutes = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes,
    shallowEqual
  );
  const isNightTimeRef = useRef(false);
  const [requestedRangeTime, setRequestedRangeTime] = useState({
    start: getCurrentDateInSiteTimeZone(zoneOffsetTimeInMinutes),
    end: getCurrentDateInSiteTimeZone(zoneOffsetTimeInMinutes),
  });
  const debouncedRequestedRangeTime = useDebounce(requestedRangeTime, 500);

  useEffect(() => {
    const todayDate = getCurrentDateInSiteTimeZone(zoneOffsetTimeInMinutes);
    handleRangeChange([todayDate, todayDate]);
  }, []);

  const handleIncludeNightTimeClicked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    isNightTimeRef.current = event.target.checked;
    handleRangeChange([requestedRangeTime.start, requestedRangeTime.end]);
  };
  // dates make us problems when we work with types its need to explore the types of antd for our components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (dates: any) => {
    const startTime = new Date(dates[0]);
    const endTime = new Date(dates[1]);
    const diffInDays = Math.abs(
      (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60 * 24)
    );
    const start = isNightTimeRef.current
      ? getStartDayFromUtc(
          startTime,
          zoneOffsetTimeInMinutes,
          getBeginningOfStartInUtcFromDateOnly
        )
      : getStartDayFromUtc(
          startTime,
          zoneOffsetTimeInMinutes,
          diffInDays >= 7
            ? getDayStartInUtcFromDateOnlyForMoreThanOneDay
            : getDayStartInUtcFromDateOnly
        );
    const end = isNightTimeRef.current
      ? getEndDayFromUtc(
          endTime,
          zoneOffsetTimeInMinutes,
          getMidnightInUtcFromDateOnly
        )
      : getEndDayFromUtc(
          endTime,
          zoneOffsetTimeInMinutes,
          getDayEndInUtcFromDateOnly
        );
    setRequestedRangeTime({ start: start, end: end });
    console.log("{ start: start, end: end }", { start: start, end: end });
  };
  const samplingInterval = getSamplingInterval(
    debouncedRequestedRangeTime.start,
    debouncedRequestedRangeTime.end
  );
  
  return (
    <Fragment>
      <div className={classes["date-selector"]}>
        <DateTimeRange updateRange={handleRangeChange} />
      </div>
      <FormControlLabel
        control={
          <Checkbox className={classes["checkbox"]} sx={btnStyling} onChange={handleIncludeNightTimeClicked} />
        }
        label={<span className={TextEnum.h4}>{"Include night time"}</span>}
      />
      <HistoryChartsSection
        requestedRangeTime={debouncedRequestedRangeTime}
        samplingInterval={samplingInterval}
      />
    </Fragment>
  );
};
export default History;
