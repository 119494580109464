import { FC } from "react";
import classes from './DescriptionCell.module.css';
import LightTooltip from "../../../../SolarGikLib/tooltip/LightTooltip";

interface IDescriptionCellProps {
    description: string
}

const DescriptionCell: FC<IDescriptionCellProps> = ({ description }) => {
    return (
        <LightTooltip
            title={description}
            customTooltipSx={{ padding: "1.2em", fontSize: 14 }}
        >
            <div className={classes.description}>
                {description}
            </div>
        </LightTooltip>
    );
};

export default DescriptionCell;