import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useSelector } from "react-redux";
import AgriTableColumns from "./AgriTableColumns";
import classes from "./AgriPlan.module.css";
import Table from "../../../../common/table/Table";
import IconLoader from "../../../html_elements/IconLoader";
import { IAgriDailyPlan, IAgriDailyPlanDto } from "../AgriModels";
import AgriActions from "../AgriCommands";
import {
  getAgriPlanTemplateAsync,
  getDailyPlanAsync,
  uploadNewPlanAsync,
} from "../AgriApi";
import Card from "../../../../SolarGikLib/cards/Card";
import { AlertMessage } from "../../../../SolarGikLib/alerts/AlertModels";
import getIcons from "../../../../SolarGikLib/icons/Icons"
import {
  FileCommandsIcons,
  IconCategory,
  SomaIcons,
} from "../../../../SolarGikLib/icons/IconsModels";
import { TextEnum } from "../../../../SolarGikLib/TextStyles";
import { IButtonVariant } from "../../../../common/Mui/StyledButton";
import Upload from "../../crudCommands/Upload";
import SolarGikAlert from "../../../../SolarGikLib/alerts/Alert";
import {
  COMMUNICATION_CSV_HEADER,
  CSV_FILE_SUFFIX,
} from "../../../../common/ConstantValues";
import { createAndDownloadBlobFile } from "../../../../common/DownloadFileUtils";
import SolarGikButton from "../../../../SolarGikLib/Button";
import { narrowBlueButton } from "../../../../SolarGikLib/styles/ButtonsStyle";
import ContactSupportErrorMessage from "../../../../SolarGikLib/alerts/ContactSupportErrorMessage";
import { selectSiteId } from "../../../sites/SiteStore";
const getRowId = (row: IAgriDailyPlan) => row.localDate.toString();

const AgriPlan = () => {
  const [isPlanExist, setIsPlanExist] = useState<boolean>(false);
  const [todaysPlan, setTodaysPlan] = useState<IAgriDailyPlan[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const siteId = useSelector(selectSiteId);
  const [isShowContentSupportError, showContactSupportError] = useState(false);
  const buttonVariant: IButtonVariant = { variant: "outlined" };
  const ClockIcon = getIcons(SomaIcons.Clock, IconCategory.Soma);
  const DownloadIcon = getIcons(
    FileCommandsIcons.WhiteDownload,
    IconCategory.FileCommands
  );

  const getTodaysPlanAsync = async () => {
    showContactSupportError(false);
    setAlertMessage(undefined);
    const todaysPlanUi: IAgriDailyPlan[] = [];
    try {
      const todaysPlan: IAgriDailyPlanDto[] = await getDailyPlanAsync(siteId);
      todaysPlan.forEach((plan: IAgriDailyPlanDto) => {
        todaysPlanUi.push({
          localDate: plan.LocalTime,
          shadingRatio:
            plan.ShadingRatio || plan.ShadingRatio === 0
              ? plan.ShadingRatio
              : "Tracking",
        });
      });
      setIsPlanExist(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response?.status === 404) {
        setIsPlanExist(false);
      } else {
        setAlertMessage({
          text: "Failed to get plan",
          severity: "error",
        });
        console.error(error.message);
      }
    } finally {
      setTableLoading(false);
      setTodaysPlan(todaysPlanUi);
    }
  };

  const handleUploadFailed = (error: AxiosError) => {
    console.error("handle upload failed", error);
    if (axios.isAxiosError(error) && error.status === 404) {
      setAlertMessage({
        text: "No plan exists for this site",
        severity: "error",
      });
    } else if (axios.isAxiosError(error) && typeof error.status === 'number' && error.status >= 500) {
      setAlertMessage({
        text: "Plan upload failed",
        severity: "error",
      });
      showContactSupportError(true);
    } else {
      setAlertMessage({
        text: "Plan upload failed",
        severity: "error",
      });
    }
  };

  const handleDownloadFailed = (error: AxiosError) => {
    if (typeof error.status === 'number' && error.status >= 500) {
      setAlertMessage({
        text: "Plan upload failed",
        severity: "error",
      });
      showContactSupportError(true);
    } else {
      setAlertMessage({
        text: "Plan download failed",
        severity: "error",
      });
    }
  };

  const handlePlanDeleted = () => {
    showContactSupportError(false);
    setTodaysPlan([]);
    setIsPlanExist(false);
  };

  const handleDownloadPlanTemplate = async () => {
    showContactSupportError(false);
    setAlertMessage(undefined);
    try {
      const planTemplate = await getAgriPlanTemplateAsync();
      createAndDownloadBlobFile(
        planTemplate,
        "AgriPlanTemplate",
        COMMUNICATION_CSV_HEADER,
        CSV_FILE_SUFFIX
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleDownloadFailed(error);
    }
  };

  useEffect(() => {
    getTodaysPlanAsync();
  }, []);
  const buttonStyle = { ...narrowBlueButton, "@media screen and (max-width: 1300px)": { fontSize: "10px", margin: 0 } };
  return (
    <Card
      contentStyle={{ marginBottom: 0 }}
      title={isPlanExist ? "Shading plan" : ""}
      actions={
        isPlanExist && (
          <AgriActions
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            siteId={siteId}
            getTodaysPlan={getTodaysPlanAsync}
            handleDeleted={handlePlanDeleted}
            isPlanExist={isPlanExist}
            handleUploadFailed={handleUploadFailed}
          />
        )
      }
    >
      {isPlanExist ? (
        <div className={classes.table}>
          <div className={classes["table-content"]}>
            <IconLoader isLoading={tableLoading}>
              <Table
                rows={todaysPlan}
                columns={AgriTableColumns}
                getRowId={getRowId}
                hideToolbar
                hideFooter
                noRowsMessage="No shading plan"
              />
            </IconLoader>
          </div>
        </div>
      ) : (
        <div className={classes.column}>
          <ClockIcon />
          <Box style={{ flex: 0.05 }} />
          <div
            className={TextEnum.h1}
            style={{ lineHeight: "28px", whiteSpace: "pre-wrap" }}
          >
            {"There is\nno shading \nplan yet"}
          </div>
          <Box style={{ flex: 0.05 }} />
          <div
            className={TextEnum.h5}
            style={{ lineHeight: "14px", whiteSpace: "pre-wrap" }}
          >
            {"The system is in regular \ntracking mode"}
          </div>
          <Box style={{ flex: 0.1 }} />
          <SolarGikAlert
            message={alertMessage}
            setMessage={setAlertMessage}
            isCloseIconVisible={false}
          />
          {isShowContentSupportError && (
            <>
              {" "}
              <Box style={{ flex: 0.05 }} />
              <ContactSupportErrorMessage />
            </>
          )}

          <Box style={{ flex: 0.05 }} />
          <Upload
            setErrorMessageFunc={setAlertMessage}
            postUploadFunc={getTodaysPlanAsync}
            siteId={siteId}
            uploadApiFuncAsync={uploadNewPlanAsync}
            buttonVariant={buttonVariant}
            isTextLessButton={false}
            isDisabled={false}
            onFailFunc={handleUploadFailed}
            overrideStyle={buttonStyle}
          />
          <Box style={{ flex: 0.05 }} />
          <SolarGikButton
            icon={<DownloadIcon />}
            onClickFunc={handleDownloadPlanTemplate}
            isDisabled={false}
            style={buttonStyle}
            text={"Template"}
          />
        </div>
      )}
    </Card>
  );
};
export default AgriPlan;
