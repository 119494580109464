import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import { IIdToMetadataDictionary, IIssuesAndMetadata, IssuesLoadingStatus } from "../faults/issues/IssuesModels";
import { ComponentTypeEnum, SeverityEnum } from "../faults/faultsModels";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";

interface SystemStatusDescription {
    invertersIssusDescription: string;
    trackersIssuesDescription: string;
}

export const useSystemStatusDescription = (siteId: string): SystemStatusDescription => {
    const currentSiteIssuesLoadingState = useSelector(
        (state: RootState) => state.multiSitesIssues.loadingState[siteId]
    );
    const currentSiteIssues = useSelector(
        (state: RootState) => state.multiSitesIssues.issues[siteId]
    );
    const issuesMetadata = useSelector(
        (state: RootState) => state.multiSitesIssues.metadata
    );
    const totalNumOfTrackers = useSelector(
        (state: RootState) => state.multiSitesMetadata[siteId].trackerIds.length
    );
    const totalNumOfInverters = useSelector(
        (state: RootState) => state.multiSitesMetadata[siteId].inverterIds.length
    );

    const [values, setValues] = useState<SystemStatusDescription>();

    useEffect(() => {
        const values = calculateDescriptions(
            currentSiteIssuesLoadingState, currentSiteIssues, issuesMetadata,
            totalNumOfInverters, totalNumOfTrackers
        );
        setValues(values);
    }, [currentSiteIssuesLoadingState, currentSiteIssues, issuesMetadata, totalNumOfTrackers, totalNumOfInverters]);

    return values || { invertersIssusDescription: NO_VALUE_PLACEHOLDER, trackersIssuesDescription: NO_VALUE_PLACEHOLDER };
}

function calculateDescriptions(
    currentSiteIssuesLoadingState: IssuesLoadingStatus, currentSiteIssues: IIssuesAndMetadata,
    issuesMetadata: IIdToMetadataDictionary, totalNumOfInverters: number, totalNumOfTrackers: number) {

    let invertersIssusDescription: string;
    let trackersIssuesDescription: string;

    if (currentSiteIssuesLoadingState === IssuesLoadingStatus.Complete) {
        const trackerIssues = currentSiteIssues.activeIssuesByCategories[ComponentTypeEnum.Trackers];
        const trackersHighSeverityIssues = Object.values(trackerIssues).filter(
            (issueInfo) => issuesMetadata[issueInfo.metadataId].severity === SeverityEnum.High
        );
        const trackersHighSeverityIssuesCount = new Set(
            trackersHighSeverityIssues.map((issuesInfo) => issuesInfo.deviceId)
        ).size;
        const inverterIssues = currentSiteIssues.activeIssuesByCategories[ComponentTypeEnum.Inverters];
        const invertersHighSeverityIssues = Object.values(inverterIssues).filter(
            (issuesInfo) => issuesMetadata[issuesInfo.metadataId].severity === SeverityEnum.High
        );
        const invertersHighSeverityIssuesCount = new Set(
            invertersHighSeverityIssues.map((issuesInfo) => issuesInfo.deviceId)
        ).size;

        if (totalNumOfInverters == 0 || invertersHighSeverityIssuesCount == 0) {
            invertersIssusDescription = "No inverters' high-severity issues";
        } else {
            const invertersIssuesPrecentage = (
                (invertersHighSeverityIssuesCount / totalNumOfInverters) *
                100
            ).toFixed(0);
            invertersIssusDescription = `${invertersHighSeverityIssuesCount} of ${totalNumOfInverters} (${invertersIssuesPrecentage}%) have high severity issues`;
        }

        if (totalNumOfTrackers == 0 || trackersHighSeverityIssuesCount == 0) {
            trackersIssuesDescription = "No trackers' high-severity issues";
        } else {
            const trackersIssuesPrecentage = (
                (trackersHighSeverityIssuesCount / totalNumOfTrackers) *
                100
            ).toFixed(0);
            trackersIssuesDescription = `${trackersHighSeverityIssuesCount} of ${totalNumOfTrackers} (${trackersIssuesPrecentage}%) have high severity issues`;
        }
    } else if (currentSiteIssuesLoadingState === IssuesLoadingStatus.Error) {
        trackersIssuesDescription = invertersIssusDescription =
            NO_VALUE_PLACEHOLDER;
    } else {
        invertersIssusDescription = trackersIssuesDescription = "⏳ Loading...";
    }
    return { invertersIssusDescription, trackersIssuesDescription };
}
