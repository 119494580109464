export enum ComponentEnum {
  Tracker = "Tracker",
  Inverter = "Inverter",
  PowerMeter = "PowerMeter",
  SiteControl = "Site Control",
  PC = "PC",
  Network = "Network",
  AP = "AP",
  Other = "Other",
}
export enum RecordTypeEnum {
  general = "General",
  issue = "Issue",
  work = "Work",
}
export interface IUserRecord {
  eventDate: number;
  title: string;
  reporter: string;
  componentType: string;
  componentName: string;
  recordType: string;
  description: string;
  id?: number;
  isEngineerOnly: boolean;
}
export interface IUserRecordDto {
  EventDate: number;
  Title: string;
  Reporter: string;
  ComponentType: string;
  ComponentName: string;
  RecordType: string;
  Description: string;
  Id?: number;
  IsEngineerOnly: boolean;
}
