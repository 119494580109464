export function strNumberToFixedStr(
  num: string,
  fixedDecimalDigits: number
): string {
  return Number(num).toFixed(fixedDecimalDigits);
}
export function numberToFixedStr(
  num: number,
  fixedDecimalDigits: number
): string {
  return num.toFixed(fixedDecimalDigits);
}