import axios from "axios";
import APP_CONFIG from "../../app/configuration/AppConfig";
import {
  IActiveAlert,
  IAlertMetadata,
  IGetHistoryAlertsParams as IHistoryAlertsParams,
  IHistoryAlert,
} from "./alertModels";

export const getAlertsMetadataAsync = async (): Promise<IAlertMetadata[]> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + "/faults/alerts/metadata";
  const httpResponse = await axios.get<IAlertMetadata[]>(url);
  return httpResponse.data;
};

export const getActiveAlertsAsync = async (
  siteId: string
): Promise<IActiveAlert[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/alerts/?SiteId=${siteId}`;
  const httpResponse = await axios.get<IActiveAlert[]>(url);
  return httpResponse.data;
};

export const getHistoryAlertsAsync = async (
  queryParams: IHistoryAlertsParams
): Promise<IHistoryAlert[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/alerts/history`;

  const httpResponse = await axios.get<IHistoryAlert[]>(url, {
    params: queryParams,
  });
  return httpResponse.data;
};
