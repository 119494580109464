import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { SiteAppType } from "./AppConfigModels";

export const getIotDevicesIdList = async (): Promise<string[]> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix + APP_CONFIG.apiGetIotDevices;
  const httpResponse = await axios.get<string[]>(url);
  return httpResponse.data;
};

export const ensureSiteModuleTypeExists = async (
  siteId: string,
  siteApp: SiteAppType,
  deviceId: string
) => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiEnsureSiteModuleTypeExists(siteId, siteApp, deviceId);
  await axios.post(url);
};

export const addOrUpdateTagInIotDevice = async (
  deviceId: string,
  tagName: string,
  tagValue: string
) => {
  const params = {
    deviceId,
    tagName,
    tagValue,
  };
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiAddOrUpdateTagInIotDevice;
  await axios.post(url, params);
};

export const getModuleStatusOnDevice = async (
  siteId: string,
  deviceId: string,
  siteApp: SiteAppType
): Promise<string> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiGetModuleStatusOnDevice(siteId, deviceId, siteApp);
  const httpResponse = await axios.get<string>(url);
  return httpResponse.data;
};

export const updateMcsToUseDas = async (siteId: string) => {
  const url =
    APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix +
    APP_CONFIG.apiUpdateSiteControlToUseDas(siteId);
  await axios.put(url);
};

export const updateMcsToUseTagsHarvester = async (siteId: string) => {
  const url =
    APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix +
    APP_CONFIG.apiUpdateSiteControlConfigToUseTagsHarvester(siteId);
  await axios.put(url);
};

export const generateTagsHarvesterConfig = async (siteId: string) => {
  const url =
    APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix +
    APP_CONFIG.apiGenerateTagsHarvesterConfig(siteId);
  await axios.post(url);
};
