import { FC } from "react";

import { useSelector } from "react-redux";

import DataPointTooltip from "./DataPointTooltip";
import classes from "./VerticalIconDataPoint.module.css";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { RootState } from "../../app/Store";
import DataPointInfo from "../DataPointInfo";
import { TagTimeValidity } from "../models/TagsModels";

interface VerticalIconDataPointProps {
  tagName: string;
  siteId: string;
  titleOverride?: string;
  nameOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  InvalidIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const VerticalIconDataPoint: FC<VerticalIconDataPointProps> = ({
  tagName,
  titleOverride,
  nameOverride,
  valueToStringFuncOverride,
  Icon,
  InvalidIcon,
}) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { title, valueInString, unit, valueExist, tagTimeValidityStatus } =
    DataPointInfo(tagName, siteId, titleOverride, valueToStringFuncOverride);
  const titleLines = title.split(" ");
  return (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      <li className={classes["list-items"]}>
        <div>
          {tagTimeValidityStatus === TagTimeValidity.ValidButOld
            ? InvalidIcon && <InvalidIcon />
            : Icon && <Icon />}
        </div>
        <div className={`${TextEnum.h5} ${classes["two-lines"]}`}>
          {titleLines.map((line) => (
            <div key={line}>{line}</div>
          ))}
        </div>
        <div className={TextEnum.h1}>{valueInString}</div>
        <div> {unit}</div>
        {!valueExist && <div>--</div>}
      </li>
    </DataPointTooltip>
  );
};
export default VerticalIconDataPoint;
