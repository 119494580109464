import { FC } from "react";

import classes from "./PopoverHeader.module.css";
import { TextEnum } from "../TextStyles";
interface IPopoverHeaderProp {
  title?: string;
}
const PopoverHeader: FC<IPopoverHeaderProp> = ({ title }) => {
  return <div className={`${classes["title"]} ${TextEnum.h4}`}>{title}</div>;
};

export default PopoverHeader;
