/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import Button from "@mui/material/Button";

import classes from "./ConfigItem.module.css";
import { btnStyling } from "../../common/Mui/MuiStyling";

interface ConfigItemProps {
  isDisable: boolean;
  isActive: boolean;
  configName: string;
  downloadHandler: React.MouseEventHandler<SVGSVGElement>;
  activateHandler: React.MouseEventHandler<HTMLButtonElement>;
}

const ConfigItem: FC<ConfigItemProps> = ({
  isDisable,
  isActive,
  configName,
  downloadHandler,
  activateHandler,
}) => {
  return (
    <div className={classes.row}>
      {configName}
      <DownloadIcon
        className={
          isDisable
            ? `${classes.disable} ${classes["download-icon"]}`
            : classes["download-icon"]
        }
        sx={{ fontSize: 30 }}
        onClick={(e) => downloadHandler(e)}
      />

      <div
        className={
          isDisable
            ? `${classes.disable} ${classes["activate-button"]}`
            : classes["activate-button"]
        }
      >
        <Button
        size="small"
          disabled={isDisable}
          sx={btnStyling}
          onClick={(e) => activateHandler(e)}
        >
          Activate
        </Button>
      </div>
      {isActive && <TurnedInNotIcon sx={{ color: "var(--warning-color)" }}></TurnedInNotIcon>}
    </div>
  );
};
export default ConfigItem;
