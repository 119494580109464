import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  DrawerIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { UserAccessType } from "../../user/UserStore";
export interface NavConfig {
  pageName: string;
  pagePath: string;
  userLevel: UserAccessType;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hoverIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const NavItemsConfig: NavConfig[] = [
  {
    pageName: "Multisite Table",
    pagePath: "/MultisiteTable",
    userLevel: UserAccessType.ViewDashboard,
    icon: getIcons(DrawerIcons.MultisiteTable, IconCategory.Navigation),
    activeIcon: getIcons(
      DrawerIcons.MultisiteTableActive,
      IconCategory.Navigation
    ),
    hoverIcon: getIcons(
      DrawerIcons.MultisiteTableHover,
      IconCategory.Navigation
    ),
  },

  {
    pageName: "Trends",
    pagePath: "/Trends",
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.Trends, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.TrendsActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.TrendsHover, IconCategory.Navigation),
  },
];
