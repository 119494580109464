type OnErrorCallback = (message: string) => void;

const useAsyncOperationWithErrorHandling = (onError: OnErrorCallback) => {
  const runAsyncFuncWithErrorHandling = async <T,>(
    asyncFunc: () => Promise<T>,
    errorMessage: string
  ): Promise<T> => {
    try {
      return await asyncFunc();
    } catch (error) {
      onError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const runAsyncFuncWithParamsWithErrorHandling = async <T,>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncFunc: (...args: any) => Promise<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: any,
    errorMessage: string
  ): Promise<T> => {
    try {
      return await asyncFunc(...args);
    } catch (error) {
      onError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return {
    runAsyncFuncWithErrorHandling,
    runAsyncFuncWithParamsWithErrorHandling,
  };
};

export default useAsyncOperationWithErrorHandling;
