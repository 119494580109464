import { FC, useEffect, useState } from "react";

import classes from "./WeatherGif.module.css";
import { repetitivePolling } from "../../common/AsyncUtils";
import APP_CONFIG from "../app/configuration/AppConfig";

interface IWeatherGifProps {
  imageUrl?: string;
}
const WeatherGif: FC<IWeatherGifProps> = ({ imageUrl }) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const getImage = async () => {
    const uniqueImageUrl = imageUrl + `&cacheAvoidance=${new Date().getTime()}`;
    setImageSrc(uniqueImageUrl);
  };

  useEffect(() => {
    const cntrlr = new AbortController();
    repetitivePolling(
      'getImage',
      getImage,
      APP_CONFIG.milliSecBetweenWeatherGif,
      APP_CONFIG.milliSecBetweenGetTags,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, []);

  return (
    <div className={classes["container"]}>
      <div className={classes["gif-container"]}>
        <img className={classes["gif"]} src={imageSrc}></img>
      </div>
    </div>
  );
};

export default WeatherGif;
