import { FC } from "react";

import { useSelector } from "react-redux";

import { RootState } from "../app/Store";
import { ISiteToTagToMetaDataDictionary } from "../data_point/models/TagsModels";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";


interface TagHeaderTooltipProps {
    tagName: string;
    displayName?: string;
    children?: React.ReactNode;
}

function tryGetFirstSiteIdWithTag(
    tagName: string,
    multiSitesTagsMetadata: ISiteToTagToMetaDataDictionary
): string {
    for (const site of Object.keys(multiSitesTagsMetadata)) {
        if (tagName in multiSitesTagsMetadata[site]) {
            return site;
        }
    }
    throw new Error(`No site was found with ${tagName}`);
}

const TagHeaderTooltip: FC<TagHeaderTooltipProps> = ({
    tagName,
    displayName,
    children,
}) => {
    const tagsMetadata = useSelector(
        (state: RootState) => state.multiSitesTagsMetadata
    );

    const siteId = tryGetFirstSiteIdWithTag(tagName, tagsMetadata);

    return (
        <DataPointTooltip
            tagName={tagName}
            siteId={siteId}
            displayName={displayName}
            isShowDateOnly={false}
            isShowTagMetadataOnly={true}
        >
            {children}
        </DataPointTooltip>
    )
}

export default TagHeaderTooltip;