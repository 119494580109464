import axios from "axios";

import APP_CONFIG from "../../app/configuration/AppConfig";
import { IModuleMetadata } from "./models";

export const getSiteModuleMetadata = async (
  siteId: string
): Promise<IModuleMetadata[]> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    `/site/${siteId}/modulesMetadata`;
  const httpResponse = await axios.get<IModuleMetadata[]>(url);
  return httpResponse.data;
};

export const deleteModuleType = async (
  siteId: string,
  type: string,
  deviceId: string
): Promise<void> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    `/site/${siteId}/${deviceId}/${type}`;
  await axios.delete(url);
};
