import React from "react";
import { useSelector } from "react-redux";
import { trackersTableColumns } from "./TrackersStatusTableConfig";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import Table from "../../common/table/Table";
import { numberToFixedStr } from "../app/Formating";
import { RootState } from "../app/Store";
import { SiteTrackerTypeEnum } from "../sites/SiteModels";
import classes from "./TrackersStatusTable.module.css";
import { selectSiteId } from "../sites/SiteStore";

interface TrackersStatusTableProps {
  handleRowsSelected?: (selectedRows: number[]) => void;
  isLoading?: boolean;
}

const TrackersStatusTable: React.FC<TrackersStatusTableProps> = ({
  handleRowsSelected,
  isLoading,
}) => {
  const siteId = useSelector(selectSiteId);
  const trackersStatuses = useSelector(
    (state: RootState) => state.multiSitesTrackers[siteId] || []
  );
  const isAgriSite = useSelector(
    (state: RootState) =>
      state.multiSitesVisualInfo[siteId].isAgriPageVisible
  );
  const isSptSite =
    useSelector((state: RootState) => state.site.trackerType) ===
    SiteTrackerTypeEnum.SPT;
  const tableData = trackersStatuses.map((status) => {
    return {
      ...status,
      shadingRatio:
        status.shadingRatio != null
          ? numberToFixedStr(status.shadingRatio, 2)
          : NO_VALUE_PLACEHOLDER,
      batteryStatus: {
        chargingState: status.chargingState,
        chargePercentage: status.chargePercentage,
      },
    };
  });
  const getHiddenColumns = () => {
    const res = [];
    if (!isAgriSite) {
      res.push("shadingRatio");
    }
    if (!isSptSite) {
      res.push("batteryStatus");
    }
    return res;
  };
  const hiddenColumns = getHiddenColumns();
  return (
    <Table
      getCellClassName={(params) =>
        params.field === "batteryStatus" ? classes["battery-status-cell"] : ""
      }
      loading={isLoading}
      rows={tableData}
      columns={trackersTableColumns}
      hiddenColumns={hiddenColumns}
      handleRowsSelected={handleRowsSelected}
      checkboxSelection
      hideToolbar
      hideFooter
      hideBorder
    />
  );
};
export default TrackersStatusTable;
