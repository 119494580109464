import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  DrawerIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { PageNames } from "../../../pages/PageNames";
import { UserAccessType } from "../../user/UserStore";
import { NavConfig } from "../NavigationDrawer/NavigationDrawerModels";

export const NavItemsConfig: NavConfig[] = [
  {
    pageName: "Dashboard",
    pagePath: "/" + PageNames.Dashboard,
    userLevel: UserAccessType.ViewDashboard,
    icon: getIcons(DrawerIcons.Dashboard, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.DashboardActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.DashboardHover, IconCategory.Navigation),
  },

  {
    pageName: "History",
    pagePath: "/" + PageNames.History,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.History, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.HistoryActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.HistoryHover, IconCategory.Navigation),
  },
  {
    pageName: "Trackers",
    pagePath: "/" + PageNames.Trackers,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.Trackers, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.TrackersActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.TrackersHover, IconCategory.Navigation),
  },
  {
    pageName: "User Records",
    pagePath: "/" + PageNames.UserRecords,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.UserRecords, IconCategory.Navigation),
    activeIcon: getIcons(
      DrawerIcons.UserRecordsActive,
      IconCategory.Navigation
    ),
    hoverIcon: getIcons(DrawerIcons.UserRecordsHover, IconCategory.Navigation),
  },
  {
    pageName: "Agriculture",
    pagePath: "/" + PageNames.Agriculture,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.Agri, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.AgriActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.AgriHover, IconCategory.Navigation),
  },
  {
    pageName: "Issues",
    pagePath: "/" + PageNames.Issues,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.Issues, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.IssuesActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.IssuesHover, IconCategory.Navigation),
  },
  {
    pageName: "Sprinklers",
    pagePath: "/" + PageNames.Sprinklers,
    userLevel: UserAccessType.Viewer,
    icon: getIcons(DrawerIcons.Sprinklers, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.SprinklersActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.SprinklersHover, IconCategory.Navigation),
  },
  {
    pageName: "Weather",
    pagePath: "/" + PageNames.Weather,
    userLevel: UserAccessType.Engineer,
    icon: getIcons(DrawerIcons.Weather, IconCategory.Navigation),
    activeIcon: getIcons(DrawerIcons.WeatherActive, IconCategory.Navigation),
    hoverIcon: getIcons(DrawerIcons.WeatherHover, IconCategory.Navigation),
  },
  {
    pageName: "Configuration",
    pagePath: "/" + PageNames.Configuration,
    userLevel: UserAccessType.Engineer,
    icon: getIcons(DrawerIcons.CloudConfiguration, IconCategory.Navigation),
    activeIcon: getIcons(
      DrawerIcons.CloudConfigurationActive,
      IconCategory.Navigation
    ),
    hoverIcon: getIcons(
      DrawerIcons.CloudConfigurationHover,
      IconCategory.Navigation
    ),
  },
];
