import { FC } from "react";

import classes from "./HeaderDataPoint.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import DataPointInfo from "../data_point/DataPointInfo";
import TagInvalidIcon from "../data_point/TagInvalidIcon";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";

interface HeaderDataPointProps {
  tagName: string;
  siteId: string;
  titleOverride?: string;
  nameOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
}

const HeaderDataPoint: FC<HeaderDataPointProps> = ({
  tagName,
  siteId,
  titleOverride,
  nameOverride,
  valueToStringFuncOverride,
}) => {
  const { title, valueInString, unit, valueExist } = DataPointInfo(
    tagName,
    siteId,
    titleOverride,
    valueToStringFuncOverride
  );

  return (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      <li>
        <div className={classes["data-point-container"]}>
          <TagInvalidIcon tagName={tagName} siteId={siteId} />
          <span className={TextEnum.h4}>
            {title}{" "}
          </span>
          <span className={TextEnum.h1}>
            {!valueExist ? NO_VALUE_PLACEHOLDER : valueInString}
          </span>
          <span className={TextEnum.h7}>{valueExist && unit}</span>
        </div>
      </li>
    </DataPointTooltip>
  );
};

export default HeaderDataPoint;
