import axios from "axios";

import { IEmergencyStatus } from "./EmergencyModel";
import APP_CONFIG from "../../app/configuration/AppConfig";

export async function setDesiredEmergencyStatusAsync(
  siteId: string,
  active: boolean,
  reason?: string
) {
  const url: string =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiEmergencyStatus(siteId);
  const params = {
    active,
    reason,
  };
  const apiResponse = await axios.post<void>(url, params);
  return apiResponse;
}

export async function getDesiredEmergencyStatusAsync(siteId: string) {
  const url: string =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiEmergencyStatus(siteId);

  const apiResponse = await axios.request<IEmergencyStatus>({
    url,
  });
  return apiResponse.data;
}
