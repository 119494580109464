import { createSlice } from "@reduxjs/toolkit";

import { PagesState } from "./PagesModel";

const defaultPagesState: PagesState = {
  showTrackerViewPage: false,
};
export const pagesSlice = createSlice({
  name: "pages",
  initialState: defaultPagesState,
  reducers: {
    setViewPage: (state, action) => {
      return {
        ...state,
        showTrackerViewPage: action.payload,
      };
    },
  },
});
export const pagesReducer = pagesSlice.reducer;
