import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { failSafeInit } from "../../../common/AsyncUtils";
import { errorHandlerFunc } from "../../../common/CommunicationErrorHandling";
import APP_CONFIG from "../../app/configuration/AppConfig";
import classes from "./DasConfiguration.module.css";
import Card from "../../../SolarGikLib/cards/Card";
import {
  getActiveConfigAsync,
  getConfigListAsync,
  updateMetadataDescriptionAsync,
} from "../AppConfigAPI";
import {
  AppVersionViewModel,
  SiteAppType,
} from "../AppConfigModels";
import ConfigurationList from "../ConfigurationList";
import SiteAppConfigUpload from "../SiteAppConfigUpload";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { formatVersions, versionRegex } from "../AppUtils";
import { selectSiteId } from "../../sites/SiteStore";

const siteApp = SiteAppType.Das;

const DasConfiguration = () => {
  useEffect(() => {
    const abortCtrl = new AbortController();
    failSafeInit(
      'getRemoteConfigList',
      getRemoteConfigList,
      APP_CONFIG.milliSecBetweenFailedInitRequests,
      abortCtrl.signal
    );
    return () => {
      abortCtrl.abort();
    };
  }, []);

  const [configList, setConfigList] = useState<AppVersionViewModel[]>();
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const siteId = useSelector(selectSiteId);

  const getRemoteConfigList = async () => {
    try {
      const [configListResponse, activeVersion] = await Promise.all([
        getConfigListAsync(siteId, "", siteApp),
        getActiveConfigAsync(siteId, siteApp).catch((error) => {
          setAlertMessage({
            text: errorHandlerFunc(error, "getting Active version"),
            severity: "error",
          });
          return null;
        }),
      ]);

      const formattedConfigList = formatVersions(configListResponse);
      if (activeVersion) {
        for (let i = 0; i < formattedConfigList.length; i++) {
          if (formattedConfigList[i].dtoVersion === activeVersion) {
            formattedConfigList[i].isActive = true;
          }
        }
      }
      setConfigList(formattedConfigList);
      setAlertMessage(undefined);
    } catch (error) {
      setAlertMessage({
        text: errorHandlerFunc(error, "get configuration list"),
        severity: "error",
      });
    }
  };

  const setRemoteDescription = async (
    resource: string,
    description: string
  ) => {
    try {
      const res = await updateMetadataDescriptionAsync(
        description,
        resource,
        siteApp
      );
      if (res.status === 200) {
        getRemoteConfigList();
        return true;
      }
    } catch (error) {
      setAlertMessage({
        text: errorHandlerFunc(error, "Update metadata description"),
        severity: "error",
      });
    }
    return false;
  };

  return (
    <div className={classes.content}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes.row}>
        <Card>
          <ConfigurationList
            configList={configList}
            onChange={getRemoteConfigList}
            updateRemoteDescription={setRemoteDescription}
            siteApp={siteApp}
          />
        </Card>
        <Card title="Upload" contentStyle={{ alignItems: "flex-start" }} >
          <div>
            <SiteAppConfigUpload
              versionRegex={versionRegex}
              getConfigListFunc={getRemoteConfigList}
              updateRemoteDescription={setRemoteDescription}
              siteApp={siteApp}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
export default DasConfiguration;
