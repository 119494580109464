import APP_CONFIG from "../app/configuration/AppConfig";
import {
  getCurrentDateInSiteTimeZone,
  getDayEndInUtcFromDateOnly,
  getDayStartInUtcFromDateOnly,
  getEndDayFromUtc,
  getStartDayFromUtc,
} from "../app/DateTimeUtils";
import { ITagLineInfo } from "../data_point/models/TagsModels";
import TagsNames, { TagsFormatPreFix } from "../data_point/TagsNames";

export interface ChartParams {
  tags: ITagLineInfo[];
  fromDate: Date;
  toDate: Date;
  samplingInterval: string;
  siteId: string;
}
export const initialChartParams = {
  tags: [],
  fromDate: new Date(),
  toDate: new Date(),
  samplingInterval: "",
  siteId: "",
};

export const createChartConfig = (
  tagTable: ITagLineInfo[],
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  return {
    siteId: siteId,
    tags: tagTable,
    fromDate: getStartDayFromUtc(
      getCurrentDateInSiteTimeZone(zoneOffsetTime),
      zoneOffsetTime,
      getDayStartInUtcFromDateOnly
    ),
    toDate: getEndDayFromUtc(
      getCurrentDateInSiteTimeZone(zoneOffsetTime),
      zoneOffsetTime,
      getDayEndInUtcFromDateOnly
    ),
    samplingInterval: APP_CONFIG.chartDefaultSamplingInterval,
  };
};
export const createWeatherChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartWeather: ITagLineInfo[] = [];

  chartWeather.push({
    id: TagsNames.DNI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.TEMP,
    isCombinedYAxis: false,
  });
  chartWeather.push({
    id: TagsNames.WIND,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartWeather, zoneOffsetTime, siteId);
};
export const createWeatherForAgriChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartWeather: ITagLineInfo[] = [];

  chartWeather.push({
    id: TagsNames.WEATHER_HUMIDITY,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.TEMP,
    isCombinedYAxis: false,
  });
  chartWeather.push({
    id: TagsNames.WIND,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartWeather, zoneOffsetTime, siteId);
};
export const createIrradiancePowerChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartIrradiancePower: ITagLineInfo[] = [];
  chartIrradiancePower.push({
    id: TagsNames.DNI,
    isCombinedYAxis: true,
  });
  chartIrradiancePower.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartIrradiancePower.push({
    id: TagsNames.SITE_DC_CURRENT_POWER,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartIrradiancePower, zoneOffsetTime, siteId);
};
export const createSoilChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartSoil: ITagLineInfo[] = [];
  chartSoil.push({
    id: TagsNames.SOIL_HUMIDITY,
    isCombinedYAxis: true,
  });
  chartSoil.push({
    id: TagsNames.SOIL_TEMP,
    isCombinedYAxis: true,
  });
  chartSoil.push({
    id: TagsNames.SOIL_PH,
    isCombinedYAxis: true,
  });
  return createChartConfig(chartSoil, zoneOffsetTime, siteId);
};
export const createDailyProductionChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartPower: ITagLineInfo[] = [];
  chartPower.push({
    id: TagsNames.SITE_AC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  chartPower.push({
    id: TagsNames.MOMENT_POTENTIAL_POWER,
    isCombinedYAxis: true,
  });
  chartPower.push({
    id: TagsNames.MOMENT_CONTRACT_REQUIRED_POWER,
    isCombinedYAxis: true,
  });
  return createChartConfig(chartPower, zoneOffsetTime, siteId);
};
export const createCurrentProductionChartConfig = (
  ids: number[],
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const currentProductionChart: ITagLineInfo[] = [];
  currentProductionChart.push({
    id: TagsNames.SITE_AC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  currentProductionChart.push({
    id: TagsNames.SITE_DC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  fillProductionChartConfig(
    currentProductionChart,
    ids,
    zoneOffsetTime,
    getInverterAcPowerTagWithId,
    "Inverter Power"
  );
  return createChartConfig(currentProductionChart, zoneOffsetTime, siteId);
};
export const createPerformanceChartConfig = (
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const chartPerformance: ITagLineInfo[] = [];
  chartPerformance.push({
    id: TagsNames.DAILY_SP_PRODUCTION,
    isCombinedYAxis: false,
  });
  chartPerformance.push({
    id: TagsNames.MOMENT_POTENTIAL_POWER,
    isCombinedYAxis: false,
  });
  chartPerformance.push({
    id: TagsNames.DAILY_SP_EFFICIENCY,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartPerformance, zoneOffsetTime, siteId);
};
export const createTrackersChartConfig = (
  ids: number[],
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const trackersChart: ITagLineInfo[] = [];
  fillTrackersChartConfig(
    trackersChart,
    ids,
    getTrackerCurrentElevationTagWithId
  );
  return createChartConfig(trackersChart, zoneOffsetTime, siteId);
};
export const createShadingPercentChartConfig = (
  ids: number[],
  zoneOffsetTime: number,
  siteId: string
): ChartParams => {
  const trackersChart: ITagLineInfo[] = [];
  fillTrackersChartConfig(
    trackersChart,
    ids,
    getTrackerShadingPercentTagWithId
  );
  return createChartConfig(trackersChart, zoneOffsetTime, siteId);
};
const fillProductionChartConfig = (
  currentProductionChart: ITagLineInfo[],
  ids: number[],
  zoneOffsetTime: number,
  tagFunc: (number: number) => string,
  displayName: string
) => {
  if (typeof ids !== "undefined" && ids !== null) {
    ids.forEach((id) => {
      if (currentProductionChart.find((data) => data.id === tagFunc(id))) {
        return;
      } else {
        currentProductionChart.push({
          id: tagFunc(id),
          displayName: displayName + " " + id,
          isCombinedYAxis: true,
        });
      }
    });
  }
};
const fillTrackersChartConfig = (
  trackersChart: ITagLineInfo[],
  ids: number[],
  trackerFunc: (number: number) => string
) => {
  if (typeof ids !== "undefined" && ids !== null) {
    ids.forEach((id) => {
      trackersChart.push({
        id: trackerFunc(id),
        displayName: "Tracker " + id,
        isCombinedYAxis: true,
      });
    });
  }
};
const getInverterAcPowerTagWithId = (num: number) =>
  TagsFormatPreFix.SITE_AC_CURRENT_POWER + num;
const getTrackerCurrentElevationTagWithId = (num: number) =>
  "TrackerStatus:CurrentElevation:" + num;
const getTrackerShadingPercentTagWithId = (num: number) =>
  "TrackerStatus:ShadingRatio:" + num;
export const AUXILIARIES_ISSUES_TITLE = "Auxiliaries Issues";
export const POWER_GENERATION_TITLE = "Power Generation";
export const CURRENT_PRODUCTION_TITLE = "Production";
export const WEATHER_TITLE = "Weather";
export const PERFORMANCE_TITLE = "Performance";
export const SHADING_TITLE = "Shading Percent";
export const TRACKER_ELEVATION_TITLE = "Trackers Elevations";
export const IRRADIANCE_POWER_TITLE = "Irradiance / Power";
export const SOIL_TITLE = "Soil";
export const CHART_DEFAULT_HEIGHT = 390;
export const CHART_HEIGHT_WITHOUT_LEGEND = 370;
