import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { generateUniqueKey } from "../../faults/issues/IssuesCommon";
import {
  ISiteToIssuesState,
  IIssueInfo,
  IComponentTypeToIssues,
  IIdToMetadataDictionary,
  ComponentTypeToIssuesSeverityCount,
  IComponentTypeToIssuesDictionary,
  IIssuesAndMetadata,
  IssuesLoadingStatus,
  ISitesToIssueInfos,
} from "../../faults/issues/IssuesModels";
import { RootState } from "../Store";
import { ComponentTypeEnum, SeverityEnum } from "../../faults/faultsModels";
import { getActiveIssuesAsync } from "../../faults/issues/IssuesAPI";
import { TrackerNamesMap } from "../../sites/SiteModels";
import { tryGetTrackerName } from "../../trackers/TrackerName";

const defaultIssuesState: ISiteToIssuesState = {
  issues: {},
  loadingState: {},
  metadata: {},
};

const updateAmountOfIssuesBySeverity = (
  siteIssues: IIssuesAndMetadata,
  issuesMetadata: IIdToMetadataDictionary
) => {
  Object.values(SeverityEnum).forEach((severity) => {
    siteIssues.counts[severity as SeverityEnum] = 0;
  });
  Object.values(siteIssues.activeIssuesByCategories).forEach(
    (activeIssuesCategory) => {
      Object.values(activeIssuesCategory).forEach((issue) => {
        const metadata = issuesMetadata[issue.metadataId];
        if (!metadata) {
          return;
        }
        siteIssues.counts[metadata.severity as SeverityEnum]++;
      });
    }
  );
};

export const multiSitesIssuesSlice = createSlice({
  name: "multiSitesIssues",
  initialState: defaultIssuesState,
  reducers: {
    setSitesActiveIssues: (
      state: ISiteToIssuesState,
      action: PayloadAction<ISitesToIssueInfos>
    ) => {
      Object.entries(action.payload).forEach(([siteId, activeIssues]) => {
        setSiteActiveIssues(state, siteId, activeIssues);
        state.loadingState[siteId] = IssuesLoadingStatus.Complete;
      });
    },
    setIssuesMetaData: (
      state: ISiteToIssuesState,
      action: PayloadAction<{
        issuesMetadata: IIdToMetadataDictionary[];
        sites: string[];
      }>
    ) => {
      const { issuesMetadata } = action.payload;
      state.metadata = issuesMetadata.reduce((accumulator, metadata) => {
        const id = Object.keys(metadata)[0];
        accumulator[id] = metadata[id];
        return accumulator;
      }, {} as IIdToMetadataDictionary);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveIssues.fulfilled, (state, action) => {
        const { siteId, activeIssues } = action.payload;
        setSiteActiveIssues(state, siteId, activeIssues);
        state.loadingState[siteId] = IssuesLoadingStatus.Complete;
      })
      .addCase(fetchActiveIssues.pending, (state, action) => {
        const siteId = action.meta.arg;
        state.loadingState[siteId] = IssuesLoadingStatus.Pending;
      })
      .addCase(fetchActiveIssues.rejected, (state, action) => {
        const siteId = action.meta.arg;
        state.loadingState[siteId] = IssuesLoadingStatus.Error;
      });
  },
});

const setSiteActiveIssues = (
  state: ISiteToIssuesState,
  siteId: string,
  issues: IIssueInfo[]
) => {
  resetIssues(state, siteId);
  const siteIssues = state.issues[siteId];
  issues.forEach((issue) =>
    updateIssue(issue, siteIssues.activeIssuesByCategories, state.metadata)
  );
  updateAmountOfIssuesBySeverity(siteIssues, state.metadata);
};

const resetIssues = (state: ISiteToIssuesState, siteId: string) => {
  state.issues[siteId] = {
    activeIssuesByCategories: Object.values(ComponentTypeEnum).reduce(
      (accumulator, value) => {
        if (typeof value === "number" && value !== 0) {
          accumulator[value] = {};
        }
        return accumulator;
      },
      {} as IComponentTypeToIssuesDictionary
    ),
    counts: Object.values(SeverityEnum).reduce((accumulator, severity) => {
      accumulator[severity as SeverityEnum] = 0;
      return accumulator;
    }, {} as { [key in SeverityEnum]: number }),
  };
};

const updateIssue = (
  issue: IIssueInfo,
  activeIssuesByCategories: IComponentTypeToIssuesDictionary,
  metadata: IIdToMetadataDictionary
) => {
  const { issueType, componentType, severity } = metadata[issue.metadataId];
  const id = generateUniqueKey(issue, issueType);
  const existingIssue = activeIssuesByCategories[componentType][id];
  if (existingIssue) {
    const existingIssueSeverity = metadata[existingIssue.metadataId].severity;
    if (severity < existingIssueSeverity) {
      return;
    }
  }

  activeIssuesByCategories[componentType][id] = issue;
};


const getComponentName = (type: ComponentTypeEnum, componentNumber: number, trackerNamesMap?: TrackerNamesMap) => {
  if (trackerNamesMap !== undefined && type === ComponentTypeEnum.Trackers) {
    return tryGetTrackerName(componentNumber, trackerNamesMap);
  }
  return componentNumber.toString();
};

export const selectCurrentSiteActiveIssuesByComponentType = createSelector(
  [
    (state: RootState) => state.multiSitesIssues,
    (state: RootState) => state.site.siteId,
    (state: RootState) => state.site.trackerNamesMap
  ],
  (multisiteIssues, siteId, trackerNamesMap) => {
    const issuesRes: IComponentTypeToIssues = {};
    const siteIssues = multisiteIssues.issues[siteId]?.activeIssuesByCategories;
    if (!siteIssues) {
      return null;
    }

    Object.values(siteIssues).forEach((componentTypeToIssueDic) => {
      Object.values(componentTypeToIssueDic).forEach((issue) => {
        //get issue metadata
        const metadata = multisiteIssues.metadata[issue.metadataId];
        if (metadata) {
          const ct = ComponentTypeEnum[metadata.componentType];

          if (!issuesRes[ct]) {
            issuesRes[ct] = [];
          }
          const description = metadata.description
            .replace("{ComponentType}", ct)
            .replace("{ComponentNumber}", getComponentName(metadata.componentType, issue.deviceId, trackerNamesMap))
            .replace("{ScreenTime}", metadata.screenTimeMinutes.toString());

          issuesRes[ct].push({
            ...issue,
            ...metadata,
            description,
            id: issue.deviceId + metadata.issueType + ct,
          });
        } else {
          console.log(
            `metadata not found for siteId ${siteId} and issue ${issue}`
          );
        }
      });
    });

    return issuesRes;
  }
);

export const selectCurrentSiteActiveIssuesCountByComponentType = createSelector(
  [
    (state: RootState) => state.multiSitesIssues,
    (state: RootState) => state.site.siteId,
  ],
  (multiSitesIssues, siteId) => {
    const issuesCount: ComponentTypeToIssuesSeverityCount = {
      [ComponentTypeEnum.Undefined]: {
        [SeverityEnum.Low]: 0,
        [SeverityEnum.Medium]: 0,
        [SeverityEnum.High]: 0,
        totalIssuesCount: 0,
      },
      [ComponentTypeEnum.Inverters]: {
        [SeverityEnum.Low]: 0,
        [SeverityEnum.Medium]: 0,
        [SeverityEnum.High]: 0,
        totalIssuesCount: 0,
      },
      [ComponentTypeEnum.Trackers]: {
        [SeverityEnum.Low]: 0,
        [SeverityEnum.Medium]: 0,
        [SeverityEnum.High]: 0,
        totalIssuesCount: 0,
      },
      [ComponentTypeEnum.System]: {
        [SeverityEnum.Low]: 0,
        [SeverityEnum.Medium]: 0,
        [SeverityEnum.High]: 0,
        totalIssuesCount: 0,
      },
      [ComponentTypeEnum.Auxiliaries]: {
        [SeverityEnum.Low]: 0,
        [SeverityEnum.Medium]: 0,
        [SeverityEnum.High]: 0,
        totalIssuesCount: 0,
      },
    };
    const siteIssues =
      multiSitesIssues.issues[siteId]?.activeIssuesByCategories;
    for (const key in siteIssues) {
      Object.values(siteIssues[key]).forEach((issues) => {
        // Get issue metadata
        const metadata = multiSitesIssues.metadata[issues.metadataId];
        if (metadata && metadata.severity != SeverityEnum.Undefined) {
          // Update the counts based on the severity
          issuesCount[key][metadata.severity]++;
          issuesCount[key].totalIssuesCount++;
        } else {
          console.log("metadata not found");
        }
      });
    }
    return issuesCount;
  }
);
export const fetchActiveIssues = createAsyncThunk(
  "issues/fetchActive",
  async (siteId: string) => {
    const activeIssues = await getActiveIssuesAsync(siteId);
    return {
      siteId,
      activeIssues,
    };
  }
);

export const multiSitesIssuesReducer = multiSitesIssuesSlice.reducer;
