import { FC } from "react";

import classes from "./ShowLine.module.css";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory, SomaIcons } from "../../../SolarGikLib/icons/IconsModels";
import { ITrendLine, TrendsUserEvents } from "../TrendsModel";
interface ShowLineProps {
  line: ITrendLine;
}
const ShowLine: FC<ShowLineProps> = ({ line }) => {
  const handleClick = () => {
    const event = new CustomEvent(TrendsUserEvents[TrendsUserEvents.ShowLineChange], {
      detail: { lineId: line.id, value: !line.isShowLine },
    });
    document.dispatchEvent(event);
  };
  const ClosedEye = getIcons(SomaIcons.ClosedEye, IconCategory.Soma);
  const OpenedEye = getIcons(SomaIcons.OpenedEye, IconCategory.Soma)
  const closedEye = <ClosedEye className={classes.eye} onClick={handleClick} />;
  const openedEye = <OpenedEye className={classes.eye} onClick={handleClick} />;
  return line.isShowLine == true ? openedEye : closedEye;
};
export default ShowLine;
