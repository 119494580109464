import { format } from "date-fns";

import { HOUR_TO_MINUTE_FORMAT, MONTH_TO_DAY_FORMAT, MONTH_TO_MINUTE_FORMAT } from "../app/DateTimeUtils";

type LabelDictionary = { [index: number]: boolean };
//This class provides utility functionality to calculate x labels for the UI in the chart
export class ChartXAxisUiLabel {
  allLabelsArr: Date[];
  indexesOfRoundHoursInLabelsArr: number[];
  showLabelsIndexDic: LabelDictionary;
  numberOfLabelsToShow: number;

  static getFormatForLabelToolTip(differenceInDays: number): string {
    if (differenceInDays <= 1) {
      return HOUR_TO_MINUTE_FORMAT;
    } else {
      return MONTH_TO_MINUTE_FORMAT;
    }
  }
  static getFormatForLabelXAxis(differenceInDays: number, date: Date): string {
    if (differenceInDays <= 1) {
      return format(date, HOUR_TO_MINUTE_FORMAT);
    } else if (differenceInDays > 1 && differenceInDays <= 4) {
      return format(date, MONTH_TO_MINUTE_FORMAT);
    } else {
      return format(date, MONTH_TO_DAY_FORMAT);
    }
  }
  constructor(labels: Date[], numberOfLabelsToShow = 4) {
    this.allLabelsArr = labels;
    this.numberOfLabelsToShow = numberOfLabelsToShow;
    this.indexesOfRoundHoursInLabelsArr = [];
    this.showLabelsIndexDic = {};
    this.calculateHourLabelsIndex();
    this.calculateShowLabelsIndexDic();
  }
  private calculateHourLabelsIndex(): void {
    this.indexesOfRoundHoursInLabelsArr = this.allLabelsArr
      .map((labelAsDate, index) => ({ labelAsDate, index }))
      .filter(
        (item) =>
          item.labelAsDate.getMinutes() === 0 &&
          item.labelAsDate.getSeconds() === 0
      )
      .map((item) => item.index);
  }

  private calculateShowLabelsIndexDic(): void {
    const labelsToUse =
      this.indexesOfRoundHoursInLabelsArr.length >= this.numberOfLabelsToShow
        ? this.indexesOfRoundHoursInLabelsArr
        : this.allLabelsArr.map((_, index) => index);

    const step = Math.ceil(labelsToUse.length / this.numberOfLabelsToShow);
    this.showLabelsIndexDic = labelsToUse.reduce(
      (acc: LabelDictionary, current: number, index: number) => {
        const actualIndex = typeof current === "number" ? current : index;
        if (index % step === 0) {
          acc[actualIndex] = true;
        }
        return acc;
      },
      {}
    );
  }

  public shouldShowLabel(index: number): boolean {
    return this.showLabelsIndexDic[index] || false;
  }
}
