import { FC, useState, useRef, useEffect } from "react";
import { DatePicker, Space, Button } from "antd";
import dayjs from "dayjs"; //We not work with dayjs! this only for this calendar!
import { useSelector } from "react-redux";
import classes from "./DateTimeRange.module.css";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { YEAR_TO_DAY_FORMAT } from "../app/DateTimeUtils";
import {
  maxDaysToDisplay,
  maxMonthsToDisplay,
} from "../data_point/charts/ChartTagsUtils";
import { RootState } from "../app/Store";
import { selectSiteId } from "../sites/SiteStore";

const { RangePicker } = DatePicker;
const rangePresets: {
  label: string;
  value: [dayjs.Dayjs, dayjs.Dayjs];
}[] = [
    { label: "Last 3 Days", value: [dayjs().add(-3, "d"), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  ];
interface IDateTimeRange {
  // dates make us problems when we work with types its need to explore the types of antd for our components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateRange: (dates: any) => void;
}
const DateTimeRange: FC<IDateTimeRange> = ({ updateRange }) => {
  const siteId = useSelector(selectSiteId);
  const isEnableQueryHistoryForMoreThanOneWeek = useSelector(
    (state: RootState) => state.multiSitesVisualInfo[siteId].isEnableQueryHistoryForMoreThanOneWeek
  );

  const [dates, setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().startOf("day").add(4, "h"),
    dayjs().startOf("day").add(18, "h"),
  ]);
  const [isFinalDate, setIsFinalDate] = useState<boolean>(false);
  useEffect(() => {
    updateRange([dates[0], dates[1]]);
  }, [isFinalDate]);

  //antd datepicker has no types for ref
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rangePickerRef = useRef<any>(null);
  // dates make us problems when we work with types its need to explore the types of antd for our components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRangeChange = (dates: any) => {
    if (dates == null || dates.length < 1) {
      return;
    }
    if (isMoreThenMaxMonthsToDisplay(dates)) {
      dates[1] = dates[0].add(maxMonthsToDisplay, "M");
    }
    if (!isEnableQueryHistoryForMoreThanOneWeek && isMoreThenWeek(dates)) {
      dates[1] = dates[0].add(maxDaysToDisplay, "d");
    }
    setDates([dates[0], dates[1]]);
  };
  const onOpenChange = (open: boolean) => {
    if (!open) {
      setIsFinalDate(!isFinalDate);
    }
  };
  // dates make us problems when we work with types its need to explore the types of antd for our components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isMoreThenWeek = (dates: any) => dates[1].diff(dates[0], "day") > 7;
  const isMoreThenMaxMonthsToDisplay = (dates: [dayjs.Dayjs, dayjs.Dayjs]) =>
    dates[1].diff(dates[0], "month") > maxMonthsToDisplay;
  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        onOpenChange={onOpenChange}
        className={TextEnum.h4}
        ref={rangePickerRef}
        value={dates}
        onCalendarChange={onRangeChange}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        disabledDate={(current: any) => current && current.isAfter(dayjs())}
        format={
          YEAR_TO_DAY_FORMAT.replace("yyyy", "YYYY").replace(
            "dd",
            "DD"
          ) /*Weird formating for range picker*/
        }
        renderExtraFooter={() => (
          <div className={classes["range-presets"]}>
            <Space size={30}>
              {rangePresets.map((preset) => (
                <Button
                  key={preset.label}
                  disabled={
                    !isEnableQueryHistoryForMoreThanOneWeek &&
                    isMoreThenWeek(preset.value)
                  }
                  onClick={() => {
                    onRangeChange(preset.value);
                    if (rangePickerRef.current != null) {
                      rangePickerRef.current.blur();
                    }
                  }}
                >
                  {preset.label}
                </Button>
              ))}
            </Space>
          </div>
        )}
      />
    </Space>
  );
};

export default DateTimeRange;
