import { getCssVariable } from "../cssVarUtils";

export const COLORS = {
  DISABLE: getCssVariable("--disable-color"),
  DARK_BLUE_PRIMARY: getCssVariable("--blue-color-primary"),
  GREY_TOOLTIP: getCssVariable("--grey-tooltip"),
  BACKGROUND: getCssVariable("--background-color"),
  WHITE: getCssVariable("--white"),
};
export const CHART_COLORS = {
  X_AXIS_GRID: getCssVariable("--chart-grid"),
  GRID_GREY: getCssVariable("--chart-grid"),
  TICKS: getCssVariable("--blue-color-primary"),
  TOOLTIP_BACKGROUND: getCssVariable("--chart-tooltip-background-color"),
  CHART_VERTICAL_LINE: getCssVariable("--chart-vertical-line"),
};

export const SEVERITY_COLORS = {
  Severe: getCssVariable("--severity-high-color"),
  Mild: getCssVariable("--severity-mild-color"),
  Low: getCssVariable("--severity-low-color"),
  Undefined: getCssVariable("--undefined-color"),
};
