import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FeatureFlags, IFeatureFlags } from './FeatureFlagsModels'
import getFeatureFlagsAsync from "./FeatureFlagsApi";

interface IFeatureFlagsState {
  flags: IFeatureFlags;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

const initialState: IFeatureFlagsState = {
  flags: Object.values(FeatureFlags).reduce(
    (accumulator, item) => {
      accumulator[item] = false;
      return accumulator;
    },
    {} as IFeatureFlags
  ),
  isLoading: false
}

const featuresSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureFlags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
        action.payload.forEach((featureFlag) => {
          state.flags[featureFlag] = true
        });
        state.isLoading = false;
      })
      .addCase(fetchFeatureFlags.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      })
  }
});

export const fetchFeatureFlags = createAsyncThunk(
  "featureFlags/fetch",
  async () => {
    const enabledFeatures = await getFeatureFlagsAsync();
    return enabledFeatures;
  }
);

export default featuresSlice.reducer;