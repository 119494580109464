import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PageNames } from "./PageNames";
import { RootState } from "../features/app/Store";

const Home = () => {
  const siteIds = useSelector((state: RootState) => state.user.siteIds);
  const navigate = useNavigate();
  useEffect(() => {
    if (siteIds.length === 0) {
      return;
    }
    navigate(`/${PageNames.MultisiteTable}`);
  }, [siteIds]);

  return <></>;
};

export default Home;
