import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ITrackersCommandSendTrackersToMaintenanceResultCodeEnum,
  ITrackersCommandResultReleaseTrackersFromMaintenance,
  TrackersCommandType,
  ITrackersCommandSendTrackersToFreezeResultCodeEnum,
} from "../../trackers/TrackersModels";
import { RootState } from "../Store";

interface ITrackerCommandResultState {
  [siteId: string]: {
    result:
      | ITrackersCommandSendTrackersToMaintenanceResultCodeEnum
      | ITrackersCommandResultReleaseTrackersFromMaintenance
      | ITrackersCommandSendTrackersToFreezeResultCodeEnum;
    commandType: TrackersCommandType;
    elevation: number | null;
    reason: string | null;
  };
}
interface ITrackersCommandResultSendTrackersToMaintenancePayload {
  result: ITrackersCommandSendTrackersToMaintenanceResultCodeEnum;
  commandType: TrackersCommandType;
  elevation: number;
  reason: string;
}
interface ITrackersCommandResultReleaseTrackersFromMaintenancePayload {
  result: ITrackersCommandResultReleaseTrackersFromMaintenance;
  commandType: TrackersCommandType;
}
interface ITrackersCommandResultSendTrackersToFreezePayload {
  result: ITrackersCommandSendTrackersToFreezeResultCodeEnum;
  commandType: TrackersCommandType;
  reason: string;
}

const initialState: ITrackerCommandResultState = {
  null: GetFaultInitialSiteState(),
};

function GetFaultInitialSiteState() {
  return {
    result: {},
    commandType: TrackersCommandType.ReleaseTrackersFromMaintenance,
    elevation: null,
    reason: null,
  };
}

export const multiSitesTrackersCommandsSlice = createSlice({
  name: "multiSitesTrackersCommands",
  initialState,
  reducers: {
    setTrackersCommandSendTrackersToMaintenanceResult: (
      state,
      action: PayloadAction<{
        site: string;
        result: ITrackersCommandResultSendTrackersToMaintenancePayload;
      }>
    ) => {
      state[action.payload.site] = {
        result: action.payload.result.result,
        commandType: action.payload.result.commandType,
        elevation: action.payload.result.elevation,
        reason: action.payload.result.reason,
      };
    },
    setTrackersCommandReleaseTrackersFromMaintenanceResult: (
      state,
      action: PayloadAction<{
        site: string;
        result: ITrackersCommandResultReleaseTrackersFromMaintenancePayload;
      }>
    ) => {
      state[action.payload.site] = {
        result: action.payload.result.result,
        commandType: action.payload.result.commandType,
        elevation: null,
        reason: null,
      };
    },
    setTrackersCommandSendTrackersToFreezeResult: (
      state,
      action: PayloadAction<{
        site: string;
        result: ITrackersCommandResultSendTrackersToFreezePayload;
      }>
    ) => {
      state[action.payload.site] = {
        result: action.payload.result.result,
        commandType: action.payload.result.commandType,
        elevation: null,
        reason: action.payload.result.reason,
      };
    },
  },
});
export const selectCurrentTrackerCommands = createSelector(
  [
    (state: RootState) => state.multiSitesTrackersCommands,
    (state: RootState) => state.site.siteId,
  ],
  (
    multiSiteTrackerCommandState: ITrackerCommandResultState,
    currentSite: string
  ) => {
    return (
      multiSiteTrackerCommandState[currentSite] || GetFaultInitialSiteState()
    );
  }
);

export const selectCurrentResults = createSelector(
  [
    (state: RootState) => state.multiSitesTrackersCommands,
    (state: RootState) => state.site.siteId,
  ],
  (
    multiSiteTrackerCommandState: ITrackerCommandResultState,
    currentSite: string
  ) => {
    return (
      multiSiteTrackerCommandState[currentSite] || GetFaultInitialSiteState()
    ).result;
  }
);
export const multiSitesTrackersCommandsReducer =
  multiSitesTrackersCommandsSlice.reducer;
