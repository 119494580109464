import { GridColDef } from "@mui/x-data-grid";
import { formatDateToDayMonthYear } from "../../app/DateTimeUtils";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { ComponentTypeEnum } from "../faultsModels";
import UrgencyCell from "./tableComponent/UrgencyCell";
import TimeOpenedHeader from "./tableComponent/TimeOpenedHeader";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";
import DescriptionCell from "./tableComponent/DescriptionCell";

const ActiveAlertTableColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Alert Name",
    flex: 0.5,
  },
  {
    field: "componentType",
    headerName: "Component",
    flex: 0.5,
    valueGetter: (params) => {
      return humanizeEnumValue(ComponentTypeEnum[params.value]) || NO_VALUE_PLACEHOLDER;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    renderCell: (params) => <DescriptionCell description={params.value} />,
  },
  {
    field: "severity",
    headerName: "Urgency",
    renderCell: UrgencyCell,
    flex: 0.5,
  },
  {
    field: "startTimeUtc",
    renderCell: (params) => {
      const timeOnly = formatDateToDayMonthYear(new Date(params.value));
      return <span>{timeOnly}</span>;
    },
    renderHeader: TimeOpenedHeader,
    flex: 0.5,
  },
];
export default ActiveAlertTableColumns;
