import { GridRenderCellParams } from "@mui/x-data-grid";
import getIcons from "../../../../SolarGikLib/icons/Icons";
import {
  ErrorIcons,
  IconCategory,
} from "../../../../SolarGikLib/icons/IconsModels";
import { humanizeEnumValue } from "../../../../common/EnumUtils";
import { SeverityEnum } from "../../faultsModels";
import classes from "./UrgencyCell.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../../../common/ConstantValues";

const SeverityHighIcon = getIcons(
  ErrorIcons.MultisiteHighSeverityError,
  IconCategory.Error
);
const SeverityMildIcon = getIcons(
  ErrorIcons.MultisiteMildSeverityError,
  IconCategory.Error
);

const SeverityLowIcon = getIcons(
  ErrorIcons.MultisiteLowSeverityError,
  IconCategory.Error
);

const mapSverityToIcons = (value: SeverityEnum) => {
  switch (value) {
    case SeverityEnum.High:
      return SeverityHighIcon;
    case SeverityEnum.Medium:
      return SeverityMildIcon;
    case SeverityEnum.Low:
      return SeverityLowIcon;
    default:
      return SeverityLowIcon;
  }
};

const UrgencyCell: React.FC<GridRenderCellParams> = (params) => {
  const value = params.row[params.field];
  const Icon = mapSverityToIcons(value);
  const severity = humanizeEnumValue(SeverityEnum[value]) || NO_VALUE_PLACEHOLDER;
  return (
    <div className={classes.container}>
      <div className={classes["icon-container"]}>
        <Icon width={24} height={24} />
      </div>
      <div className={classes["severity-container"]}>{severity}</div>
    </div>
  );
};

export default UrgencyCell;
