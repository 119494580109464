import axios from "axios";
import APP_CONFIG from "../app/configuration/AppConfig";
import { FeatureFlags } from "./FeatureFlagsModels";

interface GlobalFeaturesDto {
  enabledFeatures: FeatureFlags[];
}

const getFeatureFlagsAsync = async (): Promise<FeatureFlags[]> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiFeatureFlags;
  const httpResponse = await axios.get<GlobalFeaturesDto>(url);
  return httpResponse.data.enabledFeatures;
};

export default getFeatureFlagsAsync;
