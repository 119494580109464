import { FC, useEffect, useState } from "react";

import classes from "./IconLoader.module.css";
import APP_CONFIG from "../app/configuration/AppConfig";

interface LoaderProps {
  children?: React.ReactNode;
  isLoading: boolean;
}

const IconLoader: FC<LoaderProps> = ({ isLoading, children }) => {
  const [show, setShow] = useState(isLoading);
  let timer: ReturnType<typeof setTimeout>;
  useEffect(() => {
    if (isLoading) {
      setShow(true);
      timer = setTimeout(() => {
        setShow(false);
      }, APP_CONFIG.milliSecLoaderTimeOut);
    }
    if (!isLoading) {
      clearInterval(timer);
      setShow(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  return (
    <div className={classes["loader-container"]}>
      {show && (
        <>
          <div className={classes["color-overlay"]}> </div>
          <div className={classes["loader-spinner"]}></div>
        </>
      )}
      {children}
    </div>
  );
};
export default IconLoader;
