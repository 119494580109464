import axios from "axios";

import { ISitesMetadataDictionary } from "./SiteModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getSitesMetadataAsync = async (
  siteIds: string[]
): Promise<ISitesMetadataDictionary> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSitesMetadata;
  const httpResponse = await axios.post<ISitesMetadataDictionary>(url, siteIds);
  return httpResponse.data;
};
