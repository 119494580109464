import { FunctionComponent, SVGProps } from "react";

import { IListItem } from "../../../SolarGikLib/Lists/ListModels";
import { UserAccessType } from "../../user/UserStore";

export interface IEmergencyStatus {
  DesiredState: boolean;
  ReportedState: boolean;
}
export enum EmergencyState {
  EmergencyOff = "Emergency",
  EmergencySent = "Request Sent",
  EmergencyOn = "In Emergency",
}

export interface IEmergencyListitem extends IListItem {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  accessType: UserAccessType;
}

export enum EmergencyReason {
  WeatherProjectionWind = 1,
  WeatherProjectionSnow = 2,
  PanelWashing = 3,
  TrackerMaintenance = 4,
  UserMaintenance = 5,
  SoftwareUpdate = 6,
}
