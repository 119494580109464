import { FC, ReactNode } from "react";

import Header from "./header/Header";
import classes from "./MultisiteContent.module.css";
import { NavItemsConfig } from "./NavigationDrawer/DrawerConfiguration";
import NavigationDrawer from "../navigation/NavigationDrawer/NavigationDrawer";

interface MultisiteContentProps {
  children: ReactNode;
}
const MultisiteContent: FC<MultisiteContentProps> = ({ children }) => {

  return (
    <>
      <Header />
      <NavigationDrawer navigationItems={NavItemsConfig} />
      <div className={classes.children}>{children}</div>
    </>
  );
};

export default MultisiteContent;
