import { ComponentTypeEnum } from "../faultsModels";
import { IIssueInfo } from "./IssuesModels";

export function generateUniqueKey(issue: IIssueInfo, issueType: string) {
  return `${issue.deviceId}_${issueType}`;
}

export function getComponentTypes() {
  return Object.values(ComponentTypeEnum).filter(
    (value) => typeof value === "number" && value !== 0
  ) as number[];
}
