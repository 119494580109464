import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../common/table/Table";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchActiveAlerts } from "../../app/store/AlertsStore";
import ActiveAlertTableColumns from "./ActiveAlertTableColumns";
import useNumberOfRowsToDisplay from "../../../common/Hooks/useNumberOfRowsToDisplay";
import classes from "./Alerts.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { selectSiteId } from "../../sites/SiteStore";

const rowsSurroundingHeight = 276;
const rowHeight = 45;

const ActiveAlerts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageSize = useNumberOfRowsToDisplay(rowsSurroundingHeight, rowHeight);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const siteId = useSelector(selectSiteId);
  const alertsMetadata = useSelector(
    (state: RootState) => state.multiSitesAlerts.metadata
  );
  const activeAlerts = useSelector(
    (state: RootState) => state.multiSitesAlerts.activeAlerts[siteId]
  );
  const isLoading = useSelector(
    (state: RootState) => state.multiSitesAlerts.isSiteInLoading[siteId]
  );
  const isSiteError = useSelector(
    (state: RootState) => state.multiSitesAlerts.siteError[siteId]
  );

  useEffect(() => {
    dispatch(fetchActiveAlerts(siteId));
  }, [siteId]);

  useEffect(() => {
    if (isSiteError == null) {
      return;
    }
    setAlertMessage({
      text: isSiteError,
      severity: "error",
    });
  }, [isSiteError]);

  const handlePaginationChange = (params: {
    page: number;
    pageSize: number;
  }) => {
    setCurrentPage(params.page + 1);
  };

  const tableData =
    activeAlerts?.map((alert) => {
      const alertMeta = alertsMetadata[alert.id];
      return {
        metadataId: alert.id,
        severity: alert.severity,
        startTimeUtc: alert.startTimeUtc,
        description: alertMeta.severityToDescription[alert.severity],
        componentType: alertMeta.componentType,
        name: alertMeta.name,
      };
    }) || [];

  const isHaveAlertsInSite = isLoading || (!isLoading && tableData.length > 0);
  const isNoAlertsInSite =
    !isLoading && tableData.length === 0 && isSiteError == null;

  return (
    <div className={classes.container}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isNoAlertsInSite && (
        <div className={classes["no-alerts-text"]}>
          <span className={`${TextEnum.h3}`}>Congratulations!</span>
          <span className={`${TextEnum.h2}`}> No alerts in the site!</span>
        </div>
      )}
      {isHaveAlertsInSite && (
        <Table
          hideToolbar
          loading={isLoading}
          rows={tableData}
          columns={ActiveAlertTableColumns}
          customUI={{
            "& .MuiDataGrid-main": {
              borderLeft: "0.3rem solid #63a7fd",
              borderRadius: "25px",
            },
          }}
          paginationMode="client"
          onPaginationModelChange={handlePaginationChange}
          paginationModel={{
            page: currentPage - 1,
            pageSize: pageSize,
          }}
          hideFooterPagination={tableData.length <= pageSize}
          getRowId={(alert) => {
            return alert.metadataId + alert.severity + alert.startTimeUtc;
          }}
          rowHeight={rowHeight}
          columnHeaderHeight={45}
          rowSelection={false}
        />
      )}
    </div>
  );
};
export default ActiveAlerts;
