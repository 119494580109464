import {
  IServiceUrls,
  IAppConfig,
  IAppConfigPerEnvironment,
  GENERAL_APP_CONFIG,
} from "./AppConfigModels";

const getServiceUrls = (hostUrl: string): IServiceUrls => {
  const backendUrl = `${hostUrl}/backend`;
  return {
    apiHostUrl: `${hostUrl}`,
    siteAppsConfigUrlPrefix: `${backendUrl}/site-apps-config-service`,
    userRecordsUrlPrefix: `${backendUrl}/user-records`,
    siteGatewayUrlPrefix: `${backendUrl}/site-gateway`,
    historianTagsSystemBaseUrl: `${backendUrl}/historian-ts-api`,
    dualUsageUrlPrefix: `${backendUrl}/pvdualusage`,
    maintenanceServiceUrlPrefix: `${backendUrl}/maintenance-service`,
    faultsServiceUrlPrefix: `${backendUrl}/faults-service`,
  };
};

const getDevConfig = (): IAppConfigPerEnvironment => ({
  clarityProjectId: "mat7k3mcy1",
  serviceUrls: getServiceUrls("/api"),
  authConfig: {
    tenantName: "B2CDevPortal",
    clientId: "eabccd2c-fcf6-4667-9def-a71ba6b59e2d",
    authorityDomain: "B2CDevPortal.b2clogin.com",
    webApiScope:
      "https://B2CDevPortal.onmicrosoft.com/ccbe149e-7155-4dfb-b5c0-71710fe91817/access_as_user",
    signInAuth:
      "https://B2CDevPortal.b2clogin.com/B2CDevPortal.onmicrosoft.com/B2C_1_si",
  },
});

const getStagingConfig = (): IAppConfigPerEnvironment => ({
  clarityProjectId: "mcrfrngnjp",
  serviceUrls: getServiceUrls("/api"),
  authConfig: {
    tenantName: "B2CStagePortal",
    clientId: "d94d6c80-2ebf-4ac9-af3e-1397f54bbe57",
    authorityDomain: "B2CStagePortal.b2clogin.com",
    webApiScope:
      "https://B2CStagePortal.onmicrosoft.com/4cf1b273-9aa0-44bc-9656-05c55aa40da7/access_as_user",

    signInAuth:
      "https://B2CStagePortal.b2clogin.com/B2CStagePortal.onmicrosoft.com/B2C_1_si",
  },
});

const getProdConfig = (): IAppConfigPerEnvironment => ({
  clarityProjectId: "mcec1hz028",
  serviceUrls: getServiceUrls("/api"),
  authConfig: {
    tenantName: "SolargikPortalB2c",
    clientId: "33f6c390-284d-4764-b56f-295934128d07",
    authorityDomain: "SolargikPortalB2c.b2clogin.com",
    webApiScope:
      "https://SolargikPortalB2c.onmicrosoft.com/d6a06e43-2ba8-4612-bc7e-8377c5a7c054/access_as_user",
    signInAuth:
      "https://SolargikPortalB2c.b2clogin.com/SolargikPortalB2c.onmicrosoft.com/B2C_1_psi", // need to configure
  },
});

const APP_CONFIG: IAppConfig = (function (): IAppConfig {
  switch (window.location.host) {
    case "portal.solargik.com":
      return { ...GENERAL_APP_CONFIG, ...getProdConfig() };
    case "portal.green.solargik.com":
      return {
        ...GENERAL_APP_CONFIG,
        ...getProdConfig(),
        clarityProjectId: "mpnheneylz",
      };
    case "portal.stage.solargik.com":
      return { ...GENERAL_APP_CONFIG, ...getStagingConfig() };
    case "portal.dev.solargik.com":
      return { ...GENERAL_APP_CONFIG, ...getDevConfig() };
    case "localhost":
    case "localhost:3000":
      return {
        ...GENERAL_APP_CONFIG,
        ...getDevConfig(),
        serviceUrls: getServiceUrls("http://localhost:7000/api"),
        clarityProjectId: undefined,
      };
    default:
      throw new Error(
        `Couldn't figure out correct environment from host URL (${window.location.host})`
      );
  }
})();

export default APP_CONFIG;
