import { useMsal } from "@azure/msal-react";

import HeaderUi from "./HeaderUi";
import { MenuItem } from "../../../SolarGikLib/fields/Dropdown";
import { GENERAL_APP_CONFIG } from "../../app/configuration/AppConfigModels";
import { useLogout } from "../../../common/Hooks/useLogout";
//should be changed to get it in props
const headerData = {
  multisiteDashboard: "Multisite dashboard",
  spanText1: "Total daily power 13.44 ",
  spanText2: "MWh",
};

const Header = () => {
  const { instance } = useMsal();
  const Logout = useLogout();

  const menuItems: MenuItem[] = [
    {
      label: "Logout",
      onClick: () => {
        Logout(instance);
      },
    },
    {
      label: "Soma " + GENERAL_APP_CONFIG.portalVersion,
      onClick: () => {
        //eslint-disable-next-line @typescript-eslint/no-empty-function
      },
      disabled: true,
    },
  ];
  return (
    <HeaderUi
      multisiteDashboard={headerData.multisiteDashboard}
      userMenuItems={menuItems}
    />
  );
};
export default Header;
