import { GridColDef } from "@mui/x-data-grid/models/colDef";
export const createSkeletonRows = (num: number, tableColumns: GridColDef[]) => {
  return Array.from({ length: num }, () => ({
    ...tableColumns.reduce(
      (acc, column) => ({
        ...acc,
        [column.field]: "",
      }),
      {}
    ),
  }));
};
