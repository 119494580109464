import { FC } from "react";

import {
  COMMUNICATION_CSV_HEADER,
  CSV_FILE_SUFFIX,
} from "../../../common/ConstantValues";
import { createAndDownloadBlobFile } from "../../../common/DownloadFileUtils";
import { IButtonVariant } from "../../../common/Mui/StyledButton";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowOutlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";

interface DownloadTemplateProps {
  setAlertMessage: (message?: AlertMessage) => void;
  buttonText: string;
  fileName: string;
  siteId?: string;
  fileVersion?: string;
  //Can be all kinds of functions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadFileApi: any;
  buttonVariant: IButtonVariant;
}
const DownloadTemplate: FC<DownloadTemplateProps> = ({
  setAlertMessage,
  buttonText,
  fileName,
  downloadFileApi,
  siteId,
  fileVersion,
}) => {
  const handleGetTemplateCsvClicked = async () => {
    setAlertMessage(undefined);
    try {
      let file;
      if (siteId) {
        file = await downloadFileApi(siteId);
      } else if (fileVersion) {
        file = await downloadFileApi(fileVersion);
      } else {
        file = await downloadFileApi();
      }
      createAndDownloadBlobFile(
        file,
        fileName,
        COMMUNICATION_CSV_HEADER,
        CSV_FILE_SUFFIX
      );
      //errors must get any or unknown
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlertMessage({ text: error.message, severity: "error" });
    }
  };
  return (
    <SolarGikButton
      onClickFunc={handleGetTemplateCsvClicked}
      style={narrowOutlinedWhiteButton}
      text={buttonText}
    />
  );
};
export default DownloadTemplate;
