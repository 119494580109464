import { FC, useState } from "react";

import { LinearProgress } from "@mui/material";
import {
  DataGridProps,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

import { TableCellLoadingSkeletonWrapper } from "../../SolarGikLib/loaders/TableCellLoadingSkeletonWrapper";
import { TABLE_NO_DATA_MESSAGE } from "../ConstantValues";
import {
  StyledDataGridTable,
  GridToolbarStyled,
} from "../Mui/StyledDataGridTable";
import { createSkeletonRows } from "./SkeletonUtils";

interface ITableProps extends Omit<DataGridProps, "onRowSelectionModelChange"> {
  loading?: boolean;
  numberOfSkeletonRows?: number;
  rows: GridValidRowModel[];
  columns: GridColDef[];
  hideToolbar?: boolean;
  hiddenColumns?: string[];
  handleRowsSelected?: (selectedRows: number[]) => void;
  hideHeaders?: boolean;
  hideBorder?: boolean;
  isHideGridColumns?: boolean;
  noRowsMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customUI?: any;
}

const Table: FC<ITableProps> = ({
  loading = false,
  numberOfSkeletonRows = 6,
  experimentalFeatures,
  rows,
  columns,
  paginationMode,
  paginationModel,
  onCellClick,
  initialState,
  hiddenColumns,
  handleRowsSelected,
  hideFooter = false,
  hideToolbar = false,
  hideBorder = false,
  hideHeaders = false,
  isHideGridColumns = false,
  hideFooterPagination = true,
  pageSizeOptions = [10],
  noRowsMessage = TABLE_NO_DATA_MESSAGE,
  customUI,
  getRowClassName = (params) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd",
  ...otherProps
}) => {
  const filteredColumns = hiddenColumns
    ? columns.filter((column) => !hiddenColumns.includes(column.field))
    : columns;
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const rowsToShow = loading
    ? createSkeletonRows(numberOfSkeletonRows, columns)
    : rows;
  const tableKey = loading ? "loading" : `data-${rowsToShow.length}`;
  const columnsWithLoading = filteredColumns.map((column) => ({
    ...column,
    renderHeader: loading
      ? () => (
        <TableCellLoadingSkeletonWrapper loading={loading}>
          <span>{column.headerName}</span>
        </TableCellLoadingSkeletonWrapper>
      )
      : column.renderHeader, //This very very important line for multisite table will not work without it!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderCell: (params: any) => (
      <TableCellLoadingSkeletonWrapper loading={loading}>
        {column.renderCell ? column.renderCell(params) : params.value}
      </TableCellLoadingSkeletonWrapper>
    ),
  }));

  return (
    <StyledDataGridTable
      key={tableKey}
      onCellClick={onCellClick}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: hideToolbar ? undefined : GridToolbarStyled,
      }}
      experimentalFeatures={experimentalFeatures}
      rows={rowsToShow}
      columns={columnsWithLoading}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      hideFooterPagination={hideFooterPagination}
      pageSizeOptions={pageSizeOptions}
      hideFooter={hideFooter}
      getRowClassName={getRowClassName}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        const ids: GridRowId[] = [];
        newRowSelectionModel.forEach((row) => {
          ids.push(Number(row));
        });
        handleRowsSelected && handleRowsSelected(ids as number[]);
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      initialState={initialState}
      localeText={{
        toolbarColumns: "",
        toolbarFilters: "",
        toolbarDensity: "",
        toolbarExport: "",
        noRowsLabel: noRowsMessage,
      }}
      sx={{
        ...(onCellClick
          ? {
            "& .MuiDataGrid-cell": {
              cursor: "pointer",
            },
          }
          : {}),
        ...(hideHeaders && {
          "& .MuiDataGrid-columnHeaders": { display: "none" },
        }),
        ...hideBorder && { "& .MuiDataGrid-main": { border: "none" } },
        ...(isHideGridColumns && {
          "& .MuiDataGrid-cell": { borderRight: "0px", border: "0px" },
        }),
        ...(customUI && { ...customUI }),
      }}
      {...otherProps}
    />
  );
};
export default Table;
