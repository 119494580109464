import { InputAdornment, TextField } from "@mui/material";
import { useSelector } from "react-redux";

import { MaintenanceReasonRow } from "./MaintenanceReasonRow";
import classes from "./TrackersControlCommandFormUi.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  FieldIcons,
  FileCommandsIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { RootState } from "../../app/Store";
import { getMaintenanceOptionListConfig } from "../MaintenanceOptionListConfig";
import {
  TrackerElevation,
  TrackersCommandType,
  ElevationDirections,
} from "../TrackersModels";

const sendButtonStyle = {
  ...narrowBlueButton,
  width: "100%",
  "@media screen and (max-width: 1300px)": {
    display: "inline-flex",
    margin: "0px",
    fontSize: "10px",
  },
};

interface TrackersControlCommandFormUiProps {
  command: TrackersCommandType | undefined;
  setCommand: (command: TrackersCommandType) => void;
  elevation: TrackerElevation;
  setElevation: (elevation: TrackerElevation) => void;
  reason: string | null;
  setReason: (reason: string | null) => void;
  alertMessage?: AlertMessage;
  setAlertMessage: (message?: AlertMessage) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
}

const elevationDirectionOptions = Object.values(ElevationDirections);

const TrackersControlCommandFormUi = (
  props: TrackersControlCommandFormUiProps
) => {
  const {
    command,
    setCommand,
    elevation,
    setElevation,
    reason,
    setReason,
    alertMessage,
    setAlertMessage,
    handleSubmit,
    disabled,
  } = props;

  const featureFlags = useSelector(
    (state: RootState) => state.featureFlags.flags
  );
  let commandTypeOptions = Object.values(TrackersCommandType);
  if (!featureFlags.SendTrackersToFreeze) {
    commandTypeOptions = commandTypeOptions.filter(
      (x) => x !== TrackersCommandType.SendTrackersToFreeze
    );
  }

  const userType = useSelector((state: RootState) => state.user.userType);
  const setElevationFunc = (event: { target: { value: number } }) => {
    setElevation({
      value: Number(event.target.value),
      direction: elevation.direction ?? ElevationDirections.West,
    });
  };

  const setElevationDirectionFunc = (event: { target: { value: string } }) => {
    if (event.target.value.length > 0) {
      setElevation({
        value: elevation.value ?? 0,
        direction: event.target.value as ElevationDirections,
      });
    }
  };

  const SendIcon = getIcons(
    FileCommandsIcons.WhiteSend,
    IconCategory.FileCommands
  );
  const ArrowIcon = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);
  const DisabledArrowIcon = getIcons(
    FieldIcons.ArrowDownDisabled,
    IconCategory.Fields
  );
  const reasonListConfig = getMaintenanceOptionListConfig(userType);
  return (
    <form onSubmit={handleSubmit}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes.form}>
        <div className={classes.item}>
          <SolarGikAutocomplete
            options={commandTypeOptions}
            renderInputFunc={(params) => (
              <TextField
                {...params}
                label="Command"
                sx={inputStyle}
                InputLabelProps={{ className: classes["input-text-size"] }}
              />
            )}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSelect={(event: any) => {
              setCommand(event.target.value as TrackersCommandType);
            }}
            value={command}
            key={"select command"}
            id={"select command"}
            textSize={classes["input-text-size"]}
            popUpIcon={disabled ? <DisabledArrowIcon /> : <ArrowIcon />}
            disabled={disabled}
            size={"small"}
          />
        </div>
        <div className={`${classes.item} ${classes["elevation-row"]}`}>
          <div className={classes["elevation-direction"]}>
            <SolarGikAutocomplete
              options={elevationDirectionOptions}
              renderInputFunc={(params) => (
                <TextField
                  {...params}
                  sx={inputStyle}
                  label="Direction"
                  InputLabelProps={{ className: classes["input-text-size"] }}
                />
              )}
              value={elevation.direction}
              onSelect={setElevationDirectionFunc}
              key={"Elevation direction"}
              id={"Elevation direction"}
              textSize={classes["input-text-size"]}
              popUpIcon={
                disabled ||
                command !== TrackersCommandType.SendTrackersToMaintenance ? (
                  <DisabledArrowIcon />
                ) : (
                  <ArrowIcon />
                )
              }
              disabled={
                disabled ||
                command !== TrackersCommandType.SendTrackersToMaintenance
              }
              size={"small"}
            />
          </div>
          <div className={classes["elevation-value"]}>
            <SolarGikInput
              disabled={
                disabled ||
                command !== TrackersCommandType.SendTrackersToMaintenance
              }
              type="number"
              label="Elevation"
              forceLabel
              placeholder="Elevation"
              value={elevation.value}
              onChange={setElevationFunc}
              inputPropsExtras={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>
                      {elevation.direction === ElevationDirections.West
                        ? "+"
                        : "-"}
                    </span>
                  </InputAdornment>
                ),
              }}
              textSize={classes["input-text-size"]}
              size={"small"}
            />
          </div>
        </div>
        <div className={classes.item}>
          <SolarGikAutocomplete
            options={reasonListConfig}
            renderInputFunc={(params) => (
              <TextField
                {...params}
                label="Reason"
                sx={inputStyle}
                InputLabelProps={{ className: classes["input-text-size"] }}
              />
            )}
            getOptionLabel={(option) => (option?.title ? option.title : "")}
            renderOption={(props, option) => (
              <div>
                <li {...props}>
                  {option && (
                    <MaintenanceReasonRow
                      Icon={option.icon}
                      title={option?.title ?? ""}
                    />
                  )}
                </li>
              </div>
            )}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSelect={(event: any) => {
              setReason(event.target.value);
            }}
            value={null}
            key={"select reason"}
            id={"select reason"}
            textSize={classes["input-text-size"]}
            popUpIcon={
              disabled ||
              command === TrackersCommandType.ReleaseTrackersFromMaintenance ? (
                <DisabledArrowIcon />
              ) : (
                <ArrowIcon />
              )
            }
            disabled={
              disabled ||
              command === TrackersCommandType.ReleaseTrackersFromMaintenance
            }
            size={"small"}
          />
        </div>
        <div className={classes.item}>
          <SolarGikButton
            icon={<SendIcon />}
            style={sendButtonStyle}
            isDisabled={
              disabled ||
              command === undefined ||
              (command !== TrackersCommandType.ReleaseTrackersFromMaintenance &&
                !reason)
            }
            text="Send"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default TrackersControlCommandFormUi;
