import { FunctionComponent, SVGProps } from "react";

import { IListItem } from "../../SolarGikLib/Lists/ListModels";
import { UserAccessType } from "../user/UserStore";

export enum TrackersStateEnum {
  Undefined = 0,
  Init = 1,
  Homing = 2,
  Manual = 3,
  Tracking = 4,
  SmartTracking = 5,
  Sleep = 6,
  Safe = 7,
  Error = 8,
  Done = 9,
  InProgress = 10,
  Disconnected = 11,
}
export enum SendTrackersToMaintenanceResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedButAddedToMaintenanceList = 4,
  TrackerInSafeButAddedToMaintenanceList = 5,
}
export enum SendTrackersToFreezeResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedCouldNotFreeze = 4,
}
export enum ReleaseTrackersFromMaintenanceResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedButReleasedFromToMaintenanceList = 4,
  TrackerWasNotInMaintenance = 5,
}
export enum TrackersCommandType {
  ReleaseTrackersFromMaintenance = "Release",
  SendTrackersToMaintenance = "Maintenance",
  SendTrackersToFreeze = "Freeze",
}
export enum TrackersCommandReasonType {
  HighWindWeatherProtection = "Weather Protection High Wind",
  HighSnowWeatherProtection = "Weather Protection High Snow",
  PanelWashing = "Panel Washing",
  TrackerMaintenance = "Tracker Maintenance",
  ClientMaintenance = "Client Maintenance",
  SoftwareUpgrade = "Software Upgrade",
}

export enum MaintenanceReason {
  WeatherProjectionWind = 1,
  WeatherProjectionSnow = 2,
  PanelWashing = 3,
  TrackerMaintenance = 4,
  UserMaintenance = 5,
  SoftwareUpdate = 6,
}
export enum ChargingStateEnum {
  Undefined = 0,
  Unknown = 1,
  Charging = 2,
  Charged = 3,
  UsingBattery = 4,
  ChargingError = 5,
}
export interface ITrackersCommandResultReleaseTrackersFromMaintenance {
  [key: string]: ReleaseTrackersFromMaintenanceResultCodeEnum;
}
export interface ITrackersCommandSendTrackersToMaintenanceResultCodeEnum {
  [key: string]: SendTrackersToMaintenanceResultCodeEnum;
}
export interface ITrackersCommandSendTrackersToFreezeResultCodeEnum {
  [key: string]: SendTrackersToFreezeResultCodeEnum;
}
export interface ITrackersCommandResponseReleaseTrackersFromMaintenance {
  Results: { [key: string]: ReleaseTrackersFromMaintenanceResultCodeEnum };
}
export interface ITrackersCommandResponseSendTrackersToMaintenance {
  Results: { [key: string]: SendTrackersToMaintenanceResultCodeEnum };
}
export interface ITrackersCommandResponseSendTrackersToFreeze {
  Results: { [key: string]: SendTrackersToFreezeResultCodeEnum };
}
export interface ITrackersState {
  [siteId: string]: ITrackerStatus[];
}

export interface ITrackerStatus {
  updateTime: Date;
  id: number;
  name: string;
  uptime?: number;
  currentState?: number;
  currentElevation?: number;
  shadingRatio?: number;
  chargingState?: ChargingStateEnum;
  chargePercentage?: number;
}

export interface ITrackerMetaData {
  macAddress: string;
  ip: string;
  xPosition: number;
  yPosition: number;
}

export enum ElevationDirections {
  West = "West",
  East = "East",
}
export interface TrackerElevation {
  value: number;
  direction: ElevationDirections;
}

export interface IMaintenanceListitem extends IListItem {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  accessType: UserAccessType;
}
