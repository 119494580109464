import { FC } from "react";

import classes from "./LightTooltipContent.module.css";
import { TextEnum } from "../TextStyles";

interface StyledTooltipContentProps {
  name?: string;
  info: string[];
}

const LightTooltipContent: FC<StyledTooltipContentProps> = ({ name, info }) => {
  return (
    <div>
      <ul className={`${classes["tooltip-info-list"]} ${TextEnum.h5}`}>
        {name && <li className={TextEnum.h4}>{name}</li>}
        {info.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default LightTooltipContent;
