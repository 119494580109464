import React, { FC } from "react";

import { Button } from "@mui/material";
import { Popover } from "antd";

import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  IconCategory,
  SomaIcons,
} from "../../../SolarGikLib/icons/IconsModels";
import { ITrendLine } from "../TrendsModel";
import classes from "./EditLine.module.css";
import EditTrendPopupContent from "./EditTrendPopupContent";
import { TextEnum } from "../../../SolarGikLib/TextStyles";

interface EditLineProps {
  line: ITrendLine;
}
const EditLine: FC<EditLineProps> = ({ line }) => {
  const LineIcon = getIcons(SomaIcons.EditLine, IconCategory.Soma);
  return (
    <Popover
      id={line.id}
      trigger="click"
      content={<EditTrendPopupContent line={line} />}
      color={"var(--table-outline-color)"}
    >
      <Button
        className={classes["edit-button"]}
        variant="text"
        endIcon={
          <LineIcon
            stroke={line.color}
            className={classes["edit-button-icon"]}
          />
        }
      >
        <span className={`${classes["edit-button-text"]} ${TextEnum.h8}`}>
          {"Line"}
        </span>
      </Button>
    </Popover>
  );
};
export default EditLine;
