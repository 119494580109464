import { JSONSchemaType } from "ajv";

export enum Status {
  Undefined = 0,
  Ok = 1,
  Warning = 2,
  Error = 3,
}

export enum FieldState {
  Undefined = 0,
  Tracking = 1,
  Sleep = 2,
  WindSafe = 5,
  CloudEmergency = 6,
  UpsEmergency = 7,
  EngineerEmergency = 8,
  Disconnected = 10,
  UnknownError = 15,
  GeoLocationError = 16,
}

export enum McsState {
  Undefined = 0,
  Safety = 1,
  Operational = 2,
}

export enum TagValueType {
  Float = 0,
  Int = 1,
  Enum = 2,
  Bool = 3,
}

export interface IValueTime {
  value: number;
  time: Date; // UTC in Local Time
}

export enum TagTimeValidity {
  Valid = 0,
  ValidButOld = 1,
  Invalid = 2,
}

export interface ITagData {
  value: number;
  time: Date;
  timeValidity: TagTimeValidity;
  valueExist?: boolean;
}

export interface ITagLineInfo {
  id: string;
  displayName?: string;
  isCombinedYAxis: boolean;
}

export interface IMultiSiteStringToTimeValueDictionary {
  [siteId: string]: {
    [tag: string]: IValueTime;
  };
}

export interface IStringToTimeValueDictionary {
  [key: string]: IValueTime;
}

export interface ISiteIdToDisplayName {
  [key: string]: string;
}

export interface IStringToTagDataDictionary {
  [key: string]: ITagData;
}

export interface ISiteToTagDataDictionary {
  [key: string]: IStringToTagDataDictionary;
}

export interface IValuesListStartingTimeAndInterval {
  values: number[];
  startingTime: number;
  interval: number;
}

export interface IStringToTimeValueListDictionary {
  [key: string]: IValueTime[];
}

export interface IGetMultipleTagsMetaDataResponse {
  [key: string]: ITagMetaData[];
}

export interface ISiteToTagToMetaDataDictionary {
  [key: string]: ITagToMetaDataDictionary;
}

export interface ISiteToTagList {
  [key: string]: string[];
}

export interface ITagToMetaDataDictionary {
  [key: string]: ITagMetaData;
}

export interface ITagToMetaDataResponseDictionary {
  [key: string]: ITagMetaDataResponse;
}

export interface ITagMetaDataResponse {
  uniqueName: string;
  tagValueType: number;
  unitInfo: string;
  defaultDisplayName: string;
  description: string;
  high: number;
  low: number;
  tagSource: number;
}

export interface ITagMetaData {
  uniqueName: string;
  displayName: string;
  unitName: string;
  description: string;
  tagValueType: number;
  high: number;
  low: number;
  toStringConverter: (value: number) => string;
  tagSource: number;
}

export enum TagSource {
  "Storage" = 0,
  "Calculated" = 1,
}

export interface TagsState {
  tagsCache: IStringToTagDataDictionary;
}

export interface ITagsState {
  tagsCache: ISiteToTagDataDictionary;
}
export interface TagValuesStr {
  value: string;
  timestamp: Date;
}

export interface IExtendedLineChartInfo {
  id: string;
  isCombinedYAxis: boolean;
  displayName: string;
  min?: number;
  max?: number;
}

export const metaDataSchema: JSONSchemaType<ITagMetaDataResponse> = {
  type: "object",
  properties: {
    uniqueName: {
      type: "string",
    },
    tagValueType: {
      type: "number",
    },
    unitInfo: {
      type: "string",
    },
    defaultDisplayName: {
      type: "string",
    },
    description: {
      type: "string",
    },
    high: {
      type: "number",
    },
    low: {
      type: "number",
    },
    tagSource: {
      type: "number",
    },
  },
  required: ["unitInfo", "description"],
  additionalProperties: true,
};
