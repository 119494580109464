import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import getIcons from "../../SolarGikLib/icons/Icons";
import {
  GeneralIcons,
  IconCategory,
} from "../../SolarGikLib/icons/IconsModels";
import { iconButtonStyle } from "../../SolarGikLib/styles/ButtonsStyle";
import { resetSlice } from "../app/Store";

const InteractiveSolargikLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SolarGikLogoIcon = getIcons(
    GeneralIcons.SolarGikLogo,
    IconCategory.General
  );
  return (
    <IconButton
      onClick={() => {
        dispatch(resetSlice.actions.clearResults());
        navigate(`/`);
      }}
      style={iconButtonStyle}
    >
      <SolarGikLogoIcon />
    </IconButton>
  );
};

export default InteractiveSolargikLogo;
