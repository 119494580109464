import { GridColDef } from "@mui/x-data-grid";
import format from "date-fns/format";

const AgriTableColumns: GridColDef[] = [
  {
    field: "localDate",
    headerName: "Time",
    renderCell: (params) => {
      const timeOnly = format(new Date(params.value), "HH:mm");
      return <span>{timeOnly}</span>;
    },
    disableColumnMenu: true,
    sortable: false,
    width: 100,
  },
  {
    field: "shadingRatio",
    headerName: "Shadow",
    disableColumnMenu: true,
    sortable: false,
    width: 150,
  },
];
export default AgriTableColumns;
