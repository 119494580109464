import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentEnum, IUserRecord } from "../../user_records/UserRecordsModels";
import { queryUserRecords } from "../../user_records/UserRecordsAPI";
import { RootState } from "../Store";
import { TrackerNamesMap } from "../../sites/SiteModels";
import { tryGetTrackerName } from "../../trackers/TrackerName";


interface IUserRecordsState {
  [key: string]: {
    rawRecords: IUserRecord[],
    error?: string
    loading: boolean
  }
}

const initialState: IUserRecordsState = {};

export const multiSitesUserRecordsSlice = createSlice({
  name: "multiSitesUserRecords",
  initialState,
  reducers: {
    addRecord: (
      state,
      action: PayloadAction<{ site: string; record: IUserRecord }>
    ) => {
      const { site, record } = action.payload;
      if (!state[site]) {
        state[site] = { rawRecords: [], loading: false };
      }
      state[site].rawRecords.push(record);
    },
    deleteRecord: (
      state,
      action: PayloadAction<{ site: string; recordId: number }>
    ) => {
      const { site, recordId } = action.payload;
      if (state[site]) {
        state[site].rawRecords = state[site].rawRecords.filter((record) => record.id !== recordId);
      }
    },
  },
  extraReducers: (builder) => builder
    .addCase(fetchUserRecords.fulfilled, (state, action) => {
      const { siteId, records } = action.payload;
      state[siteId] = { rawRecords: records, loading: false };
    })
    .addCase(fetchUserRecords.rejected, (state, action) => {
      const { siteId } = action.meta.arg;
      state[siteId] = { rawRecords: [], loading: false, error: "Failed to load user records" };
    })
    .addCase(fetchUserRecords.pending, (state, action) => {
      const { siteId } = action.meta.arg;
      state[siteId] = { rawRecords: [], loading: true };
    })
});

type FetchUserRecordsArgs = { siteId: string, creationTimeStart: Date, creationTimeEnd: Date };

export const fetchUserRecords = createAsyncThunk(
  "multiSitesUserRecords/fetchUserRecords",
  async ({ siteId, creationTimeStart, creationTimeEnd }: FetchUserRecordsArgs) => {
    const records = await queryUserRecords(
      siteId,
      creationTimeStart,
      creationTimeEnd
    );
    return { siteId, records };
  }
);

function formatUserRecord(record: IUserRecord, trackerNamesMap?: TrackerNamesMap): IUserRecord {
  if (!trackerNamesMap || record.componentType !== ComponentEnum[ComponentEnum.Tracker]) {
    return record;
  }
  try {
    const trackerNames = record.componentName
      .replace("[", "").replace("]", "").split(",")
      .map((id: string) => ({ number: parseInt(id, 10), original: id }))
      .map(({ number, original }) => isNaN(number) ? original : tryGetTrackerName(number, trackerNamesMap));
    if (trackerNames.length == 1) {
      return {
        ...record,
        componentName: trackerNames[0]
      }
    }
    return {
      ...record,
      componentName: "[" + trackerNames.join(", ") + "]"
    }
  } catch (e) {
    console.error("Failed to format user record", e);
    return record;
  }
}

export const selectCurrentSiteFormattedUserRecords = createSelector(
  [
    (state: RootState) => state.site.trackerNamesMap,
    (state: RootState) => state.multiSitesUserRecords[state.site.siteId]
  ],
  (trackerNamesMap, userRecordsState) => {
    if (!userRecordsState || userRecordsState.loading) {
      return [];
    }
    return userRecordsState.rawRecords.map(r => formatUserRecord(r, trackerNamesMap))
  }
);

export const multiSitesUserRecordsReducer = multiSitesUserRecordsSlice.reducer;
