import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./TagsHarvesterDeploy.module.css";
import useAsyncOperationWithErrorHandling from "../../../common/Hooks/useAsyncOperationWithErrorHandling";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import {
  addOrUpdateTagInIotDevice,
  ensureSiteModuleTypeExists,
  generateTagsHarvesterConfig,
  getIotDevicesIdList,
  getModuleStatusOnDevice,
  updateMcsToUseTagsHarvester,
} from "../SiteControlAppsDeployApi";
import { selectSiteId } from "../../sites/SiteStore";

const READY_STATUS = "Running";
const CHECK_HARVESTER_STATUS_BUTTON_TEXT = "Check Tags Harvester deploy status";

const TagsHarvesterDeploy = () => {
  const [TagsHarvesterTagVersion, setTagsHarvesterTagVersion] =
    useState<string>("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [redisDeployStatus, setRedisDeployStatus] = useState<string>("");
  const [tagsHarvesterStatus, setTagsHarvesterStatus] = useState<string>("");
  const [deviceIdList, setDeviceIdList] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<string>("");
  const siteId = useSelector(selectSiteId);

  const handleError = (message: string) => {
    setAlertMessage({
      text: message,
      severity: "error",
    });
  };

  const {
    runAsyncFuncWithErrorHandling,
    runAsyncFuncWithParamsWithErrorHandling,
  } = useAsyncOperationWithErrorHandling(handleError);

  useEffect(() => {
    async function getDeviceIdList() {
      await getIotDeviceIds();
    }
    getDeviceIdList();
  }, []);

  const onSelectDeviceId = (value: string | null) => {
    if (value === null) {
      return;
    }
    if (value.startsWith(siteIdPrefix)) {
      setDeviceId(value);
    } else if (value.length > 0) {
      if (
        window.confirm(
          `The device id is ${value} and the site is ${siteId}, are you sure to continue?`
        )
      ) {
        setDeviceId(value);
      }
    }
  };

  const ensureRedisModuleExists = async () => {
    await runAsyncFuncWithParamsWithErrorHandling(
      ensureSiteModuleTypeExists,
      [siteId, "Redis", deviceId],
      "Failed to ensure site module type Redis exist"
    );

    await runAsyncFuncWithParamsWithErrorHandling(
      addOrUpdateTagInIotDevice,
      [deviceId, "redis_version", "7.2.0"],
      "Failed to add or update tag In Iot device"
    );

    setAlertMessage({
      text: "Redis tag exists/created",
      severity: "success",
    });
  };

  const getRedisModuleStatus = async () => {
    const moduleStatus = await getModuleStatus("Redis");
    setRedisDeployStatus(moduleStatus);
    if (moduleStatus !== READY_STATUS) {
      setAlertMessage({
        text: "Try again in a few minutes",
        severity: "info",
      });
    }
  };

  const getTagsHarvesterStatus = async () => {
    const moduleStatus = await getModuleStatus("TagsHarvester");
    setTagsHarvesterStatus(moduleStatus);
    if (moduleStatus === "NotExist") {
      setAlertMessage({
        text:
          "Tags Harvester module doesn't exist, Try again in a few minutes",
        severity: "info",
      });
    } else if (moduleStatus === READY_STATUS) {
      setAlertMessage({
        text: "Tags Harvester Deployed & Running",
        severity: "success",
      });
    } else {
      setAlertMessage({
        text: "Tags Harvester is deployed but not running",
        severity: "info",
      });
    }
  };

  const getModuleStatus = async (module: string) => {
    const status = await runAsyncFuncWithParamsWithErrorHandling(
      getModuleStatusOnDevice,
      [siteId, deviceId, module],
      "Failed to get module status on device"
    );
    return status;
  };

  const startDeploy = async () => {
    await runAsyncFuncWithParamsWithErrorHandling(
      ensureSiteModuleTypeExists,
      [siteId, "TagsHarvester", deviceId],
      "Failed to ensure site module type TagsHarvester exist"
    );

    await runAsyncFuncWithParamsWithErrorHandling(
      addOrUpdateTagInIotDevice,
      [deviceId, "tags_harvester_version", TagsHarvesterTagVersion],
      "Failed to add or update tag In Iot device"
    );

    setAlertMessage({
      text: "successfully start deploy Tags Harvester",
      severity: "success",
    });
  };

  const getIotDeviceIds = async () => {
    const deviceList = await runAsyncFuncWithErrorHandling(
      getIotDevicesIdList,
      "Failed to get Iot Device Id"
    );
    if (deviceList.length === 0) {
      setAlertMessage({
        text: "No Iot Device Id found",
        severity: "error",
      });
    } else {
      setDeviceIdList(deviceList);
    }
  };

  const generateTagsHarvesterConfiguration = async () => {
    await runAsyncFuncWithParamsWithErrorHandling(
      generateTagsHarvesterConfig,
      [siteId],
      "Failed to generate TagsHarvester Configuration"
    );

    setAlertMessage({
      text: `Generated TagsHarvester configuration. Configuration might take a while to apply. 
      please click the '${CHECK_HARVESTER_STATUS_BUTTON_TEXT}' button within few minutes`,
      severity: "success",
    });
  };

  const updateMcsConfigUseTagsHarvester = async () => {
    await runAsyncFuncWithParamsWithErrorHandling(
      updateMcsToUseTagsHarvester,
      [siteId],
      "Failed to update Mcs to use Tags Harvester"
    );
    setAlertMessage({
      text: "Mcs configuration updated to use Tags Harvester",
      severity: "success",
    });
  };

  const siteIdPrefix = siteId.split("-")[0];

  const filterDeviceIdList: string[] = deviceIdList.filter((x) =>
    x.startsWith(siteIdPrefix)
  );

  const isDeviceIdAndSiteIsNotMatch =
    filterDeviceIdList.length === 0 && deviceIdList.length > 0;

  const isRedisDeployReady = redisDeployStatus === READY_STATUS;

  const isTagsHarvesterRunning = tagsHarvesterStatus === READY_STATUS;

  const isTagsHarvesterDeployReady =
    tagsHarvesterStatus !== "NotExist" && tagsHarvesterStatus !== "";

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes["row"]}>
        {isDeviceIdAndSiteIsNotMatch ? (
          <SolarGikInput
            type="text"
            label="Device Id"
            placeholder="Device Id"
            value={deviceId}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setDeviceId(e.target.value)}
          />
        ) : (
          <SolarGikAutocomplete
            size="small"
            options={filterDeviceIdList}
            renderInputFunc={(params) => (
              <TextField {...params} label="Device Id" sx={inputStyle} />
            )}
            onSelect={(value: { target: { value: string } }) => {
              onSelectDeviceId(value.target.value);
            }}
            key={"device Id"}
            id={"device Id"}
          />
        )}
      </div>
      <div className={classes["row"]}>
        <SolarGikButton
          text="Ensure Redis Tag Exists"
          rootClassName={classes["button"]}
          style={{
            ...outlinedWhiteButton,
          }}
          onClickFunc={ensureRedisModuleExists}
          isDisabled={deviceId === ""}
        />
        <SolarGikButton
          text="Check Redis deploy status"
          onClickFunc={getRedisModuleStatus}
          isDisabled={deviceId === ""}
          rootClassName={classes["button"]}
          style={{
            ...outlinedWhiteButton,
            backgroundColor: isRedisDeployReady ? "var(--ok-color)" : "",
          }}
        />
      </div>
      <div className={classes["row"]}>
        <SolarGikInput
          size="small"
          textSize={TextEnum.h5}
          rootClassName={classes["white-field"]}
          type="text"
          label="tags Harvester version number"
          placeholder="tags Harvester version number"
          value={TagsHarvesterTagVersion}
          disabled={!isRedisDeployReady}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setTagsHarvesterTagVersion(e.target.value)
          }
        />
        <SolarGikButton
          rootClassName={classes["button"]}
          text="start deploy Tags Harvester"
          style={outlinedWhiteButton}
          onClickFunc={startDeploy}
          isDisabled={TagsHarvesterTagVersion === ""}
        />
      </div>

      <div className={classes["row"]}>
        <SolarGikButton
          rootClassName={classes["button"]}
          text={CHECK_HARVESTER_STATUS_BUTTON_TEXT}
          onClickFunc={getTagsHarvesterStatus}
          style={{
            ...outlinedWhiteButton,
            backgroundColor: isTagsHarvesterRunning
              ? "var(--ok-color)"
              : isTagsHarvesterDeployReady
                ? "var(--warning-color)"
                : "",
          }}
        />
        <SolarGikButton
          rootClassName={classes["button"]}
          text="Generate Tags harvester configuration"
          style={outlinedWhiteButton}
          isDisabled={!isTagsHarvesterDeployReady && !isTagsHarvesterRunning}
          onClickFunc={generateTagsHarvesterConfiguration}
        />
      </div>
      <div className={classes["row"]}>
        <SolarGikButton
          rootClassName={classes["button"]}
          text="Activate tags Harvester in MCS"
          style={outlinedWhiteButton}
          isDisabled={!isTagsHarvesterRunning}
          onClickFunc={updateMcsConfigUseTagsHarvester}
        />
      </div>
    </div>
  );
};
export default TagsHarvesterDeploy;
