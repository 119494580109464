import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { IButtonVariant } from "../../common/Mui/StyledButton";
import SolarGikAlert from "../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import Delete from "../app/crudCommands/Delete";
import Download from "../app/crudCommands/Download";
import Upload from "../app/crudCommands/Upload";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/UserStore";
import {
  deleteSprinklersPlanAsync,
  getIsPlanExist,
  getPlanAsFileAsync,
  getSprinklersPlanTemplateAsync,
  uploadNewSprinklersPlanAsync,
} from "./SprinklersAPI";
import classes from "./SprinklersCommands.module.css";
import { selectSiteId } from "../sites/SiteStore";

const SprinklersCommands = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const siteId = useSelector(selectSiteId);
  const userType = useSelector((state: RootState) => state.user.userType);
  const [isPlanExist, setIsPlanExist] = useState<boolean | undefined>(undefined);
  const [isUploadingPlan, setIsUploadingPlan] = useState<boolean>(false);

  const handleDeleteClicked = () => {
    setIsPlanExist(false);
  };
  const handleUploadClicked = () => {
    setIsPlanExist(true);
    setIsUploadingPlan(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadFailed = (error: any) => {
    setIsUploadingPlan(false);
    if (error.response.status == 404) {
      setAlertMessage({
        text: "No plan exists for this site",
        severity: "error",
      });
    } else if (error.response.status >= 500) {
      setAlertMessage({
        text:
          "Plan upload failed please contact support at support@solargik.com error code: " +
          error.response.status,
        severity: "error",
      });
    } else {
      setAlertMessage({
        text: "Plan upload failed.",
        severity: "error",
      });
    }
  };

  const handlePreUpload = () => {
    setIsUploadingPlan(true);
  };

  const getFetchingPlanStatusText = () => {
    if (isPlanExist === undefined) {
      return "";
    }
    return isPlanExist ? " Plan exists " : " No plan exists";
  };

  const checkIfPlanExist = async () => {
    try {
      const isPlanExist = await getIsPlanExist(siteId);
      setIsPlanExist(isPlanExist);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setIsPlanExist(false);
      } else {
        setAlertMessage({ text: error.message, severity: "error" });
      }
    }
  };

  useEffect(() => {
    checkIfPlanExist();
  }, [siteId]);

  const isDisabled = userType < UserAccessType.Operator;

  const fetchPlanStatusMsg = getFetchingPlanStatusText();

  const buttonVariant: IButtonVariant = { variant: "outlined" };

  return (
    <div className={classes["data-wrapper"]}>
      <SolarGikAlert
        message={alertMessage}
        setMessage={setAlertMessage}
      />

      <div className={`${classes["loading-status-row"]} ${TextEnum.h2}`}>
        Status:
        {isPlanExist == undefined && (
          <Skeleton className={classes["status-skeleton"]} variant="text" />
        )}
        {isPlanExist != undefined && (
          <span className={classes["loading-status-text"]}>
            {fetchPlanStatusMsg}
          </span>
        )}
      </div>
      <div className={`${classes.row} ${!isPlanExist && classes["partial"]}`}>
        {isPlanExist && (
          <Download
            setAlertMessage={setAlertMessage}
            buttonText={"Download Plan"}
            fileName="SprinklersPlan"
            downloadFileApi={getPlanAsFileAsync}
            siteId={siteId}
            buttonVariant={buttonVariant}
          />
        )}
        <Upload
          preUploadFunc={handlePreUpload}
          setErrorMessageFunc={setAlertMessage}
          postUploadFunc={handleUploadClicked}
          onFailFunc={handleUploadFailed}
          siteId={siteId}
          uploadApiFuncAsync={uploadNewSprinklersPlanAsync}
          buttonVariant={buttonVariant}
          isDisabled={isDisabled || isUploadingPlan}
        />
        <Download
          setAlertMessage={setAlertMessage}
          buttonText={"Download Template"}
          fileName="SprinklersTemplate"
          downloadFileApi={getSprinklersPlanTemplateAsync}
          fileVersion={"v1"}
          buttonVariant={buttonVariant}
        />
        {isPlanExist && (
          <Delete
            deleteApi={deleteSprinklersPlanAsync}
            setAlertMessage={setAlertMessage}
            siteId={siteId}
            postDeleteFunc={handleDeleteClicked}
            buttonVariant={buttonVariant}
            buttonText="Delete Plan"
            isDisabled={isDisabled}
          />
        )}
      </div>
    </div>
  );
};
export default SprinklersCommands;
