import { FC, useState } from "react";

import classes from "./TrendsOverlay.module.css";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory, SomaIcons } from "../../../SolarGikLib/icons/IconsModels";
import { TextEnum } from "../../../SolarGikLib/TextStyles";

interface TrendsOverlayProps {
  children: JSX.Element;
}
const TrendsOverlay: FC<TrendsOverlayProps> = ({ children }) => {
  const [visible, setVisible] = useState(true);
  const [cssName, setCssName] = useState<string>("icon");
  const hideOverlay = () => {
    setCssName("icon-close");
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 500);
    return () => clearTimeout(timeout);
  };
  const PlusIcon = getIcons(SomaIcons.Plus, IconCategory.Soma);
  return (
    <>
      {visible && (
        <div className={classes["custom-overlay"]}>
          <div className={`${classes["overlay-item"]} ${TextEnum.h1}`}>
            <div>Create Your Trend</div>
            <PlusIcon className={classes[cssName]}  onClick={hideOverlay} />
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default TrendsOverlay;
