import { FC } from "react";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  FileCommandsIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import classes from "./DeleteModuleType.module.css";
import { deleteModuleType } from "./ModuleMetadataApi";

interface IDeleteModuleTypeProps {
  siteId: string;
  type: string;
  deviceId: string;
  onDelete: () => void;
}

export const DeleteModuleType: FC<IDeleteModuleTypeProps> = ({
  siteId,
  type,
  deviceId,
  onDelete,
}) => {
  const DeleteIcon = getIcons(
    FileCommandsIcons.Delete,
    IconCategory.FileCommands
  );

  const handleDelete = async () => {
    const result = window.confirm(
      "Are you sure you want to delete this module type?"
    );
    if (!result) {
      return;
    }
    await deleteModuleType(siteId, type, deviceId);
    onDelete();
  };
  return <DeleteIcon onClick={handleDelete} className={classes.icon} />;
};
