import { FC } from "react";

import LightTooltip from "../../SolarGikLib/tooltip/LightTooltip";
import LightTooltipContent from "../../SolarGikLib/tooltip/LightTooltipContent";

interface HeaderTooltipProps {
  name: string;
  description: string;
  children?: React.ReactNode;
}

const HeaderTooltip: FC<HeaderTooltipProps> = ({
  name,
  description,
  children,
}) => {
  return (
    <LightTooltip
      title={<LightTooltipContent name={name} info={[description]} />}
    >
      <div>{children}</div>
    </LightTooltip>
  );
};

export default HeaderTooltip;
