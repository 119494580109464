import { ITrendLine, colors } from "./TrendsModel";
import { ITagMetaData } from "../data_point/models/TagsModels";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
export const createDefaultStyledLine = (
  siteId: string,
  tag: string, //full tag name
  category: string, //tag category
  uiName: string,
  deviceId: number | null,
  displayMsg: string,
  lineIndex: number,
  lineTagMetadata?: ITagMetaData
) => {
  const defaultStyledLine: ITrendLine = {
    id: siteId + tag,
    site: siteId,
    tag: tag,
    category: category,
    uiName: uiName,
    deviceId: deviceId,
    color: colors[lineIndex],
    isCombinedYAxis: false,
    displayName: displayMsg,
    yAxisRangeMin: lineTagMetadata?.low || 0,
    yAxisRangeMax: lineTagMetadata?.high || 100,
    titleOverride: "",
    isLineFill: false,
    isLineDashed: false,
    dashedLineValue: 15,
    values: [],
    order: lineIndex,
    chartType: ChartTypeEnum.line,
  };
  return defaultStyledLine;
};
