import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { getSiteModuleMetadata } from "./ModuleMetadataApi";
import { IModuleMetadata } from "./models";
import Table from "../../../common/table/Table";
import { createModuleMetadataTableColumns } from "./createModuleMetadataTableColumns";
import { selectSiteId } from "../../sites/SiteStore";

const ModuleMetadata = () => {
  const siteId = useSelector(selectSiteId);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [siteModulesMetadata, setSiteModulesMetadata] = useState<IModuleMetadata[]>([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const moduleMetadata = await getSiteModuleMetadata(siteId);
        setIsLoading(false);
        setSiteModulesMetadata(moduleMetadata);
      } catch (error) {
        setAlertMessage({
          text: "Fail to fetch site modules metadata",
          severity: "error",
        });
      }
    }
    setIsLoading(true);
    fetchData();
  }, []);

  const onDelete = async () => {
    try {
      const moduleMetadata = await getSiteModuleMetadata(siteId);
      setSiteModulesMetadata(moduleMetadata);
    } catch (error) {
      setAlertMessage({
        text: "Fail to fetch site modules metadata",
        severity: "error",
      });
    }
  };

  const isSiteHaveModulesMetadata =
    siteModulesMetadata.length > 0 && !isLoading;
  const tableColumns = createModuleMetadataTableColumns(onDelete);

  return (
    <div>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isSiteHaveModulesMetadata ? (
        <Table
          hideToolbar={true}
          rows={siteModulesMetadata}
          columns={tableColumns}
          disableRowSelectionOnClick={true}
          getRowId={(row: IModuleMetadata) =>
            row.DeviceId + row.Type + row.SiteId
          }
        />
      ) : (
        <div>No modules metadata found for the site</div>
      )}
    </div>
  );
};
export default ModuleMetadata;
