export const popoverStyle = {
  "& .MuiPopover-paper": {
    fontFamily: "var(--font-family)",
    boxShadow: "var(--tooltip-box-shadow)",
    backgroundColor: "var(--card-background-color)",
    borderRadius: "var(--tooltip-border-radius)",
    color: "var(--blue-color-primary)",
    marginTop: "10px",
  },
};
