import subMilliseconds from "date-fns/subMilliseconds";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidDate(d: any) {
  if (d == undefined) {
    return false;
  }
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
export function convertMillisecondsfromEpochTimeToDate(milliseconds: number) {
  return new Date(milliseconds);
}

export function substractMillisecondsfromSiteZoneTime(
  milliseconds: number,
  date: Date
) {
  return subMilliseconds(date, milliseconds);
}