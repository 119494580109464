import React, { FC, useState, useRef, useEffect } from "react";

import { IconButton } from "@mui/material";
import { CSVLink } from "react-csv";

import getIcons from "../../SolarGikLib/icons/Icons";
import {
  FileCommandsIcons,
  IconCategory,
} from "../../SolarGikLib/icons/IconsModels";
import { ITrendLine } from "../multisite_trends/TrendsModel";
import { stringifyDateTimeWithoutTimeZone } from "../app/DateTimeUtils";

interface IExportMultiSiteTagsToCsvFileProps {
  trendData: ITrendExportProps;
}
interface ITrendExportProps {
  linesConfig: ITrendLine[];
  title: string;
}
const ExportMultiSiteTagsToCsvFile: FC<IExportMultiSiteTagsToCsvFileProps> = ({
  trendData,
}) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  // type did not work with property link so we need used any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csvLink = useRef<any>(null);

  const formatDataToCSV = () => {
    const headers = ["Time"];
    const rows: Record<string, string[]> = {};

    trendData.linesConfig.forEach((line: ITrendLine) => {
      const headerName = `${line.site}-${line.tag}`;
      if (!headers.includes(headerName)) {
        headers.push(headerName);
      }

      line.values.forEach((valueTime) => {
        const timeKey = stringifyDateTimeWithoutTimeZone(valueTime.time);
        if (!rows[timeKey]) {
          rows[timeKey] = new Array(headers.length).fill("");
          rows[timeKey][0] = timeKey;
        }
        const columnIndex = headers.indexOf(headerName);
        rows[timeKey][columnIndex] = valueTime.value.toString();
      });
    });

    const formattedData = Object.values(rows);
    setCsvData([headers, ...formattedData]);
  };

  useEffect(() => {
    if (isClicked && csvData.length > 0 && csvLink.current) {
      csvLink.current.link.click();
      setIsClicked(false);
    }
  }, [csvData, isClicked]);

  const handleExportClick = () => {
    setIsClicked(true);
    formatDataToCSV();
  };
  const DownloadIcon = isClicked
    ? getIcons(FileCommandsIcons.DownloadDisabled, IconCategory.FileCommands)
    : getIcons(FileCommandsIcons.Download, IconCategory.FileCommands);
  return (
    <>
      <CSVLink
        data={csvData}
        filename={`${trendData.title}.csv`}
        ref={csvLink}
      />

      <IconButton onClick={handleExportClick} disabled={isClicked}>
        <DownloadIcon />
      </IconButton>
    </>
  );
};

export default ExportMultiSiteTagsToCsvFile;
