import { useEffect, useRef, useState } from "react";

import { FormikValues } from "formik";
import { useSelector } from "react-redux";

import TimeQueryUI from "./TimeQueryUI";
import Loader from "./Loader";
import MultiSiteTrends from "./MultiSiteTrends";
import TrendsOverlay from "./overlay/TrendsOverlay";
import ShowTrendsButton from "./showTrendsButton";
import SiteTagsQueryUI from "./SiteTagsQueryUI";
import { createDefaultStyledLine } from "./TrendControllerUtilsFunctions";
import classes from "./TrendsController.module.css";
import {
  InputFieldType,
  ITrendConfig,
  ITrendLine,
  TrendsUserEvents,
} from "./TrendsModel";
import SolarGikAlert from "../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import Card from "../../SolarGikLib/cards/Card";
import {
  convertStringToSeconds,
  fromDatePickerToUtc,
  toDateInSiteTimeZone,
} from "../app/DateTimeUtils";
import { RootState } from "../app/Store";
import { selectSitesTimeZoneOffsetInMinutes } from "../app/store/SitesStore";
import { getTagsHistoryAsync } from "../app/TagsAPI";
import { getSamplingInterval } from "../data_point/charts/ChartTagsUtils";
import {
  IHistoryTagsParams,
  IMultipleSitesHistoryTagsParams,
  ISiteToTags,
} from "../data_point/models/TagChartModel";
import { IValueTime } from "../data_point/models/TagsModels";
import {
  ConvertToTagDetailsDictionary,
  METADATA_NUMBER_ID_AS_AGG,
  TagUiModel,
} from "../data_point/TagUiNameMap";
import { ISitesMetadataDictionary } from "../sites/SiteModels";
import { UserAccessType } from "../user/UserStore";
import { getChartType } from "../charts_ui/Utils";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
import { useSiteNamesFormatter } from "../site_visual_info/SiteNameComponent";

export interface HierarchyDic {
  [key: string]: Category;
}
interface Category {
  [key: string]: UIName;
}
interface UIName {
  [key: string]: DeviceId;
}
interface DeviceId {
  [key: number]: TagUiModel;
}
//export for testing
export function GetHierarchyDictionary(
  tagUiModels: TagUiModel[],
  isEngineer: boolean
): HierarchyDic {
  const ret: HierarchyDic = {};
  for (const tagUiModel of tagUiModels) {
    if (tagUiModel.isEngineerTag && !isEngineer) {
      continue;
    }
    if (!Object.hasOwn(ret, tagUiModel.siteName)) {
      ret[tagUiModel.siteName] = {};
    }
    const siteName = ret[tagUiModel.siteName];

    if (!Object.hasOwn(siteName, tagUiModel.tagUiCategory)) {
      siteName[tagUiModel.tagUiCategory] = {};
    }
    const category = siteName[tagUiModel.tagUiCategory];

    if (!Object.hasOwn(category, tagUiModel.tagUiName)) {
      category[tagUiModel.tagUiName] = {};
    }
    const tagUiName = category[tagUiModel.tagUiName];

    const number = tagUiModel.deviceNumber ?? METADATA_NUMBER_ID_AS_AGG;
    if (!Object.hasOwn(tagUiName, number)) {
      tagUiName[number] = tagUiModel;
    }
  }
  return ret;
}
const currentDate = new Date();
const roundedMinutes = Math.floor(currentDate.getMinutes() / 10) * 10;
currentDate.setMinutes(roundedMinutes, 0, 0); // Set seconds to 0
const fromDate = new Date(currentDate.getTime() - 60 * 60 * 1000);
const toDate = currentDate;
const samplingInterval = convertStringToSeconds(
  getSamplingInterval(fromDate, toDate)
);
const initTrendConfig: ITrendConfig = {
  title: "",
  rangeTime: [fromDate, toDate],
  samplingInterval: samplingInterval,
  chartType: ChartTypeEnum.line,
};
//this components is in charge to call API, to save ang get store values
const TrendsController = () => {
  const siteNameFormatter = useSiteNamesFormatter();
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [linesConfig, setLinesConfig] = useState<ITrendLine[]>([]);
  const [trendConfig, setTrendConfig] = useState<ITrendConfig>(initTrendConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowUpdateButton, setIsShowUpdateButton] = useState(false);
  const [focusedField, setFocusedField] = useState<InputFieldType>(
    InputFieldType.RangeTime
  );
  const [isUserFirstEntryInTrends, setIsUserFirstEntryInTrends] =
    useState<boolean>(false);
  const multiSiteVisualInfo = useSelector((state: RootState) => state.multiSitesVisualInfo);
  const lineStateRef = useRef(linesConfig);
  const numOfLines = useRef(0);
  const userName = useSelector((state: RootState) => state.user.userName);
  const onboardingLocalStorageKey = `${userName}:TrendsOnBoardingState`;
  useEffect(() => {
    const isOnboarded = localStorage.getItem(onboardingLocalStorageKey);
    setIsUserFirstEntryInTrends(isOnboarded !== "true");
  }, [userName]);

  //needed for the event listener state
  const setMyState = (data: ITrendLine[]) => {
    lineStateRef.current = data;
    setLinesConfig(data);
  };
  const siteIds = useSelector((state: RootState) => state.user.siteIds);
  const [hierarchyDic, setHierarchyDic] = useState<HierarchyDic>({});
  const multiSitesTagsMetadataState = useSelector(
    (state: RootState) => state.multiSitesTagsMetadata
  );
  const multiSitesMetadataState: ISitesMetadataDictionary = useSelector(
    (state: RootState) => state.multiSitesMetadata
  );
  const userType: UserAccessType = useSelector(
    (state: RootState) => state.user.userType
  );
  const isEngineer = userType >= UserAccessType.Engineer;

  const apiParameters = useRef<IMultipleSitesHistoryTagsParams>({
    siteToTags: [],
    fromDateInZoneTime: fromDate,
    toDateInZoneTime: toDate,
    samplingIntervalInSeconds: samplingInterval,
  });

  const setTime = (rangeTime: Date[]) => {
    setAlertMessage(undefined);
    apiParameters.current.fromDateInZoneTime = rangeTime[0];
    apiParameters.current.toDateInZoneTime = rangeTime[1];
    const samplingInterval = getSamplingInterval(
      apiParameters.current.fromDateInZoneTime,
      apiParameters.current.toDateInZoneTime
    );
    const chartType = getChartType(rangeTime[0], rangeTime[1]);
    const intervalInSeconds = convertStringToSeconds(samplingInterval);
    apiParameters.current.samplingIntervalInSeconds = intervalInSeconds;
    setTrendConfig({
      ...trendConfig,
      samplingInterval: intervalInSeconds,
      rangeTime: [rangeTime[0], rangeTime[1]],
      chartType: chartType,
    });
    SetNextFocusedField(InputFieldType.RangeTime);
    if (linesConfig.length > 0) {
      setIsShowUpdateButton(true);
    }
  };
  const SetNextFocusedField = (inputFieldName: InputFieldType) => {
    switch (inputFieldName) {
      case InputFieldType.RangeTime:
        setFocusedField(InputFieldType.SiteId);
        break;
      case InputFieldType.SiteId:
        setFocusedField(InputFieldType.Category);
        break;
      case InputFieldType.Category:
        setFocusedField(InputFieldType.UiName);
        break;
      case InputFieldType.UiName:
        setFocusedField(InputFieldType.DeviceId);
        break;
      case InputFieldType.DeviceId:
      default:
        setFocusedField(InputFieldType.Undefined);
    }
  };

  const handleShowButton = async () => {
    const siteToTags: ISiteToTags[] = [];
    linesConfig.forEach((line) => {
      const tag = line.tag;
      if (!siteToTags.find((site) => site.siteId === line.site)) {
        siteToTags.push({ siteId: line.site, tags: [tag] });
      } else {
        siteToTags.find((site) => site.siteId === line.site)?.tags.push(tag);
      }
    });
    apiParameters.current.siteToTags = siteToTags;
    const params = apiParameters.current;
    if (params.siteToTags.length === 0) {
      setAlertMessage({
        text: "No tags selected.",
        severity: "error",
      });
    } else if (
      params.fromDateInZoneTime.getTime() === params.toDateInZoneTime.getTime()
    ) {
      setAlertMessage({
        text: "Invalid time range.",
        severity: "error",
      });
    } else if (params.samplingIntervalInSeconds == 0) {
      setAlertMessage({
        text: "Invalid sampling interval.",
        severity: "error",
      });
    } else {
      const apiParamsArr = convertUserRequestToQueryPerSiteZone(
        params,
        multiSitesMetadataState
      );
      try {
        setIsLoading(true);
        const responses = await Promise.all(
          apiParamsArr.map(async (apiParams) => {
            try {
              const response = await getTagsHistoryAsync(apiParams);
              return { apiParams, response };
            } catch (error) {
              console.error("Error fetching data:", error);
              throw error; // Rethrow the error to be caught by the outer catch block
            }
          })
        );

        responses.forEach(({ apiParams, response }) => {
          Object.entries(response).forEach(([tag, valueTimeArr]) => {
            const convertedResponse = convertSiteResponseToSiteZoneTime(
              valueTimeArr,
              multiSitesMetadataState,
              apiParams.siteId
            );
            updateLinesConfig(
              `${apiParams.siteId + tag}`,
              "values",
              convertedResponse
            );
          });
        });
        setIsLoading(false);
        if (userName !== null) {
          localStorage.setItem(onboardingLocalStorageKey, "true");
        } else {
          console.error(
            "User Id in local storage is null, can't update state of trends onboarding"
          );
        }
      } catch (error) {
        setIsLoading(false);
        setAlertMessage({
          text: "Server Error. Contact support",
          severity: "error",
        });
        console.error("TrendsController: handleShowButton: error: ", error);
      }

      setIsShowUpdateButton(false);
    }
  };

  const handleAddTag = (values: FormikValues) => {
    const deviceNumber = values.deviceId
      ? values.deviceId
      : METADATA_NUMBER_ID_AS_AGG;
    const uiModel =
      hierarchyDic[values.siteId][values.category][values.uiName][deviceNumber];
    const originalTag = uiModel.tagId;
    const lineId = values.siteId + originalTag;
    const tag = linesConfig.find((line) => line.id === lineId);
    if (tag) {
      setAlertMessage({
        text: "Tag already exists in the list.",
        severity: "error",
      });
    } else {
      setAlertMessage(undefined);
      const lineIndex = numOfLines.current++;
      const newLinesConfig = [...linesConfig];
      const fmtSiteId = siteNameFormatter(values.siteId);
      const lineTooltipMsg = fmtSiteId + " - " + values.uiName +
        (values.deviceId ? ` #${values.deviceId}` : "");
      const tagsMetadata = multiSitesTagsMetadataState[values.siteId];
      const lineTagMetadata = tagsMetadata
        ? tagsMetadata[originalTag]
        : undefined;
      const newLine = createDefaultStyledLine(
        values.siteId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        originalTag,
        values.category,
        values.uiName,
        values.deviceId,
        lineTooltipMsg,
        lineIndex,
        lineTagMetadata
      );
      newLinesConfig.push(newLine);
      setMyState(newLinesConfig);
    }
  };

  const deleteLine = (lineId: string) => {
    const updatedLinesConfig = lineStateRef.current.filter(
      (line: ITrendLine) => line.id !== lineId
    );
    setMyState(updatedLinesConfig);
  };

  const updateLinesConfig = (
    lineId: string,
    propertyToUpdate: string,
    //the value can be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    const updatedLinesConfig = lineStateRef.current.map((line: ITrendLine) => {
      if (line.id === lineId) {
        return {
          ...line,
          isShowLine: line.isShowLine ?? true,
          [propertyToUpdate]: value,
        };
      }
      return line;
    });
    setMyState(updatedLinesConfig);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCustomEvent = (event: any) => {
    const { lineId, value } = event.detail;
    const changeType = event.type;

    // Handle the different types of changes
    if (changeType === TrendsUserEvents[TrendsUserEvents.ColorChange]) {
      updateLinesConfig(lineId, "color", value);
    } else if (changeType === TrendsUserEvents[TrendsUserEvents.FillChange]) {
      updateLinesConfig(lineId, "isLineFill", value);
    } else if (changeType === TrendsUserEvents[TrendsUserEvents.DashChange]) {
      updateLinesConfig(lineId, "isLineDashed", value);
    } else if (changeType === TrendsUserEvents[TrendsUserEvents.MinChange]) {
      updateLinesConfig(lineId, "yAxisRangeMin", value);
    } else if (changeType === TrendsUserEvents[TrendsUserEvents.MaxChange]) {
      updateLinesConfig(lineId, "yAxisRangeMax", value);
    } else if (
      changeType === TrendsUserEvents[TrendsUserEvents.ShowLineChange]
    ) {
      updateLinesConfig(lineId, "isShowLine", value);
    } else if (changeType === TrendsUserEvents[TrendsUserEvents.DeleteTag]) {
      deleteLine(lineId);
    } else {
      console.debug(
        "TrendsController: handleCustomEvent: unknown event type: ",
        changeType
      );
    }
  };

  useEffect(() => {
    const hierarchyDic = siteIds.reduce((accumulator: HierarchyDic, siteId: string) => {
      const tagMetaData = multiSitesTagsMetadataState[siteId];
      if (!tagMetaData) {
        console.error(`tagMetaData for siteId: ${siteId} is null`);
        return accumulator;
      }
      const tagNames = Object.keys(tagMetaData);
      const isAgriSiteVisible = multiSiteVisualInfo[siteId]?.isAgriPageVisible;
      const tagDetails = ConvertToTagDetailsDictionary(siteId, tagNames, isAgriSiteVisible);
      const hierarchyDictionary = GetHierarchyDictionary(
        tagDetails,
        isEngineer
      );
      accumulator[siteId] = hierarchyDictionary[siteId];
      return accumulator;
    }, {});
    setHierarchyDic(hierarchyDic);
  }, []);

  useEffect(() => {
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.ColorChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.FillChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.DashChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.MinChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.MaxChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.ShowLineChange],
      handleCustomEvent
    );
    document.addEventListener(
      TrendsUserEvents[TrendsUserEvents.DeleteTag],
      handleCustomEvent
    );

    return () => {
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.ColorChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.FillChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.DashChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.MinChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.MaxChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.ShowLineChange],
        handleCustomEvent
      );
      document.removeEventListener(
        TrendsUserEvents[TrendsUserEvents.DeleteTag],
        handleCustomEvent
      );
    };
  }, []);
  const pageContent = (
    <div className={classes["grid-container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={`${classes["time-query"]}`}>
        <TimeQueryUI
          initDateRangeValues={initTrendConfig.rangeTime}
          setRangeTime={setTime}
          focusedField={focusedField}
        />
      </div>
      <div className={`${classes["siteTag-query"]}`}>
        <Card
          wrapperStyle={{ paddingTop: "0px" }}
          contentStyle={{ display: "block" }}
        >
          <SiteTagsQueryUI
            hierarchyDic={hierarchyDic}
            chartType={trendConfig.chartType}
            linesConfig={linesConfig}
            handleAddTag={handleAddTag}
            setIsShowUpdateButton={setIsShowUpdateButton}
            focusedFieldChanged={SetNextFocusedField}
            focusedField={focusedField}
          />
          <ShowTrendsButton
            isShowUpdateButton={isShowUpdateButton}
            handleShowButtonClicked={handleShowButton}
          />
        </Card>
      </div>
      <div
        className={` ${classes["trend-card"]} ${isLoading ? classes["hide"] : ""
          } `}
      >
        <Card wrapperStyle={{ paddingTop: "0px" }}>
          <MultiSiteTrends
            trendConfig={trendConfig}
            linesConfig={linesConfig}
          />
        </Card>
      </div>
      <div
        className={` ${classes["trend-card"]} ${!isLoading ? classes["hide"] : ""
          } `}
      >
        <Card wrapperStyle={{ paddingTop: "0px" }}>
          <Loader />
        </Card>
      </div>
    </div>
  );

  return (
    <>
      {isUserFirstEntryInTrends && <TrendsOverlay>{pageContent}</TrendsOverlay>}
      {!isUserFirstEntryInTrends && pageContent}
    </>
  );
};
export default TrendsController;
function convertUserRequestToQueryPerSiteZone(
  params: IMultipleSitesHistoryTagsParams,
  multiSitesMetadataState: ISitesMetadataDictionary
): IHistoryTagsParams[] {
  const apiParams: IHistoryTagsParams[] = [];
  params.siteToTags.forEach((siteToTags) => {
    const siteTimezoneOffset = selectSitesTimeZoneOffsetInMinutes(
      multiSitesMetadataState,
      siteToTags.siteId
    );
    const fromDateInUtc = fromDatePickerToUtc(
      params.fromDateInZoneTime,
      siteTimezoneOffset
    );
    const toDateInUtc = fromDatePickerToUtc(
      params.toDateInZoneTime,
      siteTimezoneOffset
    );
    apiParams.push({
      siteId: siteToTags.siteId,
      tags: siteToTags.tags,
      fromDateInZoneTime: fromDateInUtc,
      toDateInZoneTime: toDateInUtc,
      samplingIntervalInSeconds: samplingInterval,
    });
  });
  return apiParams;
}

function convertSiteResponseToSiteZoneTime(
  valueTimeArr: IValueTime[],
  multiSitesMetadataState: ISitesMetadataDictionary,
  siteId: string
) {
  const updatedValueTimeArr = valueTimeArr.map((valueTime) => ({
    value: valueTime.value,
    time: toDateInSiteTimeZone(
      valueTime.time,
      selectSitesTimeZoneOffsetInMinutes(multiSitesMetadataState, siteId)
    ),
  }));
  return updatedValueTimeArr;
}
