import { GridColDef } from "@mui/x-data-grid";
import ActiveAlertTableColumns from "./ActiveAlertTableColumns";
import { formatDateToDayMonthYear } from "../../app/DateTimeUtils";

const HistoryAlertTableColumns: GridColDef[] = [
  ...ActiveAlertTableColumns,
  {
    field: "endTimeUtc",
    headerName: "Time Resolved",
    flex: 0.5,
    renderCell: (params) => {
      const timeOnly = formatDateToDayMonthYear(new Date(params.value));
      return <span>{timeOnly}</span>;
    },
  },
];
export default HistoryAlertTableColumns;
