import { FC } from "react";

import { TextEnum } from "../../../SolarGikLib/TextStyles";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory, SomaIcons } from "../../../SolarGikLib/icons/IconsModels";
import classes from "./AgriAverageShadingImg.module.css";

interface IAgriAverageShadingImgProps {
  shadingPercent: number;
}

const ConnectorIcon = getIcons(SomaIcons.Connector, IconCategory.Soma);

const AgriAverageShadingImg: FC<IAgriAverageShadingImgProps> = ({
  shadingPercent,
}) => {
  const getIcon=()=> {
    if (shadingPercent >= 0 && shadingPercent < 25) {
      return (getIcons(SomaIcons.Shading025, IconCategory.Soma));
    } else if (shadingPercent >= 25 && shadingPercent < 50) {
      return(getIcons(SomaIcons.Shading2550, IconCategory.Soma));
    } else if (shadingPercent >= 50 && shadingPercent < 75) {
      return(getIcons(SomaIcons.Shading5075, IconCategory.Soma));
    } else if (shadingPercent >= 75 && shadingPercent <= 100) {
      return(getIcons(SomaIcons.Shading75100, IconCategory.Soma));
    }
    return getIcons(SomaIcons.Shading025, IconCategory.Soma);
  }
  
  const Icon = getIcon();
  
  return (
      <div className={classes.row}>
        <div className={classes.column}>
          <span className={`${TextEnum.h1} ${classes["shading-percent"]}`}>
            {shadingPercent + `%`}
          </span>
          <ConnectorIcon />
        </div>
        <Icon />
      </div>
  );
}
export default AgriAverageShadingImg;
