export interface IModuleMetadata {
  SiteId?: string;
  DeviceId: string;
  Type: ModuleType;
}

export enum ModuleType {
  Mcs,
  SiteToCloudShipper,
  TagHarvester,
  Das,
  Redis,
}
