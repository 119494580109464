import { useSelector } from "react-redux";

import { NavItemsConfig } from "./DrawerConfiguration";
import { RootState } from "../../app/Store";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import { selectSiteId } from "../../sites/SiteStore";

export default function SiteNavigationDrawer() {
  const siteId = useSelector(selectSiteId);
  const currentSiteSiteVisualInfo = useSelector((state: RootState) => state.multiSitesVisualInfo[siteId]);
  const isAgriPageVisible: boolean =
    currentSiteSiteVisualInfo.isAgriPageVisible;
  const isEnableUserRecordsFeature: boolean =
    currentSiteSiteVisualInfo.isEnableUserRecordsFeature;
  const isEnableSprinklersFeature: boolean =
    currentSiteSiteVisualInfo.isEnableSprinklersFeature;
  const isEnableWeatherFeature: boolean =
    currentSiteSiteVisualInfo.isEnableWeatherFeature;
  const isEnableIssuesFeature: boolean =
    currentSiteSiteVisualInfo.isEnableIssuesFeature;

  const filteredDrawerItems = NavItemsConfig.filter(
    (item) =>
      !(
        (item.pageName === "User Records" && !isEnableUserRecordsFeature) ||
        (item.pageName === "Sprinklers" && !isEnableSprinklersFeature) ||
        (item.pageName === "Agriculture" && !isAgriPageVisible) ||
        (item.pageName === "Weather" && !isEnableWeatherFeature) ||
        (item.pageName === "Issues" && !isEnableIssuesFeature)
      )
  );

  const editedSiteName = "/" + encodeURI(siteId);

  return (
    <NavigationDrawer
      navigationItems={filteredDrawerItems}
      navigationPrefx={editedSiteName}
    />
  );
}
