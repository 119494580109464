import { FC } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik, Form, FormikValues } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import classes from "./AddingNewUserRecordUi.module.css";
import {
  ComponentEnum,
  IUserRecord,
  RecordTypeEnum,
} from "./UserRecordsModels";
import SolarGikButton from "../../SolarGikLib/Button";
import { narrowOutlinedWhiteButton } from "../../SolarGikLib/styles/ButtonsStyle";
import {
  YEAR_TO_SECOND_FORMAT,
  changeYearInDateToUpperCase,
  formatDateToDayMonthYearHourMinuteSeconds,
  getCurrentDateInSiteTimeZone,
} from "../app/DateTimeUtils";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/UserStore";
interface AddingNewUserRecordUiProps {
  addRecord: (values: IUserRecord) => void;
  siteTimeOffset: number;
}
const AddingNewUserRecordUi: FC<AddingNewUserRecordUiProps> = ({
  addRecord,
  siteTimeOffset,
}) => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const initialValues: IUserRecord = {
    eventDate: getCurrentDateInSiteTimeZone(siteTimeOffset).getTime(),
    title: "",
    reporter: "",
    componentType: "",
    componentName: "",
    recordType: "",
    description: "",
    isEngineerOnly: false,
  };
  const onSubmit = (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const newRecord: IUserRecord = {
      eventDate: values.eventDate,
      title: values.title,
      reporter: values.reporter,
      componentType: values.componentType,
      componentName: values.componentName,
      recordType: values.recordType,
      description: values.description,
      isEngineerOnly: values.isEngineerOnly,
    };
    addRecord(newRecord);
    setSubmitting(false);
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    reporter: Yup.string().required("Required"),
    componentName: Yup.string().required("Required"),
    componentType: Yup.string().required("Required"),
    recordType: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    isEngineerOnly: Yup.bool().required("Required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        setSubmitting
      }) => (
        <Form className={classes.form}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              ampm={true}
              className={classes.field}
              closeOnSelect={true}
              label="Event Date in site time zone"
              value={values.eventDate}
              onChange={(newValue: Date | null) => {
                setFieldValue("eventDate", newValue);
              }}
              disableFuture={true}
              inputFormat={changeYearInDateToUpperCase(YEAR_TO_SECOND_FORMAT)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  error={touched.eventDate && Boolean(errors.eventDate)}
                  onBlur={handleBlur}
                  value={formatDateToDayMonthYearHourMinuteSeconds(
                    new Date(values.eventDate)
                  )}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            className={classes.field}
            label="Title"
            name="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title && errors.title}
          />
          <TextField
            fullWidth
            className={classes.field}
            label="Reporter"
            name="reporter"
            value={values.reporter}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.reporter && Boolean(errors.reporter)}
            helperText={touched.reporter && errors.reporter}
          />
          <FormControl fullWidth>
            <InputLabel
              className={classes["select-label"]}
              id="component-type-label"
            >
              Select a Component
            </InputLabel>
            <Select
              className={classes.field}
              labelId="component-type-label"
              label="Component"
              name="componentType"
              placeholder="Select a Component"
              value={values.componentType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.componentType && Boolean(errors.componentType)}
            >
              {Object.values(ComponentEnum).map((component: string) => (
                <MenuItem key={component} value={component}>
                  {component}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            className={classes.field}
            label="Component Id"
            name="componentName"
            value={values.componentName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.componentName && Boolean(errors.componentName)}
            helperText={touched.componentName && errors.componentName}
          />

          <FormControl fullWidth>
            <InputLabel id="note-type-label">Record Type</InputLabel>
            <Select
              className={classes.field}
              labelId="note-type-label"
              label="RecordType"
              name="recordType"
              value={values.recordType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.recordType && Boolean(errors.recordType)}
            >
              {Object.values(RecordTypeEnum).map((recordType: string) => (
                <MenuItem key={recordType} value={recordType}>
                  {recordType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {userType >= UserAccessType.Engineer && (
            <>
              <FormControl fullWidth>
                <InputLabel id="is-engineer-label">Is Engineer Only</InputLabel>
                <Select
                  className={classes.field}
                  labelId="is-engineer-label"
                  label="isEngineerOnly"
                  name="isEngineerOnly"
                  value={values.isEngineerOnly}
                  onChange={(e) => {
                    const isEngineerOnly = e.target.value === "true";
                    setFieldValue("isEngineerOnly", isEngineerOnly);
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.isEngineerOnly && Boolean(errors.isEngineerOnly)
                  }
                >
                  <MenuItem value={"true"}>True</MenuItem>
                  <MenuItem value={"false"}>False</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <TextField
            multiline
            fullWidth
            className={classes.field}
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            rows={6}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
          <div className={classes["add-button-container"]}>
            <SolarGikButton
              style={narrowOutlinedWhiteButton}
              text="Add"
              onClickFunc={() => onSubmit(values, {setSubmitting})}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddingNewUserRecordUi;
