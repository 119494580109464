import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subMonths } from "date-fns";
import {
  IActiveAlert,
  IAlertMetadata,
  IHistoryAlert,
} from "../../faults/alerts/alertModels";
import {
  getActiveAlertsAsync,
  getAlertsMetadataAsync,
  getHistoryAlertsAsync as getAlertsHistoryAsync,
} from "../../faults/alerts/alertsApi";
import { SeverityEnum } from "../../faults/faultsModels";

const defaultIssuesState: IAlertsState = {
  activeAlerts: {},
  alertsHistory: {},
  metadata: {},
  isSiteInLoading: {},
  siteError: {},
};

export interface IAlertsState {
  activeAlerts: { [siteId: string]: IActiveAlert[] };
  alertsHistory: { [siteId: string]: IHistoryAlert[] };
  metadata: { [id: number]: IAlertMetadata };
  isSiteInLoading: { [siteId: string]: boolean };
  siteError: { [siteId: string]: string | null };
}

export const multiSitesAlertsSlice = createSlice({
  name: "multiSitesAlerts",
  initialState: defaultIssuesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertMetadata.fulfilled, (state, action) => {
        const { alertsMetadata } = action.payload;
        state.metadata = alertsMetadata.reduce((accumulator, metadata) => {
          const id = metadata.id;

          updateSeverityDescriptions(metadata);

          accumulator[id] = metadata;
          return accumulator;
        }, {} as { [id: number]: IAlertMetadata });
      })
      .addCase(fetchActiveAlerts.fulfilled, (state, action) => {
        const { siteId, activeAlerts } = action.payload;
        state.activeAlerts[siteId] = activeAlerts;
        state.isSiteInLoading[siteId] = false;
        state.siteError[siteId] = null;
      })
      .addCase(fetchActiveAlerts.pending, (state, action) => {
        const siteId = action.meta.arg;
        state.isSiteInLoading[siteId] = true;
      })
      .addCase(fetchActiveAlerts.rejected, (state, action) => {
        const siteId = action.meta.arg;
        state.isSiteInLoading[siteId] = false;
        state.siteError[siteId] =
          "Error while fetching alerts, contact support for help";
      })
      .addCase(fetchHistoryAlerts.fulfilled, (state, action) => {
        const { siteId, alertsHistory } = action.payload;
        state.alertsHistory[siteId] = alertsHistory;
        state.isSiteInLoading[siteId] = false;
        state.siteError[siteId] = null;
      })
      .addCase(fetchHistoryAlerts.pending, (state, action) => {
        const siteId = action.meta.arg;
        state.isSiteInLoading[siteId] = true;
      })
      .addCase(fetchHistoryAlerts.rejected, (state, action) => {
        const siteId = action.meta.arg;
        state.isSiteInLoading[siteId] = false;
        state.siteError[siteId] =
          "Error while fetching alerts, contact support for help";
      });
  },
});

export const fetchAlertMetadata = createAsyncThunk(
  "multiSitesAlerts/fetchAlertMetadata",
  async () => {
    const alertsMetadata = await getAlertsMetadataAsync();
    return {
      alertsMetadata,
    };
  }
);

export const fetchActiveAlerts = createAsyncThunk(
  "multiSitesAlerts/fetchActiveAlerts",
  async (siteId: string) => {
    const activeAlerts = await getActiveAlertsAsync(siteId);
    return {
      siteId,
      activeAlerts,
    };
  }
);

export const fetchHistoryAlerts = createAsyncThunk(
  "multiSitesAlerts/fetchHistoryAlerts",
  async (siteId: string) => {
    const sixMonthsAgo = subMonths(new Date(), 6);
    const prams = {
      siteId: siteId,
      from: sixMonthsAgo,
      to: new Date(),
      includeAlreadyOpenAlerts: false,
    };
    const alertsHistory = await getAlertsHistoryAsync(prams);

    return {
      siteId,
      alertsHistory,
    };
  }
);

const updateSeverityDescriptions = (metadata: IAlertMetadata) => {
  const severityToDescription = metadata.severityToDescription;
  Object.keys(severityToDescription).forEach((severity: string) => {
    const severityEnum: SeverityEnum = severity as unknown as SeverityEnum;
    severityToDescription[severityEnum] = severityToDescription[severityEnum]
      .replace("{Components}", "Components")
      .replace("{EmergencyType}", "Emergency");
  });
};
export const multiSitesAlertsReducer = multiSitesAlertsSlice.reducer;
