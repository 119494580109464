import { FC, useState } from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  Drawer,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import classes from "./NavigationDrawer.module.css";
import { NavConfig } from "./NavigationDrawerModels";
import { drawerStyling } from "../../../common/Mui/MuiStyling";
import { RootState } from "../../app/Store";
import { UserAccessType } from "../../user/UserStore";

interface INavigationDrawerProps {
  navigationItems: NavConfig[];
  navigationPrefx?: string;
}

const NavigationDrawer: FC<INavigationDrawerProps> = ({
  navigationItems,
  navigationPrefx
}) => {
  const userLevel: UserAccessType = useSelector(
    (state: RootState) => state.user.userType
  );

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseHover = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const list = () => (
    <List className={classes["list"]}>
      {navigationItems.map((item, index) => {
        if (userLevel >= item.userLevel) {
          return (
            <NavLink
              to={navigationPrefx ? `${navigationPrefx}${item.pagePath}` : item.pagePath}
              key={item.pagePath}
            >
              {({ isActive }) => {
                const isHovered = hoveredIndex === index;
                const ItemIcon = isActive
                  ? item.activeIcon
                  : isHovered
                    ? item.hoverIcon
                    : item.icon;
                return (
                  <ListItem
                    className={classes["list-item"]}
                    key={item.pagePath}
                  >
                    <ListItemIcon>
                      <ItemIcon
                        onMouseOver={() => handleMouseHover(index)}
                        onMouseOut={handleMouseOut}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              }}
            </NavLink>
          );
        }
        return null;
      })}
    </List>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={drawerStyling}
      >
        {list()}
      </Drawer>
    </Box>
  );
};

export default NavigationDrawer;
