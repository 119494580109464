import { GridColDef } from "@mui/x-data-grid";

import { TextEnum } from "../../../SolarGikLib/TextStyles";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import DeleteLine from "./DeleteLine";
import EditLine from "./EditLine";
import MinMax from "./MinMax";
import classes from "./selectedTagsColumns.module.css";
import ShowLine from "./ShowLine";
import { is150PercentScreen } from "../../../common/WindowUtils";
import { ITrendLine } from "../TrendsModel";
import { SiteNameFormatterFunc } from "../../site_visual_info/SiteNameComponent";

export const selectedTagsColumns = (siteNameFormatter: SiteNameFormatterFunc): GridColDef<ITrendLine>[] => {
  return [
    {
      field: "delete",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => <DeleteLine line={params.row} />,
    },
    { field: "__check__", headerName: "Checkbox", width: 5 },
    {
      field: "Tag",
      flex: 0.4,
      renderCell: (params) => {
        const is150Percent = is150PercentScreen();
        const content = (
          <div className={classes.container}>
            <div className={`${TextEnum.h6} ${classes["tag-name"]}`}>
              <span>{siteNameFormatter(params.row.site) + ": " + params.row.category}</span>
            </div>

            <div className={TextEnum.h5}>{`${params.row.uiName}${params.row.deviceId != null
                ? " #" + params.row.deviceId
                : ""
              }`}</div>
          </div>
        );
        if (is150Percent) {
          return (
            <DarkTooltip
              placement="top"
              title={`${siteNameFormatter(params.row.site) + ": " + params.row.category + ":"} ${params.row.uiName
                }${params.row.deviceId != null ? " #" + params.row.deviceId : ""}`}
            >
              {content}
            </DarkTooltip>
          );
        }

        return content;
      },
    },
    {
      field: "Line",
      flex: 0.1,
      renderCell(params) {
        return <EditLine line={params.row} />;
      },
    },
    {
      field: "Range",
      flex: 0.4,
      renderCell(params) {
        return <MinMax line={params.row} />;
      },
    },
    {
      field: "ShowLine",
      flex: 0.1,
      renderCell(params) {
        return <ShowLine line={params.row} />;
      },
    },
  ]
};