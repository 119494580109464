import { FC } from "react";

import { Button } from "@mui/material";

import getIcons from "../../../SolarGikLib/icons/Icons";
import { FileCommandsIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { ITrendLine, TrendsUserEvents } from "../TrendsModel";


interface DeleteLineProps {
 line: ITrendLine;
}
const DeleteLine: FC<DeleteLineProps> = ({line}) => {
  const handleDeleteTag = () => {
    const event = new CustomEvent(
      TrendsUserEvents[TrendsUserEvents.DeleteTag],
      {
        detail: { lineId: line.id },
      }
    );
    document.dispatchEvent(event);
  };
  const CloseIcon =  getIcons(FileCommandsIcons.Close, IconCategory.FileCommands)
  return (
    <Button
      sx={{ minWidth: "20px" }}
      variant="text"
      size="small"
      endIcon={<CloseIcon />}
      onClick={handleDeleteTag}
    />
  );
};
export default DeleteLine;
