import { FC, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext } from "@mui/lab";
import { ITab } from "./TabsModels";
import classes from "./SgTabs.module.css";
import { TextEnum } from "../TextStyles";

interface ITabsProps {
  tabs: ITab[];
}
interface ISgTabsProps extends ITabsProps {
  setActiveTab: (key: string) => void;
  activeTab: string;
}

export const SimpleSgTabs: FC<ITabsProps> = ({ tabs }) => {
  const [value, setValue] = useState(tabs[0].key);
  return <SgTabs tabs={tabs} setActiveTab={setValue} activeTab={value} />;
}

const SgTabs: FC<ISgTabsProps> = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <div className={classes["container"]}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            aria-label="basic tabs example"
            TabIndicatorProps={{ className: classes["tab-indicator"] }}
            className={classes["tabs"]}
          >
            {tabs.map((tab) => (
              <Tab
                className={`${TextEnum.h4} ${classes["tab"]}`}
                sx={{
                  textTransform: "none",
                }}
                key={tab.key}
                label={tab.label}
                value={tab.key}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab) => (
          <TabPanel
            className={
              tab.isSingleItem ? classes["single-item"] : classes["tab-content"]
            }
            key={tab.key}
            value={tab.key}
          >
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default SgTabs;
