import { FC } from "react";

import classes from "./HeaderDataPoint.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import DataPointInfo from "../data_point/DataPointInfo";
import TagInvalidIcon from "../data_point/TagInvalidIcon";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";

interface FieldStateDataPointProps {
  tagName: string;
  siteId: string;
  titleOverride?: string;
  nameOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
  isBlinkingFunc?: (valueInString: string) => boolean;
}

const FieldStateDataPoint: FC<FieldStateDataPointProps> = ({
  tagName,
  siteId,
  titleOverride,
  nameOverride,
  valueToStringFuncOverride,
  isBlinkingFunc,
}) => {
  const { title, valueInString, valueExist } = DataPointInfo(
    tagName,
    siteId,
    titleOverride,
    valueToStringFuncOverride
  );

  let isBlinking = false;
  if (valueExist && isBlinkingFunc != null) {
    isBlinking = isBlinkingFunc(valueInString);
  }
  return (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      <li>
        <div className={classes["data-point-container"]}>
          <TagInvalidIcon tagName={tagName} siteId={siteId} />
          <span className={TextEnum.h4}>
            {title}{" "}
            <span
              className={
                isBlinking
                  ? `${classes["blinking-class"]} ${TextEnum.h3}`
                  : TextEnum.h3
              }
            >
              {!valueExist ? NO_VALUE_PLACEHOLDER : valueInString}
            </span>
          </span>
        </div>
      </li>
    </DataPointTooltip>
  );
};

export default FieldStateDataPoint;
