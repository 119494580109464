import { getCssVariable } from "../../cssVarUtils";

const LARGE_SCREEN_INDICATOR = 1025;

interface IChartStylesConfig {
  yTicksFontSize: number;
  xTicksFontSize: number;
  titleFontSize: number;
  fontFamily: string;
  legendBoxWidth: number;
  legendBoxHeight: number;
  legendFontSize: number;
  pointRadius: number;
  borderWidth: number;
  tooltipTitleFontSize: number;
  tooltipBodyFontSize: number;
  tooltipPadding: number;
}

const CHART_STYLE_CONFIG_PROTOTYPE = {
  fontFamily: getCssVariable("--font-family"),
  pointRadius: 0,
  legendBoxWidth: 5,
  legendBoxHeight: 5,
};

const LARGE_CHART_STYLE_CONFIG: IChartStylesConfig = {
  ...CHART_STYLE_CONFIG_PROTOTYPE,
  yTicksFontSize: 12,
  xTicksFontSize: 12,
  titleFontSize: 16,
  legendBoxWidth: 5,
  legendBoxHeight: 5,
  legendFontSize: 14,
  borderWidth: 3,
  tooltipTitleFontSize: 18,
  tooltipBodyFontSize: 14,
  tooltipPadding: 20
};

const SMALL_CHART_STYLE_CONFIG: IChartStylesConfig = {
  ...CHART_STYLE_CONFIG_PROTOTYPE,
  yTicksFontSize: 10,
  xTicksFontSize: 10,
  titleFontSize: 9,
  legendBoxWidth: 3,
  legendBoxHeight: 3,
  legendFontSize: 11,
  borderWidth: 1,
  tooltipTitleFontSize: 14,
  tooltipBodyFontSize: 12,
  tooltipPadding: 15
};

const CHART_STYLE_CONFIG: IChartStylesConfig =
  window.innerWidth >= LARGE_SCREEN_INDICATOR
    ? LARGE_CHART_STYLE_CONFIG
    : SMALL_CHART_STYLE_CONFIG;

export default CHART_STYLE_CONFIG;
