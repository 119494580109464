//error must be any or unknown
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import { IconButton } from "@mui/material";
import { IButtonVariant } from "../../../common/Mui/StyledButton";
import SolarGikButton from "../../../SolarGikLib/Button";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  FileCommandsIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { narrowOutlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";

interface DeletePlanProps {
  deleteApi: (siteId: string) => Promise<any>;
  setAlertMessage: (message?: AlertMessage) => void;
  siteId: string;
  postDeleteFunc: () => void;
  buttonVariant: IButtonVariant;
  buttonText: string;
  isDisabled: boolean;
  isTextLessButton?: boolean;
}
const DeletePlan: FC<DeletePlanProps> = ({
  setAlertMessage,
  siteId,
  postDeleteFunc,
  deleteApi,
  buttonText,
  isDisabled,
  isTextLessButton = false,
}) => {
  const handleDeletePlanClicked = async () => {
    setAlertMessage(undefined);
    try {
      const res = await deleteApi(siteId);
      if (res) {
        setAlertMessage({
          text: "Plan deleted successfully",
          severity: "success",
        });
        postDeleteFunc();
      }
    } catch (error: any) {
      setAlertMessage({
        text: error.message,
        severity: "error"
      });
    }
  };
  const DeleteIcon = getIcons(
    FileCommandsIcons.Delete,
    IconCategory.FileCommands
  );

  const DeleteDisabledIcon = getIcons(
    FileCommandsIcons.DeleteDisabled,
    IconCategory.FileCommands
  );

  if (isTextLessButton) {
    return (
      <DarkTooltip title="Delete plan">
        <IconButton
          onClick={handleDeletePlanClicked}
          disabled={isDisabled}
          size="small"
        >
          {isDisabled ? <DeleteDisabledIcon /> : <DeleteIcon />}
        </IconButton>
      </DarkTooltip>
    );
  }
  return (
    <SolarGikButton
      icon={<DeleteIcon />}
      text={buttonText}
      style={narrowOutlinedWhiteButton}
      onClickFunc={handleDeletePlanClicked}
      isDisabled={isDisabled}
    />
  );
};
export default DeletePlan;
