import { FC } from "react";

import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import classes from "./TimeQueryUI.module.css";
import { InputFieldType } from "./TrendsModel";
import getIcons from "../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import {
  HOUR_TO_MINUTE_FORMAT,
  YEAR_TO_MINUTE_FORMAT,
  formatDateTimeToDayMonthYearHourMinutes,
} from "../app/DateTimeUtils";

const { RangePicker } = DatePicker;

interface TimeQueryUIProps {
  initDateRangeValues: [Date, Date];
  setRangeTime: (rangeTime: [Date, Date]) => void;
  focusedField: InputFieldType;
}
const TimeQueryUI: FC<TimeQueryUIProps> = ({
  initDateRangeValues,
  setRangeTime,
  focusedField,
}) => {
  const isFocused = focusedField === InputFieldType.RangeTime;
  const cssClassName = isFocused
    ? `${TextEnum.h1} ${classes["option"]} ${classes["focused"]}`
    : `${TextEnum.h1} ${classes["option"]}`;

  const rangeTimeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates !== null && dates[0] !== null && dates[1] !== null) {
      const newDateRange: [Date, Date] = [
        new Date(dates[0].startOf("second").valueOf()),
        new Date(dates[1].startOf("second").valueOf()),
      ];
      setRangeTime(newDateRange);
    }
  };
  const startDate = formatDateTimeToDayMonthYearHourMinutes(
    initDateRangeValues[0]
  );
  const endDate = formatDateTimeToDayMonthYearHourMinutes(
    initDateRangeValues[1]
  );
  const CalenderIcon = getIcons(FieldIcons.Calender, IconCategory.Fields);
  return (
    <div className={classes["item-wrapper"]}>
      <RangePicker
        size={"small"}
        separator={
          <div className={classes["separator-wrapper"]}>
            <span className={classes["separator"]}>-</span>
          </div>
        }
        suffixIcon={<CalenderIcon />}
        placeholder={[startDate, endDate]}
        className={cssClassName}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getPopupContainer={(trigger: any /*External parameter*/) => trigger}
        showTime={{
          hideDisabledOptions: true,
          defaultValue: [
            dayjs("00:00", HOUR_TO_MINUTE_FORMAT),
            dayjs("00:00", HOUR_TO_MINUTE_FORMAT),
          ],
        }}
        format={YEAR_TO_MINUTE_FORMAT.replace("yyyy", "YYYY").replace(
          "dd",
          "DD"
        )}
        onChange={rangeTimeChange}
        inputReadOnly
      />
    </div>
  );
};
export default TimeQueryUI;
