import axios from "axios";

import {
  ITrackersCommandResponseReleaseTrackersFromMaintenance,
  ITrackersCommandResponseSendTrackersToMaintenance,
  ITrackerStatus,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  ITrackersCommandSendTrackersToMaintenanceResultCodeEnum,
  ITrackersCommandResultReleaseTrackersFromMaintenance,
  TrackerElevation,
  SendTrackersToFreezeResultCodeEnum,
  ITrackersCommandResponseSendTrackersToFreeze,
  ITrackersCommandSendTrackersToFreezeResultCodeEnum,
} from "./TrackersModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getAllLatestTrackersAsync = async (
  siteId: string,
): Promise<ITrackerStatus[]> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetTrackersData;
  const params = { siteId: siteId };
  const httpResponse = await axios.request<ITrackerStatus[]>({
    url: url,
    params: params,
  });
  return httpResponse.data;
};

export const sendTrackersToMaintenance = async (
  siteId: string,
  trackersIds: number[],
  targetElevation: TrackerElevation,
  reason: string
): Promise<ITrackersCommandSendTrackersToMaintenanceResultCodeEnum> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiSendTrackersToMaintenance(siteId);
  const params = {
    TargetElevationDegrees:
      targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
    Reason: reason,
  };
  try {
    const httpResponse =
      await axios.post<ITrackersCommandResponseSendTrackersToMaintenance>(
        url,
        params
      );
    return httpResponse.data.Results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        SendTrackersToMaintenanceResultCodeEnum.UnexpectedError,
      ])
    );
  }
};
export const releaseTrackersFromMaintenance = async (
  siteId: string,
  trackersIds: number[]
): Promise<ITrackersCommandResultReleaseTrackersFromMaintenance> => {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiReleaseTrackersFromMaintenance(siteId);
  const params = { TrackersList: trackersIds };
  try {
    const httpResponse =
      await axios.post<ITrackersCommandResponseReleaseTrackersFromMaintenance>(
        url,
        params
      );
    return httpResponse.data.Results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        ReleaseTrackersFromMaintenanceResultCodeEnum.UnexpectedError,
      ])
    );
  }
};
export const sendTrackersToFreeze = async (
  siteId: string,
  trackersIds: number[],
  reason: string
):Promise<ITrackersCommandSendTrackersToFreezeResultCodeEnum>=> {
  const url =
    APP_CONFIG.serviceUrls.siteGatewayUrlPrefix +
    APP_CONFIG.apiSendTrackersToFreeze(siteId);
  const params = { TrackersList: trackersIds, Reason: reason };
  try {
    const httpResponse = await axios.post<ITrackersCommandResponseSendTrackersToFreeze>(url, params);
    return httpResponse.data.Results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        SendTrackersToFreezeResultCodeEnum.UnexpectedError,
      ])
    );
  }
};
