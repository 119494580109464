/* eslint-disable @typescript-eslint/no-explicit-any */
export function createAndDownloadBlobFile(
  body: any,
  name: string,
  header: string,
  suffix: string
) {
  const blob = new Blob([body], { type: header });
  const fileName = `${name}${suffix}`;
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = window.URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
