import { FC } from "react";

import { shallowEqual, useSelector } from "react-redux";

import classes from "./Charts.module.css";
import { RootState } from "../app/Store";
import CardSiteStyledLineChartWrapper from "../charts_ui/CardSiteStyledLineChartWrapper";
import {
  CHART_DEFAULT_HEIGHT,
  CHART_HEIGHT_WITHOUT_LEGEND,
  CURRENT_PRODUCTION_TITLE,
  PERFORMANCE_TITLE,
  POWER_GENERATION_TITLE,
  SHADING_TITLE,
  TRACKER_ELEVATION_TITLE,
  WEATHER_TITLE,
  createCurrentProductionChartConfig,
  createDailyProductionChartConfig,
  createPerformanceChartConfig,
  createShadingPercentChartConfig,
  createTrackersChartConfig,
  createWeatherChartConfig,
} from "../common_chart_config/ChartConfig";
import { selectSiteId } from "../sites/SiteStore";

interface IHistoryChartsSectionProps {
  requestedRangeTime: {
    start: Date;
    end: Date;
  };
  samplingInterval: string;
}

const HistoryChartsSection: FC<IHistoryChartsSectionProps> = ({
  requestedRangeTime,
  samplingInterval,
}) => {
  const siteId = useSelector(selectSiteId);
  const isAgriPageVisible = useSelector(
    (state: RootState) => state.multiSitesVisualInfo[siteId].isAgriPageVisible
  );
  const inverterIds = useSelector((state: RootState) => state.site.inverterIds);
  const zoneOffsetTimeInMinutes = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes,
    shallowEqual
  );
  const trackerIds = useSelector((state: RootState) => state.site.trackerIds);
  const trackerChartConfig = createTrackersChartConfig(
    trackerIds,
    zoneOffsetTimeInMinutes,
    siteId
  );
  const currentProductionChartConfig = createCurrentProductionChartConfig(
    inverterIds,
    zoneOffsetTimeInMinutes,
    siteId
  );
  const performanceChartConfig = createPerformanceChartConfig(
    zoneOffsetTimeInMinutes,
    siteId
  );
  const shadingChartConfig = createShadingPercentChartConfig(
    trackerIds,
    zoneOffsetTimeInMinutes,
    siteId
  );
  const weatherChartConfig = createWeatherChartConfig(
    zoneOffsetTimeInMinutes,
    siteId
  );
  const dailyProductionChartConfig = createDailyProductionChartConfig(
    zoneOffsetTimeInMinutes,
    siteId
  );

  return (
    <div className={classes["grid-container"]}>
      <div className={`${classes["item3"]}`}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={currentProductionChartConfig.siteId}
          title={POWER_GENERATION_TITLE}
          tagInfo={currentProductionChartConfig.tags}
          samplingInterval={samplingInterval}
          rangeTimeInZoneTime={requestedRangeTime}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={false}
          isExportable={true}
        />
      </div>
      <div className={`${classes["item2"]}`}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={weatherChartConfig.siteId}
          title={WEATHER_TITLE}
          tagInfo={weatherChartConfig.tags}
          samplingInterval={samplingInterval}
          rangeTimeInZoneTime={requestedRangeTime}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={false}
          isExportable={true}
        />
      </div>
      <div className={`${classes["item1"]}`}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={dailyProductionChartConfig.siteId}
          title={CURRENT_PRODUCTION_TITLE}
          tagInfo={dailyProductionChartConfig.tags}
          samplingInterval={samplingInterval}
          rangeTimeInZoneTime={requestedRangeTime}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={false}
          isExportable={true}
        />
      </div>
      <div className={`${classes["item4"]}`}>
        {!isAgriPageVisible ? (
          <CardSiteStyledLineChartWrapper
            showChartTitle={false}
            siteId={performanceChartConfig.siteId}
            title={PERFORMANCE_TITLE}
            tagInfo={performanceChartConfig.tags}
            samplingInterval={samplingInterval}
            rangeTimeInZoneTime={requestedRangeTime}
            chartHeight={CHART_DEFAULT_HEIGHT}
            isRepetitiveRequest={false}
            isExportable={true}
          />
        ) : (
          <CardSiteStyledLineChartWrapper
            showChartTitle={false}
            siteId={shadingChartConfig.siteId}
            title={SHADING_TITLE}
            tagInfo={shadingChartConfig.tags}
            samplingInterval={samplingInterval}
            rangeTimeInZoneTime={requestedRangeTime}
            chartHeight={CHART_DEFAULT_HEIGHT}
            isRepetitiveRequest={false}
            isExportable={true}
          />
        )}
      </div>
      <div className={`${classes["item5"]}`}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={trackerChartConfig.siteId}
          title={TRACKER_ELEVATION_TITLE}
          tagInfo={trackerChartConfig.tags}
          samplingInterval={samplingInterval}
          rangeTimeInZoneTime={requestedRangeTime}
          chartHeight={CHART_HEIGHT_WITHOUT_LEGEND}
          isRepetitiveRequest={false}
          isExportable={true}
          overrideShowLegend={true}
        />
      </div>
      {/* <div className={`${classes["item6"]}`}>
        <AuxiliariesIssueCountChartWrapper
          siteId={siteId}
          title={AUXILIARIES_ISSUES_TITLE}
          samplingInterval={samplingInterval}
          rangeTimeInZoneTime={requestedRangeTime}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
          isExportable={true}
        />
      </div> */}
    </div>
  );
};
export default HistoryChartsSection;
