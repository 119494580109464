import { FC } from "react";

import classes from "./HeaderDataPoints.module.css";
import HeaderDataPoint from "../../banner/HeaderDataPoint";
import TagsNames from "../../data_point/TagsNames";
interface IHeaderDataPointsProps {
  siteId: string;
}
const HeaderDataPoints: FC<IHeaderDataPointsProps> = ({ siteId }) => {
  return (
      <ul className={classes["data-points-group-list"]}>
        <HeaderDataPoint
          tagName={TagsNames.DAILY_SP_EFFICIENCY}
          titleOverride="Perf Index"
          nameOverride="Performance Index"
          siteId={siteId}
        />
        <HeaderDataPoint
          tagName={TagsNames.SITE_AC_CURRENT_POWER}
          titleOverride="AC Power"
          siteId={siteId}
        />
        <HeaderDataPoint
          tagName={TagsNames.WIND}
          siteId={siteId}
          titleOverride="Wind"
        />       
      </ul>
  );
};
export default HeaderDataPoints;
