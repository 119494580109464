import { useCallback, useRef } from "react";

import { FileError, FileRejection, useDropzone } from "react-dropzone";

import APP_CONFIG from "../app/configuration/AppConfig";

export const useDropZone = (
  functionOnloaded: () => void,
  communicationHeader: string,
  fileTypeSuffix: string,
  isDisabled?: boolean
) => {
  const loadedFileName = useRef<string>();
  const fileContent = useRef<ArrayBuffer>();
  const error = useRef<FileError>();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed do to ");
      reader.onload = async () => {
        const text = await new Response(reader.result).arrayBuffer();
        loadedFileName.current = file.name;
        fileContent.current = text;
        functionOnloaded();
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file: FileRejection) => {
      error.current = file.errors[0];
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: APP_CONFIG.configFileMaxSize,
    accept: {
      [communicationHeader]: [fileTypeSuffix],
    },
    disabled: isDisabled,
  });
  return {
    getRootProps,
    getInputProps,
    loadedFileName,
    fileContent,
    error,
  };
};
