import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Issues.module.css";
import { RootState } from "../../app/Store";
import { getComponentTypes } from "./IssuesCommon";
import IssuesSection from "./IssuesSection";
import { ComponentTypeEnum } from "../faultsModels";
import { IssuesLoadingStatus } from "./IssuesModels";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { selectSiteId } from "../../sites/SiteStore";
import { selectCurrentSiteActiveIssuesByComponentType } from "../../app/store/IssuesStore";

const Issues = () => {
  const siteId = useSelector(selectSiteId);
  const loadingState = useSelector(
    (state: RootState) => state.multiSitesIssues.loadingState[siteId]
  );

  const issues = useSelector(selectCurrentSiteActiveIssuesByComponentType);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  useEffect(() => {
    if (loadingState === IssuesLoadingStatus.Error) {
      setAlertMessage({
        text: "Failed loading issues",
        severity: "error",
      });
    } else {
      setAlertMessage(undefined);
    }
  }, [loadingState]);


  //values of ComponentTypeEnum without 0 cause 0 is undefined
  const componentTypesEnum: number[] = getComponentTypes();

  const sortedComponentTypes = componentTypesEnum.sort((a, b) => {
    const hasIssuesA =
      issues &&
      issues[ComponentTypeEnum[a]] &&
      issues[ComponentTypeEnum[a]].length > 0;
    const hasIssuesB =
      issues &&
      issues[ComponentTypeEnum[b]] &&
      issues[ComponentTypeEnum[b]].length > 0;

    if (hasIssuesA && !hasIssuesB) {
      return -1;
    } else if (!hasIssuesA && hasIssuesB) {
      return 1;
    }

    return 0;
  });
  return (
    <div className={classes.overflow}>
      <div className={classes.content}>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
        {loadingState !== IssuesLoadingStatus.Error &&
          sortedComponentTypes.map((componentType: number) => (
            <IssuesSection
              isLoading={loadingState == IssuesLoadingStatus.Pending}
              defaultExpandedKey={sortedComponentTypes[0]}
              key={componentType}
              componentTypeEnum={componentType}
              issuesList={
                (issues && issues[ComponentTypeEnum[componentType]]) || []
              }
            />
          ))}
      </div>
    </div>
  );
};

export default Issues;
