const white = "var(--white)";
const primaryColor = "var(--blue-color-primary)";
const disabledColor = "var(disabled)";

export const btnStyling = {
  svg: { color: primaryColor },
  input: { color: primaryColor },
  label: { color: primaryColor },
  color: { color: primaryColor },
  disabled: { color: disabledColor },
  borderColor: "rgba(228, 219, 233, 0.25)",
  width: "fit-content",
  button: {
    color: primaryColor,
    borderColor: "rgba(228, 219, 233, 0.25)",
  },
  "&:hover .MuiInputBase-root-MuiOutlinedInput-root": {
    borderColor: primaryColor,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },
  "&.Mui-hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(228, 219, 233, 0.25)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },
  "&:hover": {
    borderColor: primaryColor,
  },
  "MuiInputBase-root-MuiOutlinedInput-root": {
    borderColor: primaryColor,
  },
  "MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    color: primaryColor,
  },
};
export const textInputStyle = {
  input: { color: primaryColor },
  label: { color: primaryColor },
  color: { color: primaryColor },
  textField: {
    "& .MuiInput-underline:before": {
      borderColor: primaryColor,
      color: primaryColor,
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid",
      borderColor: primaryColor,
    },
    "&:hover:before .MuiInput-underline": {
      borderBottom: "2px solid",
      borderColor: primaryColor,
    },
    "& .MuiInputBase-input-MuiFilledInput-input": {
      color: { color: primaryColor },
    },
  },
  "& .MuiFormLabel-root-MuiInputLabel-root": {
    color: { color: primaryColor },
  },
  "& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .MuiInputBase-formControl":
    {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  "& .MuiInputLabel-root.Mui-focused": {
    color: { color: primaryColor },
  },
  "& .MuiFormControl-root-MuiTextField-root label": {
    color: { color: primaryColor },
  },
  height: 20,
};
export const trendInputFieldStyling = {
  "& .MuiInputBase-root": {
    width: "100%",
    borderRadius: "40px",
    borderWidth: "0.96px",
    borderColor: primaryColor,
    height: "41px",
  },
  "& .MuiAutocomplete-inputRoot": {
    alignItems: "center",
    padding: "0 !important",
    paddingLeft: "0.4rem !important",
    borderColor: primaryColor,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--input-adornment-focused-color)",
  },
  "& .MuiAutocomplete-input": {
    padding: "7.5px 4px 4.5px 8px !important",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px `,
    },
  },
  "& .MuiInputBase-input": {
    maxWidth: "70%",
    fontSize: "14px",
    color: primaryColor,
  },
  "@media screen and (max-width: 1300px)": {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: "12px",
    },
  },
};
export const dialogStyling = {
  "& .MuiDialog-paper": {
    color: primaryColor,
    backgroundColor: white,
  },
};
const drawerWidth = "var(--drawer-width)";
export const drawerStyling = {
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    marginTop: "75px",
    backgroundColor: "transparent",
    color: "var(--blue-color-primary)",
    width: drawerWidth,
    boxSizing: "border-box",
    overflowX: "hidden",
    border: "none",
    boxShadow: "none",
  },
  "@media screen and (max-width: 1300px)": {
    "& .MuiDrawer-paper": {
      marginTop: "60px",
    },
  },
};
