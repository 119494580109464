export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function valueToFixedNumber(value: number, fixed: number) {
  return value.toFixed(fixed);
}

export function convertNumberToTimeSpan(milliseconds : number) {
  let seconds = Math.floor(milliseconds / 1000);

  const days = Math.floor(seconds / (3600 * 24)); 
  seconds = seconds % (3600 * 24); 

  const hours = Math.floor(seconds / 3600); 
  seconds = seconds % 3600; 

  const minutes = Math.floor(seconds / 60); 
  seconds = seconds % 60; 

  // Format the time as "dd.hh:mm:ss"
  const formattedTime =
  padZero(days) + "." + padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);

  return formattedTime;
}

function padZero(num : number) {
  return num.toString().padStart(2, "0");
}