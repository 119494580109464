export enum SeverityEnum {
  Undefined = 0,
  Low = 10,
  Medium = 20,
  High = 30,
}

export enum ComponentTypeEnum {
  Undefined = 0,
  Inverters = 1,
  Trackers = 2,
  System = 3,
  Auxiliaries = 4,
}
