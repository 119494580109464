import classes from "./Sprinklers.module.css";
import SprinklersCommands from "../features/sprinklers/SprinklersCommands";
import Card from "../SolarGikLib/cards/Card";

const Sprinklers = () => {
  return (
    <Card>
      <div className={classes.wrapper}>
        <h1>Sprinklers</h1>
        <SprinklersCommands />
      </div>
    </Card>
  );
};
export default Sprinklers;
