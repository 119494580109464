import { LogLevel } from "@azure/msal-browser";

import APP_CONFIG from "../app/configuration/AppConfig";

export const msalConfig = {
  auth: {
    clientId: APP_CONFIG.authConfig.clientId,
    authority: APP_CONFIG.authConfig.signInAuth,
    knownAuthorities: [APP_CONFIG.authConfig.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          /*case LogLevel.Info:
            console.info(message);
            return; removed to reduce amount of log messages*/
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  webAPI: {
    scopes: [APP_CONFIG.authConfig.webApiScope],
  },
};

export const loginRequest = {
  scopes: [...protectedResources.webAPI.scopes],
};
