import axios, { AxiosResponse } from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";

export const uploadNewSprinklersPlanAsync = async (
  siteId: string,
  fileData: Uint8Array
): Promise<AxiosResponse> => {
  const url = `${
    APP_CONFIG.serviceUrls.maintenanceServiceUrlPrefix + APP_CONFIG.apiUploadSprinklersPlan
  }/${siteId}`;
  const params = {
    file: fileData,
  };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await axios.post<AxiosResponse>(url, params, headers);
  return res;
};

export const deleteSprinklersPlanAsync = async (
  siteId: string
): Promise<boolean> => {
  const url = `${
    APP_CONFIG.serviceUrls.maintenanceServiceUrlPrefix + APP_CONFIG.apiDeleteSprinklersPlan
  }/${siteId}`;
  const res = await axios.delete<void>(url);
  return res.status == 204;
};

export const getPlanAsFileAsync = async (siteId: string) => {
  const url = `${
    APP_CONFIG.serviceUrls.maintenanceServiceUrlPrefix +
    APP_CONFIG.apiDownloadSprinklersPlan
  }/${siteId}`;
  const res = await axios.get<string>(url);
  return res.data;
};

export const getSprinklersPlanTemplateAsync = async (
  templateVersionNumber: string
) => {
  const url = `${
    APP_CONFIG.serviceUrls.maintenanceServiceUrlPrefix +
    APP_CONFIG.apiGetSprinklersPlanTemplateFile
  }/${templateVersionNumber}`;
  const res = await axios.get<string>(url);
  return res.data;
};

export const getIsPlanExist = async (siteId: string) => {
  const url = `${
    APP_CONFIG.serviceUrls.maintenanceServiceUrlPrefix + APP_CONFIG.apiGetIsPlanExist
  }?siteId=${siteId}`;
  const res = await axios.get<void>(url);
  return res.status == 200;
};
