import * as React from "react";
import { useEffect } from "react";

import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import PopoverHeader from "./PopoverHeader";
import { PopoverPlacementHorizontal as PopOverPlacementHorizontal } from "./PopoverModels";
import classes from "./SgPopover.module.css";
import { popoverStyle } from "./SgPopoverStyle";
interface PopoverPopupStateProps {
  TriggerButton: React.ReactElement;
  children?: React.ReactNode;
  title: string;
  placementHorizontal?: PopOverPlacementHorizontal;
  open: boolean;
  onClose: () => void;
}

const SgPopover: React.FC<PopoverPopupStateProps> = ({
  TriggerButton,
  children,
  title,
  placementHorizontal = "center",
  open,
  onClose,
}) => {
  return (
    <PopupState variant="popover" popupId="generic-popup-popover">
      {(popupState) => {
        useEffect(() => {
          if (open && !popupState.isOpen) {
            popupState.open();
          } else if (!open && popupState.isOpen) {
            popupState.close();
          }
        }, [open, popupState]);
        const handleOnClose = () => {
          if (popupState.isOpen) {
            popupState.close();
          }
          if (onClose) {
            onClose();
          }
        };
        return (
          <div>
            {React.cloneElement(TriggerButton, bindTrigger(popupState))}
            <Popover
              {...bindPopover(popupState)}
              open={popupState.isOpen}
              onClose={handleOnClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: placementHorizontal,
              }}
              sx={popoverStyle}
            >
              <PopoverHeader title={title} />
              <div className={classes[`children-container}`]}>{children}</div>
            </Popover>
          </div>
        );
      }}
    </PopupState>
  );
};

export default SgPopover;
