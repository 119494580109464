import {
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  SendTrackersToFreezeResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackersCommandType,
} from "../TrackersModels";

const sendTrackersToMaintenanceResultCodeEnumToString = {
  [SendTrackersToMaintenanceResultCodeEnum.Unknown]: "Unknown",
  [SendTrackersToMaintenanceResultCodeEnum.Success]: "Success",
  [SendTrackersToMaintenanceResultCodeEnum.NoSuchTracker]: "No Such Tracker",
  [SendTrackersToMaintenanceResultCodeEnum.UnexpectedError]: "Unexpected Error",
  [SendTrackersToMaintenanceResultCodeEnum.TrackerCurrentlyNotConnectedButAddedToMaintenanceList]:
    "Tracker Currently Not Connected But Added To Maintenance List",
  [SendTrackersToMaintenanceResultCodeEnum.TrackerInSafeButAddedToMaintenanceList]:
    "Tracker In Safe But Added To Maintenance List",
};
const releaseTrackersFromMaintenanceResultCodeEnumToString = {
  [ReleaseTrackersFromMaintenanceResultCodeEnum.Unknown]: "Unknown",
  [ReleaseTrackersFromMaintenanceResultCodeEnum.Success]: "Success",
  [ReleaseTrackersFromMaintenanceResultCodeEnum.NoSuchTracker]:
    "No Such Tracker",
  [ReleaseTrackersFromMaintenanceResultCodeEnum.UnexpectedError]:
    "Unexpected Error",
  [ReleaseTrackersFromMaintenanceResultCodeEnum.TrackerCurrentlyNotConnectedButReleasedFromToMaintenanceList]:
    "Tracker Currently NotConnected But Released From To Maintenance List",
  [ReleaseTrackersFromMaintenanceResultCodeEnum.TrackerWasNotInMaintenance]:
    "Tracker Was Not In Maintenance",
};
const sendTrackersToFreezeResultCodeEnumToString = {
  [SendTrackersToFreezeResultCodeEnum.Unknown]: "Unknown",
  [SendTrackersToFreezeResultCodeEnum.Success]: "Success",
  [SendTrackersToFreezeResultCodeEnum.NoSuchTracker]: "No Such Tracker",
  [SendTrackersToFreezeResultCodeEnum.UnexpectedError]: "Unexpected Error",
  [SendTrackersToFreezeResultCodeEnum.TrackerCurrentlyNotConnectedCouldNotFreeze]:
    "Tracker Currently Not Connected Could Not Freeze",
}
export const convertEnumResultCodeToStringReleaseTrackersFromMaintenance = (
  resultCode: ReleaseTrackersFromMaintenanceResultCodeEnum
): string => {
  const res = releaseTrackersFromMaintenanceResultCodeEnumToString[resultCode];
  return res ?? "Unknown Result Code";
};
export const convertEnumResultCodeToStringSendTrackersToMaintenance = (
  resultCode: SendTrackersToMaintenanceResultCodeEnum
): string => {
  const res = sendTrackersToMaintenanceResultCodeEnumToString[resultCode];
  return res ?? "Unknown Result Code";
};
export const convertEnumResultCodeToStringSendTrackersToFreeze = (
  resultCode: SendTrackersToFreezeResultCodeEnum
): string => {
  const res = sendTrackersToFreezeResultCodeEnumToString[resultCode];
  return res ?? "Unknown Result Code";
};
export const convertEnumResultCodeToString = (
  resultCode:
    | ReleaseTrackersFromMaintenanceResultCodeEnum
    | SendTrackersToMaintenanceResultCodeEnum
    | SendTrackersToFreezeResultCodeEnum,
  command: TrackersCommandType
): string => {
  switch (command) {
    case TrackersCommandType.ReleaseTrackersFromMaintenance:
      return convertEnumResultCodeToStringReleaseTrackersFromMaintenance(
        resultCode as ReleaseTrackersFromMaintenanceResultCodeEnum
      );
    case TrackersCommandType.SendTrackersToMaintenance:
      return convertEnumResultCodeToStringSendTrackersToMaintenance(
        resultCode as SendTrackersToMaintenanceResultCodeEnum
      );
       case TrackersCommandType.SendTrackersToFreeze:
      return convertEnumResultCodeToStringSendTrackersToFreeze(
        resultCode as SendTrackersToFreezeResultCodeEnum
      );
    default:
      return "Unknown Result Code";
  }
};
