import { GridColumnGroupHeaderParams } from "@mui/x-data-grid";

export const HeaderGroupItem: React.FC<GridColumnGroupHeaderParams> = (
  headerParams
) => {
  return (
    <div>
      <span>{headerParams.headerName}</span>
    </div>
  );
};
