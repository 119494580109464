import {
  EndSessionRequest,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { useDispatch } from "react-redux";
import { userSlice } from "../../features/user/UserStore";

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    postLogoutRedirectUri: "/",
    mainWindowRedirectUri: "/",
  } as EndSessionRequest);
  sessionStorage.clear();
};

export const useLogout = () => {
  const dispatch = useDispatch();

  const Logout = (instance: IPublicClientApplication) => {
    dispatch(userSlice.actions.reset());
    handleLogout(instance);
  };

  return Logout;
};
