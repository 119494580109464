export interface ConfigNameAndDescription {
  name: string;
  description: string;
}

export interface AppVersionViewModel {
  dtoVersion: string;
  uiVersion: string;
  description?: string;
  isActive?: boolean;
  dateStr: string;
}

export enum SiteAppType {
  Mcs = "Mcs",
  Das = "Das",
}
