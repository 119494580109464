import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IChartDataStateValues,
  IFetchTagsParams,
  ISiteToChartDataState,
} from "../../data_point/charts/ChartModel";
import { validateResponse } from "../../data_point/TagValidationUtils";
import { getTagsHistoryAsync } from "../TagsAPI";

const defaultTagsChartState: ISiteToChartDataState = {};

export const multiSitesTagsChartSlice = createSlice({
  name: "multiSitesTagsChart",
  initialState: defaultTagsChartState,
  reducers: {},
  //for binding the middleware action to the reducer
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      const { siteId, rangeTime, data } = action.payload;
      const temp: IChartDataStateValues = {
        tagToValues: {},
        lastCallTime: new Date(),
        rangeTime: rangeTime,
      };
      Object.entries(data).forEach(([key, valuePair]) => {
        temp.tagToValues[key] = [...valuePair];
        temp.lastCallTime = new Date();
        temp.rangeTime = rangeTime;
      });
      if (!state[siteId]) {
        state[siteId] = {}; // Initialize the site if it doesn't exist
      }
      state[siteId][action.payload.key] = temp;
    });
  },
});

export const fetchTags = createAsyncThunk(
  "multiSitesTagsChart/fetch",
  async ({ interval, paramsForGraphDisplayRequest, key }: IFetchTagsParams) => {
    const apiResponse = await getTagsHistoryAsync(paramsForGraphDisplayRequest);
    validateResponse(apiResponse, paramsForGraphDisplayRequest, interval);
    return {
      data: apiResponse,
      rangeTime: {
        start: paramsForGraphDisplayRequest.fromDateInZoneTime,
        end: paramsForGraphDisplayRequest.toDateInZoneTime,
      },
      key: key,
      siteId: paramsForGraphDisplayRequest.siteId,
    };
  }
);

export const multiSitesTagsChartReducer = multiSitesTagsChartSlice.reducer;
