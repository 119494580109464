import axios from "axios";

import {
  IIdToMetadataDictionary,
  IIssueInfo,
  ISitesToIssueInfos,
  IssuesHistoryParams,
} from "./IssuesModels";
import APP_CONFIG from "../../app/configuration/AppConfig";

interface IIssuesInfoDto {
  DeviceId: number;
  StartTime: number;
  EndTime?: number;
}

interface IIssuesMetadataDictionaryDto {
  Id: number;
  Severity: number;
  Name: string;
  Description: string;
  ScreenTimeMinutes: number;
  FaultGroupMetadataId: number;
  FaultGroupMetadata: {
    Id: number;
    ComponentType: number;
    FaultType: number;
    IsIssue: boolean;
    HasNotification: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Faults: any; // Adjust the type based on your actual data
}
interface IMetaDataIdToIssuesDictionaryDto {
  [key: number]: IIssuesInfoDto[];
}

interface ISiteToIMetadataIdToIssuesDictionaryDto {
  [siteId: string]: IMetaDataIdToIssuesDictionaryDto;
}

export const getActiveIssuesAsync = async (
  siteId: string
): Promise<IIssueInfo[]> => {
  const url = `
    ${APP_CONFIG.serviceUrls.faultsServiceUrlPrefix}${APP_CONFIG.apiGetActiveIssues}?SiteId=${siteId}`;
  const httpResponse = await axios.get<IMetaDataIdToIssuesDictionaryDto>(url);
  if (httpResponse.status != 200) {
    console.error("received non 200 response from getActiveIssuesAsync");
  }
  return convertIssueTimesTypeToDate(httpResponse.data);
};

export const tryGetSitesActiveIssues = async (
  sitesIds: string[]
): Promise<ISitesToIssueInfos | null> => {
  const url =
    APP_CONFIG.serviceUrls.faultsServiceUrlPrefix +
    APP_CONFIG.apiGetSitesActiveIssues;
  try {
    const httpResponse =
      await axios.post<ISiteToIMetadataIdToIssuesDictionaryDto>(url, sitesIds);
    return Object.entries(httpResponse.data).reduce(
      (accumulator, [siteId, issuesMap]) => {
        accumulator[siteId] = convertIssueTimesTypeToDate(issuesMap);
        return accumulator;
      },
      {} as ISitesToIssueInfos
    );
  } catch (error) {
    console.error("Failed to get sites active issues", error);
    return null;
  }
};

export const getAllSitesIssuesMetadataAsync = async (): Promise<
  IIdToMetadataDictionary[]
> => {
  const url =
    APP_CONFIG.serviceUrls.faultsServiceUrlPrefix +
    APP_CONFIG.apiGetIssuesMetadata;
  const httpResponse = await axios.get<IIssuesMetadataDictionaryDto>(url);

  if (Object.entries(httpResponse.data).length == 0) {
    console.error("received empty object from getAllSitesIssuesMetadataAsync");
  }
  return convertIssuesMetadataDtoToIssuesMetadata(httpResponse.data);
};

function convertIssuesMetadataDtoToIssuesMetadata(
  issuesMetadataDto: IIssuesMetadataDictionaryDto
): IIdToMetadataDictionary[] {
  const res: IIdToMetadataDictionary[] = [];
  Object.values(issuesMetadataDto).map(
    (issueMetadata: IIssuesMetadataDictionaryDto) => {
      const id = issueMetadata.Id;
      const metadata = {
        severity: issueMetadata.Severity,
        componentType: issueMetadata.FaultGroupMetadata.ComponentType,
        issueType: issueMetadata.Name,
        description: issueMetadata.Description,
        screenTimeMinutes: issueMetadata.ScreenTimeMinutes,
      };
      res.push({ [id]: metadata });
    }
  );
  return res;
}

function convertIssueTimesTypeToDate(
  issues: IMetaDataIdToIssuesDictionaryDto
): IIssueInfo[] {
  const res: IIssueInfo[] = [];

  Object.entries(issues).forEach(([metadataId, issuesDic]) => {
    const convertedIssues = issuesDic.map((issue: IIssuesInfoDto) => ({
      metadataId: Number(metadataId),
      deviceId: issue.DeviceId,
      startTime: new Date(issue.StartTime),
      endTime: issue.EndTime ? new Date(issue.EndTime) : undefined,
    }));

    res.push(...convertedIssues);
  });

  return res;
}

export const getSiteIssuesHistoryAsync = async (
  issuesHistoryParams: IssuesHistoryParams
) => {
  const url = `${APP_CONFIG.serviceUrls.faultsServiceUrlPrefix}${APP_CONFIG.apiGetHistoryIssues}`;
  const params = {
    siteId: issuesHistoryParams.siteId,
    from: issuesHistoryParams.startTime.toISOString(),
    to: issuesHistoryParams.endTime.toISOString(),
  };
  const res = await axios.get(url, { params });
  return res.data;
};
