import axios from "axios";

import { IUserData } from "./UserStore";
import APP_CONFIG from "../app/configuration/AppConfig";
import { ISiteIdToDisplayName } from "../data_point/models/TagsModels";

export const getUserData = async (signal: AbortSignal): Promise<IUserData> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetUserInfo;
  const httpResponse = await axios.get<IUserData>(url, { signal });
  return httpResponse.data;
};

export const getSitesDisplayNames = async (siteIds: string[], signal: AbortSignal): Promise<ISiteIdToDisplayName> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSitesDisplayNameList;
  const httpResponse = await axios.post<string[]>(url, siteIds, { signal });
  const siteUniqueDisplayNamesList = httpResponse.data;
  const siteIdNameDic: ISiteIdToDisplayName = {};
  siteIds.forEach((id: string, index: number) => {
    siteIdNameDic[id] = siteUniqueDisplayNamesList[index];
  });
  return siteIdNameDic;
};
