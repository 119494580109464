import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addMilliseconds } from "date-fns";
import {
  getIsWeatherOverrideActive,
  getWeatherOverrideRemainingTime,
} from "../../sites_configuration/safeOverride/SafeOverrideApi";

interface ISafeOverrideState {
  inOverrideSafeMode: boolean;
  expiredAt: Date;
}

const initialState: ISafeOverrideState = {
  inOverrideSafeMode: false,
  expiredAt: new Date(),
};

const safeOverrideSlice = createSlice({
  name: "safeOverride",
  initialState,
  reducers: {
    updateSafeOverrideState: (
      state,
      action: PayloadAction<ISafeOverrideState>
    ) => {
      state.inOverrideSafeMode = action.payload.inOverrideSafeMode;
      state.expiredAt = action.payload.expiredAt;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSafeOverride.fulfilled, (state, action) => {
      state.inOverrideSafeMode = action.payload.inOverrideSafeMode;
      if (action.payload.remainingTime != null) {
        state.expiredAt = addMilliseconds(
          Date.now(),
          action.payload.remainingTime
        );
      }
    });
  },
});

export const fetchSafeOverride = createAsyncThunk(
  "safeOverride/fetch",
  async (siteId: string) => {
    const isOverrideActive = await getIsWeatherOverrideActive(siteId);
    return {
      inOverrideSafeMode: isOverrideActive,
      ...(isOverrideActive && {
        remainingTime: await getWeatherOverrideRemainingTime(siteId),
      }),
    };
  }
);

export const { updateSafeOverrideState } = safeOverrideSlice.actions;
export const safeOverrideReducer = safeOverrideSlice.reducer;
