import { FC } from "react";

import classes from "./InvalidIcon.module.css";
import getIcons from "../../SolarGikLib/icons/Icons";
import { ErrorIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
interface InvalidIconProps{
    showInvalid: boolean
}
const InvalidIcon:FC<InvalidIconProps> = ({showInvalid}) =>{
   const SgInvalidIcon = getIcons(ErrorIcons.DataPointError, IconCategory.Error)
   
   ;
    return <span className={classes[`${showInvalid? "show": "hide"}`]} >  <SgInvalidIcon/> </span>;
}
export default InvalidIcon;