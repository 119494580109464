import { FC, useEffect } from "react";

import { Alert, Collapse } from "@mui/material";

import { AlertMessage } from "./AlertModels";
import { AlertStyle } from "./AlertStyle";
import { ReactComponent as ErrorIcon } from "../icons/AlertIcons/Error.svg";
import { ReactComponent as SuccessIcon } from "../icons/AlertIcons/Success.svg";
import { TextEnum } from "../TextStyles";

interface SolarGikAlertProps {
  message?: AlertMessage;
  setMessage: (message?: AlertMessage) => void;
  isCloseIconVisible?: boolean;
}

const SolarGikAlert: FC<SolarGikAlertProps> = ({
  message, setMessage, isCloseIconVisible = true,
}) => {
  useEffect(() => {
    if (!isCloseIconVisible) {
      const timeoutId = setTimeout(() => setMessage(undefined), 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [message, isCloseIconVisible]);

  return (
    <Collapse in={message && message.text.length > 0}>
    <Alert
        severity={message?.severity}
        onClose={() => setMessage(undefined)}
        iconMapping={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <ErrorIcon />,
        }}
        className={TextEnum.h5}
        sx={AlertStyle}
        //the action icon is visible when there is an override icon or nullish. false hides the action icon:
        action={isCloseIconVisible ? undefined : false}
      >
        {message?.text}
      </Alert>
    </Collapse>
  );
};

export default SolarGikAlert;
