import axios, { AxiosResponse } from "axios";

import { IAgriDailyPlanDto } from "./AgriModels";
import APP_CONFIG from "../configuration/AppConfig";

export const uploadNewPlanAsync = async (
  siteId: string,
  fileData: Uint8Array,
): Promise<AxiosResponse> => {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix + APP_CONFIG.apiUploadAgriPlan}/${siteId}`;
  const params = {
    file: fileData,
  };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await axios.post<AxiosResponse>(url, params, headers);
  return res;
};

export const deleteAgriPlanAsync = async (siteId: string): Promise<boolean> => {
  const url = `${
    APP_CONFIG.serviceUrls.dualUsageUrlPrefix + APP_CONFIG.apiDeleteAgriPlan
  }/${siteId}`;
  const res = await axios.delete<void>(url);
  return res.status == 200;
};

export const getDailyPlanAsync = async (
  siteId: string
): Promise<IAgriDailyPlanDto[]> => {
  const url = `${
    APP_CONFIG.serviceUrls.dualUsageUrlPrefix + APP_CONFIG.apiGetDailyAgriPlan
  }/${siteId}`;

  const res = await axios.get<IAgriDailyPlanDto[]>(url);
  return res.data;
};

export const getOverallPlanAsFileAsync = async (siteId: string) => {
  const url = `${
    APP_CONFIG.serviceUrls.dualUsageUrlPrefix + APP_CONFIG.apiGetOverallAgriPlan
  }/${siteId}`;
  const res = await axios.get<string>(url);
  return res.data;
};

export const getAgriPlanTemplateAsync = async () => {
  const url = `${
    APP_CONFIG.serviceUrls.dualUsageUrlPrefix + APP_CONFIG.apiGetTemplateFileAgriPlan
  }`;
  const res = await axios.get<string>(url);
  return res.data;
};
