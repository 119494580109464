import Papa from "papaparse";
import {
  COMMUNICATION_CSV_HEADER,
  CSV_FILE_SUFFIX,
} from "../../../../common/ConstantValues";
import { createAndDownloadBlobFile } from "../../../../common/DownloadFileUtils";
import {
  getAgriPlanTemplateAsync,
  getDailyPlanAsync,
  getOverallPlanAsFileAsync,
} from "../AgriApi";

export async function downloadTemplate() {
  const planTemplate = await getAgriPlanTemplateAsync();
  createAndDownloadBlobFile(
    planTemplate,
    "AgriPlanTemplate",
    COMMUNICATION_CSV_HEADER,
    CSV_FILE_SUFFIX
  );
}

export async function downloadTodaysPlan(siteId: string) {
  const todaysPlan = await getDailyPlanAsync(siteId);
  const csv = Papa.unparse(todaysPlan);
  createAndDownloadBlobFile(
    csv,
    "Todays Plan",
    COMMUNICATION_CSV_HEADER,
    CSV_FILE_SUFFIX
  );
}

export async function downloadOverallPlan(siteId: string) {
  const overallPlan = await getOverallPlanAsFileAsync(siteId);
  createAndDownloadBlobFile(
    overallPlan,
    "Overall Plan",
    COMMUNICATION_CSV_HEADER,
    CSV_FILE_SUFFIX
  );
}
