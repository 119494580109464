import { ErrorIcons } from "../../../SolarGikLib/icons/IconsModels";
import { IValueTime } from "../../data_point/models/TagsModels";
import { ComponentTypeEnum, SeverityEnum } from "../faultsModels";

export interface IIssueInfo {
  metadataId: number;
  deviceId: number;
}

export interface IIssueInfoDto {
  SpecificMetadataId: number;
  SpecificMetadata: string | null;
  SiteId: string;
  DeviceNum: number;
  StartTime: string;
  EndTime: string | null;
}

export interface ISitesToIssueInfos {
  [siteId: string]: IIssueInfo[];
}

export enum IssuesLoadingStatus {
  Pending,
  Error,
  Complete
}

export interface ISiteToIssuesState {
  issues: IIssueStates;
  loadingState: { [siteId: string]: IssuesLoadingStatus };
  metadata: IIdToMetadataDictionary;
}

export interface IIssueStates {
  [siteId: string]: IIssuesAndMetadata;
}

export interface ComponentTypeToCountTimeValueList {
  [componentType: string]: {
    count: IValueTime[];
    low: IValueTime[];
    mild: IValueTime[];
    severe: IValueTime[];
  };
}
export interface IIssuesAndMetadata {
  activeIssuesByCategories: IComponentTypeToIssuesDictionary;
  counts: { [key in SeverityEnum]: number };
}
export interface IIssueAndMetadataForChart {
  [componentType: string]: {
    metadataId: number;
    startTime: string;
    endTime: string | null;
    severity: SeverityEnum;
  }[];
}
export interface IComponentTypeToCount {
  [key: string]: number;
}
export interface IComponentTypeToMaxCount {
  [key: string]: number;
}
export interface IComponentTypeToIssuesDictionary {
  [componentType: string]: IIdToIssuesDictionary;
}
export interface IIdToIssuesDictionary {
  [id: string]: IIssueInfo;
}
export interface IIdToMetadataDictionary {
  [id: string]: IIssuesMetadataDictionary;
}

export interface IIssuesMetadataDictionary {
  severity: SeverityEnum;
  componentType: ComponentTypeEnum;
  issueType: string;
  description: string;
  screenTimeMinutes: number;
}
export interface IComponentTypeToIssues {
  [key: string]: Issues[];
}
export interface Issues extends IIssuesMetadataDictionary, IIssueInfo {
  id: string;
}

type ValidSeverities = Exclude<SeverityEnum, SeverityEnum.Undefined>;

export interface ComponentTypeToIssuesSeverityCount {
  [key: string]: { [key in ValidSeverities | "totalIssuesCount"]: number };
}

export const convertEnumToIconName = (enumValue: number): string => {
  switch (enumValue) {
    case SeverityEnum.High:
      return ErrorIcons.HighSeverityError;
    case SeverityEnum.Medium:
      return ErrorIcons.MildSeverityError;
    case SeverityEnum.Low:
      return ErrorIcons.LowSeverityError;
    default:
      return ErrorIcons.UndefinedSeverityError;
  }
};

export interface IssuesHistoryParams {
  siteId: string;
  startTime: Date;
  endTime: Date;
  intervalInSeconds: number;
}
