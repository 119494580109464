import { GridColDef } from "@mui/x-data-grid";

import { convertEnumToIconName } from "./IssuesModels";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { formatDateTimeNoYear } from "../../app/DateTimeUtils";
import IconAndTextCellItem from "../../multi_sites/cellItems/IconAndTextCellItem";
import { ComponentTypeEnum, SeverityEnum } from "../faultsModels";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";
import { TrackerNameById } from "../../trackers/TrackerName";

export const issusTableColumns: GridColDef[] = [
  {
    field: "issueType",
    headerName: "Issue type",
    flex: 0.7,
  },
  {
    field: "componentType",
    headerName: "Component",
    flex: 0.5,
    renderCell: (params) => {
      return ComponentTypeEnum[params.value];
    },
  },
  {
    field: "deviceId",
    headerName: "Device Id",
    flex: 0.5,
    renderCell: (params) => {
      if (params.row.componentType === ComponentTypeEnum.Trackers) {
        return <TrackerNameById id={params.value} />;
      }
      return <span>{params.value}</span>;
    }
  },
  {
    field: "description",
    headerName: "Detail",
    flex: 2,
  },
  {
    field: "severity",
    headerName: "Severity",
    flex: 0.5,
    renderCell: (params) => {
      return (
        <IconAndTextCellItem
          Icon={getIcons(
            convertEnumToIconName(params.value),
            IconCategory.Error
          )}
          text={
            humanizeEnumValue(SeverityEnum[params.value]) ||
            NO_VALUE_PLACEHOLDER
          }
        />
      );
    },
  },
  {
    field: "startTime",
    headerName: "Time opened",
    flex: 0.7,
    renderCell: (params) => {
      return formatDateTimeNoYear(params.value);
    },
  },
];
