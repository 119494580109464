import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import differenceInSeconds from "date-fns/differenceInSeconds";

import {
  ITagData,
  ISiteToTagDataDictionary,
  ITagsState,
  TagValuesStr,
  IStringToTimeValueDictionary,
  IMultiSiteStringToTimeValueDictionary,
  TagTimeValidity,
} from "../../data_point/models/TagsModels";
import APP_CONFIG from "../configuration/AppConfig";
import { RootState } from "../Store";

const invalidValueTime: ITagData = {
  value: -1,
  time: new Date(0),
  timeValidity: TagTimeValidity.Invalid,
  valueExist: false,
};

const initialState: ITagsState = {
  tagsCache: {},
};
const updateSiteData = (
  state: ITagsState,
  siteId: string,
  data: IStringToTimeValueDictionary
) => {
  const now = new Date();

  if (!state.tagsCache[siteId]) {
    state.tagsCache[siteId] = {};
  }
  Object.entries(data).forEach(([key, valuePair]) => {
    const time = valuePair.time;
    state.tagsCache[siteId][key] = {
      value: valuePair.value,
      time: time,
      timeValidity: calculateValidity(now, time),
      valueExist: true,
    };
  });
};

const calculateValidity = (now: Date, tagTime: Date) => {
  const secondsDelta = differenceInSeconds(now, tagTime);
  if (secondsDelta < APP_CONFIG.tagStatusOldSecondsThreshold) {
    return TagTimeValidity.Valid;
  }
  if (secondsDelta < APP_CONFIG.tagStatusInvalidSecondsThreshold) {
    return TagTimeValidity.ValidButOld;
  }
  return TagTimeValidity.Invalid;
};

export const multisiteTagsSlice = createSlice({
  name: "multisiteTags",
  initialState,
  reducers: {
    updateSiteTags: (
      state: ITagsState,
      action: PayloadAction<{
        siteId: string;
        data: IStringToTimeValueDictionary;
      }>
    ) => {
      const { siteId, data } = action.payload;
      updateSiteData(state, siteId, data);
    },
    updateMultiSiteTags: (
      state: ITagsState,
      action: PayloadAction<IMultiSiteStringToTimeValueDictionary>
    ) => {
      Object.entries(action.payload).forEach(([siteId, data]) => {
        updateSiteData(state, siteId, data);
      });
    },
  },
});

export const selectSiteTag = createSelector(
  [
    (state: RootState) => state.multiSitesTags.tagsCache,
    (state: RootState, siteId: string, tagName: string) => ({
      siteId,
      tagName,
    }),
  ],
  (tagsTable: ISiteToTagDataDictionary, { siteId, tagName }) => {
    return tagsTable[siteId] && tagsTable[siteId][tagName]
      ? tagsTable[siteId][tagName]
      : invalidValueTime;
  }
);

export const selectIsTagValid = createSelector(
  [
    (state: RootState) => state.multiSitesTags.tagsCache,
    (state: RootState, siteId: string, tagName: string) => ({
      siteId,
      tagName,
    }),
  ],
  (tagsCache, { siteId, tagName }) => {
    return (
      tagsCache[siteId] &&
      tagsCache[siteId][tagName] &&
      tagsCache[siteId][tagName].timeValidity === TagTimeValidity.Valid
    );
  }
);

export const selectSiteTagDataStr = createSelector(
  [
    (state: RootState) => state.multiSitesTags.tagsCache,
    (state: RootState, siteId: string, tagName: string) => ({
      siteId,
      tagName,
    }),
  ],
  (tagsTable: ISiteToTagDataDictionary, { siteId, tagName }): TagValuesStr => {
    const valueTime: ITagData =
      tagsTable[siteId] && tagsTable[siteId][tagName]
        ? tagsTable[siteId][tagName]
        : invalidValueTime;
    return {
      value: parseFloat(valueTime.value.toFixed(1)).toString(),
      timestamp: valueTime.time,
    };
  }
);

export const multiSiteTagsActions = multisiteTagsSlice.actions;
export const multisiteTagsReducer = multisiteTagsSlice.reducer;
