import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";

export async function initTimeScaleSchemeForNewSiteAsync(siteId: string) {
  const url: string =
    APP_CONFIG.serviceUrls.historianTagsSystemBaseUrl +
    APP_CONFIG.apiInitTimeScaleDbSchemaForNewSites(siteId);
  const apiResponse = await axios.post<void>(url);
  return apiResponse;
}
