import getIcons from "../../../SolarGikLib/icons/Icons";
import { AuxiliariesStatusIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
enum StatusEnum {
  Undefined = 0,
  Ok = 1,
  Warning = 2,
  Error = 3,
}
export const getStatusEnum = (value: number | undefined) => {
  if (value === undefined || !(value in StatusEnum)) {
    return StatusEnum.Undefined;
  }
  return value as StatusEnum;
};
const mapStatusToIcons = {
  [StatusEnum.Ok]: getIcons(AuxiliariesStatusIcons.Ok, IconCategory.Status),
  [StatusEnum.Warning]: getIcons(AuxiliariesStatusIcons.Warning, IconCategory.Status),
  [StatusEnum.Error]: getIcons(AuxiliariesStatusIcons.Error, IconCategory.Status),
  [StatusEnum.Undefined]: getIcons(AuxiliariesStatusIcons.Undefined, IconCategory.Status),
};
export const convertStatusValueToIcon = (status: StatusEnum) => {
  return mapStatusToIcons[status];
};
