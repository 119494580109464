import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import classes from './TrackerName.module.css'

export const TrackerName: FC<{ name: string }> = ({ name }) => {
    return <span className={classes['tracker-name']}>{name}</span>;
};


export const TrackerNameById: FC<{ id: number }> = ({ id }) => {
    const trackerNamesMap = useSelector((state: RootState) => state.site.trackerNamesMap);
    const name = tryGetTrackerName(id, trackerNamesMap);
    return <TrackerName name={name} />;
};

export const tryGetTrackerName = (id: number, trackerNamesMap?: { [trackerId: number]: string }): string => {
    if (trackerNamesMap !== undefined) {
        const candidate = trackerNamesMap[id];
        if (candidate && candidate !== "") {
            return candidate;
        }
    }
    return id.toString();
}
