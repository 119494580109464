import { FC } from "react";

import Button from "@mui/material/Button";

import classes from "./Popup.module.css";
import { btnStyling } from "../../common/Mui/MuiStyling";

interface PopupProps {
  headerItems: React.ReactNode;
  content: React.ReactNode;
  exitFunction: () => void;
}
const Popup: FC<PopupProps> = ({ content, exitFunction, headerItems }) => {
  return (
    <>
      <div className={classes.popup}>
        <div className={classes["popup-is-open"]}>
          <div className={classes["popup-exit-button"]}>
            <Button sx={btnStyling} variant="contained" onClick={exitFunction}>
              X
            </Button>
          </div>
          <div className={classes["header-items"]}>{headerItems}</div>
          <div className={classes["popup-content"]}>{content}</div>
        </div>
      </div>
    </>
  );
};

export default Popup;
