import React, { useId } from "react";

interface ChargingBatteryIconProps {
  chargePercentage?: number;
}

const ChargingBatteryIcon: React.FC<ChargingBatteryIconProps> = ({
  chargePercentage,
}) => {
  // Ensure fillPercentage is between 0 and 100
  const key = useId();
  const gradientId = `batteryGradient_${key}`;
  const fillColor =
    chargePercentage == undefined
      ? "var(--undefined-color)"
      : chargePercentage < 10
      ? "var(--error-color)"
      : "var(--blue-color-primary)";
  if (chargePercentage == null) {
    chargePercentage = 0;
  }
  // normalizedPercentage is between 0 and 0.8 - to compensate on the battery cap in the svg
  const normalizedPercentage =
    Math.max(0, Math.min(100, chargePercentage)) / 120;

  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id={gradientId} x1="0" y1="1" x2="0" y2="0">
          <stop offset="0" stopColor={fillColor}>
            <animate
              attributeName="offset"
              dur="2s"
              from={normalizedPercentage}
              to="1"
              repeatCount={"indefinite"}
            />
          </stop>
          <stop offset="0" stopColor="#fff">
            <animate
              attributeName="offset"
              dur="2s"
              from={normalizedPercentage}
              to="1"
              repeatCount={"indefinite"}
            />
          </stop>
        </linearGradient>
      </defs>
      <mask
        id="mask0_21_966"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="27"
        height="27"
      >
        <rect
          x="27"
          width="27"
          height="27"
          transform="rotate(90 27 0)"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_21_966)">
        {/* Outline path */}
        <path
          d="M8.87006 5.94956C8.87006 5.69212 8.95716 5.47631 9.13134 5.30212C9.30553 5.12794 9.52134 5.04084 9.77878 5.04084L11.5962 5.04084L11.5962 3.375L15.4038 3.375L15.4038 5.04084L17.2257 5.04084C17.4837 5.04084 17.6989 5.12794 17.8712 5.30212C18.0437 5.47631 18.1299 5.69212 18.1299 5.94956L18.1299 22.7163C18.1299 22.9737 18.0428 23.1895 17.8687 23.3637C17.6945 23.5379 17.4787 23.625 17.2212 23.625L9.78244 23.625C9.519 23.625 9.30112 23.5379 9.12881 23.3637C8.95631 23.1895 8.87006 22.9737 8.87006 22.7163L8.87006 5.94956Z"
          fill={"none"}
          stroke={fillColor}
          strokeWidth="1"
        />
        {/* Fill path */}
        <path
          d="M8.87006 5.94956C8.87006 5.69212 8.95716 5.47631 9.13134 5.30212C9.30553 5.12794 9.52134 5.04084 9.77878 5.04084L11.5962 5.04084L11.5962 3.375L15.4038 3.375L15.4038 5.04084L17.2257 5.04084C17.4837 5.04084 17.6989 5.12794 17.8712 5.30212C18.0437 5.47631 18.1299 5.69212 18.1299 5.94956L18.1299 22.7163C18.1299 22.9737 18.0428 23.1895 17.8687 23.3637C17.6945 23.5379 17.4787 23.625 17.2212 23.625L9.78244 23.625C9.519 23.625 9.30112 23.5379 9.12881 23.3637C8.95631 23.1895 8.87006 22.9737 8.87006 22.7163L8.87006 5.94956Z"
          fill={`url(#${gradientId})`}
        />
        {/*top part*/}
        <path
          d="M9.78249 23.6251C9.51905 23.6251 9.30118 23.538 9.12887 23.3638C8.95637 23.1896 8.87012 22.9738 8.87012 22.7163L8.87012 5.94962C8.87012 5.69219 8.95721 5.47637 9.1314 5.30219C9.30559 5.128 9.5214 5.0409 9.77884 5.0409H11.5963V3.37506L15.4038 3.37506V5.0409L17.2258 5.0409C17.4838 5.0409 17.6989 5.128 17.8712 5.30219C18.0437 5.47637 18.13 5.69219 18.13 5.94962L18.13 22.7163C18.13 22.9738 18.0429 23.1896 17.8687 23.3638C17.6945 23.538 17.4787 23.6251 17.2213 23.6251H9.78249ZM9.99512 22.5001H17.005L17.005 6.14425L9.99512 6.14425L9.99512 22.5001Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default ChargingBatteryIcon;
