import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISiteIdToDisplayName } from "../data_point/models/TagsModels";
import { getSitesDisplayNames, getUserData } from "./UserAPI";
import APP_CONFIG from "../app/configuration/AppConfig";

export enum UserAccessType {
  None = 0 /*No access*/,
  ViewDashboard = 10 /*Watch only dashboard*/,
  Viewer = 20 /*See all opeartion + maintenenace screens*/,
  Operator = 30 /*Viewer + buttons*/,
  Commissioning = 40 /*Operator + Commissioning screens*/,
  Engineer = 50 /*SuperUser + hidden features / debugging*/,
  Admin = 60 /*Can change metadata of users as wel*/,
}

export enum UserInitializationState {
  NotInitialized = 0,
  Initializing = 1,
  Initialized = 2,
}

export interface IUserData {
  siteIds: string[];
  siteIdToDisplayNameMap: ISiteIdToDisplayName;
  userType: UserAccessType;
  originalUserType?: UserAccessType;
  userName: string;
  initializationState: UserInitializationState;
  sudoMode: boolean;
}

const initialState: IUserData = {
  siteIds: [],
  siteIdToDisplayNameMap: {},
  userType: UserAccessType.None,
  userName: "",
  initializationState: UserInitializationState.NotInitialized,
  sudoMode: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setSudoMode: (state: IUserData, action: PayloadAction<boolean>) => {
      state.sudoMode = action.payload;
    },
    reset: () => {
      return initialState;
    },
    impersonateAs: (state: IUserData, action: PayloadAction<UserAccessType>) => {
      if (action.payload === state.originalUserType) {
        state.originalUserType = undefined;
      } else if (!state.originalUserType) {
        state.originalUserType = state.userType;
      }
      state.userType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.userData,
          siteIdToDisplayNameMap: action.payload.siteIdToSiteDisplayNameMap,
          initializationState: UserInitializationState.Initialized,
        };
      })
      .addCase(fetchUserData.pending, (state) => {
        state.initializationState = UserInitializationState.Initializing;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.initializationState = UserInitializationState.NotInitialized;
        console.error(action.error);
      })
  }
});

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (accountName: string, { signal, rejectWithValue }) => {
    while (!signal.aborted) {
      try {
        const userData = await getUserData(signal);
        userData.siteIds.sort((a, b) => a.localeCompare(b));
        userData.userName = accountName;
        const siteIdToSiteDisplayNameMap = await getSitesDisplayNames(userData.siteIds, signal);
        return {
          userData,
          siteIdToSiteDisplayNameMap
        };
      } catch (e) {
        if (signal.aborted) {
          return rejectWithValue(e);
        }
        await new Promise((resolve, reject) => {
          setTimeout(resolve, APP_CONFIG.milliSecBetweenFailedInitRequests);
          signal.addEventListener("abort", () => reject(new Error("aborted")));
        });
      }
    }
    return rejectWithValue({ message: 'aborted' });
  }
);

export const userReducer = userSlice.reducer;
