export const autocompleteDropdownStyle = {
  width: "100%",
  background: "white",
  color: "var(--blue-color-primary)",
  borderRadius: "var(--border-radius-dropdown)",
  "&:hover": {
    color: "var(--blue-primary-color)",
    backgroundColor: "white",
  },
};
