import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISitesVisualInfoState, ISiteVisualInfo } from "../../sites/SiteModels";
import { RootState } from "../Store";

const initialState: ISitesVisualInfoState = {};

export const multiSitesVisualInfoSlice = createSlice({
  name: "multiSitesVisualInfo",
  initialState: initialState,
  reducers: {
    setSiteVisualInfo: (
      state: ISitesVisualInfoState,
      action: PayloadAction<ISiteVisualInfo>
    ) => {
      const siteInfo = action.payload;
      state[siteInfo.siteId] = action.payload;
    },
    setSitesVisualInfo: (
      state: ISitesVisualInfoState,
      action: PayloadAction<ISitesVisualInfoState>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const selectSiteDisplayName = createSelector(
  [
    (state: RootState) => state.multiSitesVisualInfo,
    (state: RootState) => state.site.siteId,
  ],
  (visualInfo, siteId) => visualInfo[siteId].siteUniqueDisplayName
);

export const multiSitesVisualInfoReducer = multiSitesVisualInfoSlice.reducer;
