import { FC } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { useLogout } from "../common/Hooks/useLogout";
import classes from "./InitAppError.module.css";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const InitAppError: FC<IProps> = ({ error }) => {
  const { instance } = useMsal();
  const logout = useLogout();
  return (
    <div className={classes.container}>
      <div className={classes.title}>⚠ An unexpected error has occurred!</div>
      <div className={classes.message}>
        {typeof error === "string" ? error : JSON.stringify(error)}
      </div>
      <div className={classes.suggestion}>
        Logging out and logging in again might resolve this issue:
      </div>
      <Button
        className={classes["logout-button"]}
        onClick={() => logout(instance)}
      >
        Log out
      </Button>
      <div>
        If this issue persists, please contact{" "}
        <a href="mailto:support@solargik.com">Solargik&apos;s support team</a>
      </div>
    </div>
  );
};
export default InitAppError;
