import { useSelector } from "react-redux";

import classes from "./WeatherDataPointCard.module.css";
import getIcons from "../../SolarGikLib/icons/Icons";
import {
  GeneralIcons,
  IconCategory,
  SomaIcons,
} from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import TagsNames from "../data_point/TagsNames";
import VerticalIconDataPoint from "../data_point/wrapper/VerticalIconDataPoint";

const WindIcon = getIcons(SomaIcons.Wind, IconCategory.Soma);
const TemperatureIcon = getIcons(SomaIcons.Temperature, IconCategory.Soma);
const SunIcon = getIcons(SomaIcons.Sun, IconCategory.Soma);
const DCPowerIcon = getIcons(SomaIcons.DCPower, IconCategory.Soma);
const Separator = getIcons(GeneralIcons.Separator, IconCategory.General);
const WindInvalidIcon = getIcons(SomaIcons.WindInvalid, IconCategory.Soma);
const TemperatureInvalidIcon = getIcons(
  SomaIcons.TemperatureInvalid,
  IconCategory.Soma
);
const SunInvalidIcon = getIcons(SomaIcons.SunInvalid, IconCategory.Soma);
const DCPowerInvalidIcon = getIcons(
  SomaIcons.DCPowerInvalid,
  IconCategory.Soma
);
const WeatherDataPointCard = () => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  return (
    <ul className={classes["list-container"]}>
      <VerticalIconDataPoint
        tagName={TagsNames.WIND}
        siteId={siteId}
        Icon={WindIcon}
        InvalidIcon={WindInvalidIcon}
      />
      <Separator />
      <VerticalIconDataPoint
        tagName={TagsNames.TEMP}
        siteId={siteId}
        Icon={TemperatureIcon}
        InvalidIcon={TemperatureInvalidIcon}
      />
      <Separator />
      <VerticalIconDataPoint
        tagName={TagsNames.GHI}
        siteId={siteId}
        Icon={SunIcon}
        InvalidIcon={SunInvalidIcon}
      />
      <Separator />
      <VerticalIconDataPoint
        tagName={TagsNames.SITE_DC_CURRENT_POWER}
        siteId={siteId}
        Icon={DCPowerIcon}
        InvalidIcon={DCPowerInvalidIcon}
      />
    </ul>
  );
};
export default WeatherDataPointCard;
