import { FC, useState, useEffect, memo } from "react";

import MultiSitesChartWrapper from "../charts_ui/MultiSitesChartWrapper";
import { CHART_DEFAULT_HEIGHT } from "../common_chart_config/ChartConfig";
import { IMultiSiteChartProps } from "../data_point/models/TagChartModel";
import { ITagLineInfo } from "../data_point/models/TagsModels";
import classes from "./MultiSiteTrends.module.css";
import { createTagLineInfo } from "./MultiSiteTrendsHelperFunctions";
import { ITrendViewProps } from "./TrendsModel";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
const MultiSiteTrends: FC<ITrendViewProps> = ({ trendConfig, linesConfig }) => {
  const initChartProps = {
    title: "",
    rangeTimeInZoneTime: { start: new Date(), end: new Date() },
    samplingIntervalInSeconds: 0,
    chartHeight: 0,
    tagInfo: [],
    chartType: ChartTypeEnum.line,
  };

  const [chartData, setChartData] =
    useState<IMultiSiteChartProps>(initChartProps);

  const createLines = () => {
    const tagValues: ITagLineInfo[] = [];
    linesConfig.forEach((line) => {
      tagValues.push(createTagLineInfo(line.site, line.tag));
    });
    const chartProps = {
      title: trendConfig.title,
      rangeTimeInZoneTime: {
        start: trendConfig.rangeTime[0],
        end: trendConfig.rangeTime[1],
      },
      samplingIntervalInSeconds: trendConfig.samplingInterval,
      chartHeight: CHART_DEFAULT_HEIGHT,
      tagInfo: tagValues,
      overrideShowLegend: true,
      chartType: trendConfig.chartType,
    };
    setChartData(chartProps);
  };

  useEffect(() => {
    if (linesConfig && linesConfig.length > 0) {
      createLines();
    }
  }, [linesConfig]);
  const isShowTrends =
    chartData &&
    linesConfig &&
    linesConfig.length > 0 &&
    linesConfig.some((line) => line.values.length > 0);

  return (
    <>
      {isShowTrends ? (
        <MultiSitesChartWrapper
          chartConfig={chartData}
          linesConfig={linesConfig}
        />
      ) : (
        <img
          className={classes.img}
          src={"/images/multiTrendsImgPlaceHolder.png"}
        />
      )}
    </>
  );
};
export default memo(MultiSiteTrends);
