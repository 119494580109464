// import DeleteIcon from "@mui/icons-material/Delete";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import classes from "./UserRecordDeleteCellItem.module.css";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { FileCommandsIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { multiSitesUserRecordsSlice } from "../../app/store/UserRecords";
import { deleteUserRecord } from "../UserRecordsAPI";
import { selectSiteId } from "../../sites/SiteStore";
export const UserRecordDeleteCellItem: React.FC<GridRenderCellParams> = (
  params
) => {
  const siteId = useSelector(selectSiteId);
  const dispatch = useDispatch();
  const recordId: number = params.row[params.field];
  const DeleteIcon = getIcons(FileCommandsIcons.Delete, IconCategory.FileCommands);
  const handleDelete = async () => {
    try {
      await deleteUserRecord(siteId, recordId);
      dispatch(
        multiSitesUserRecordsSlice.actions.deleteRecord({
          site: siteId,
          recordId: recordId,
        })
      );
    } catch (error) {
      console.log(`Error deleting record :${recordId},error: ${error}`);
    }
  };
  return <DeleteIcon onClick={handleDelete} className={classes.icon} />;
};
