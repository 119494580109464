import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { ISiteVisualInfo, ISitesVisualInfoState } from "../sites/SiteModels";

export const getSiteVisualInfo = async (
  siteId: string
): Promise<ISiteVisualInfo> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSiteVisualInfo;
  const params = { siteId: siteId };
  const httpResponse = await axios.request<ISiteVisualInfo>({
    url: url,
    params: params,
  });
  return httpResponse.data;
};

export const getSitesVisualInformation = async (
  siteIds: string[]
): Promise<ISitesVisualInfoState> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSitesVisualInfo;
  const httpResponse = await axios.post<ISitesVisualInfoState>(url, siteIds);

  return httpResponse.data;
};
