import { FC } from "react";

import { IPublicClientApplication } from "@azure/msal-browser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import privacyDoc from "../../documents/privacyDoc.pdf";
import SolarGikDropdown, { MenuItem } from "../../SolarGikLib/fields/Dropdown";
import { GENERAL_APP_CONFIG } from "../app/configuration/AppConfigModels";
import { resetSlice } from "../app/Store";
import { useLogout } from "../../common/Hooks/useLogout";

interface DropDownItems {
  instance: IPublicClientApplication;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
}

const UserOptionDropdownMenu: FC<DropDownItems> = ({ instance, icon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = useLogout();
  const handleLogoutFunc = () => {
    Logout(instance);
  };

  const menuItems: MenuItem[] = [
    {
      label: "Privacy Policy",
      onClick: () => window.open(privacyDoc, "_blank"),
    },
    {
      label: "Sites Dashboard",
      onClick: () => {
        dispatch(resetSlice.actions.clearResults());
        navigate(`/`, { replace: true });
      },
    },
    { label: "Logout", onClick: handleLogoutFunc },

    {
      label: "Soma " + GENERAL_APP_CONFIG.portalVersion,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
      disabled: true,
    },
  ];
  return (
    <SolarGikDropdown icon={icon} menuItems={menuItems}></SolarGikDropdown>
  );
};
export default UserOptionDropdownMenu;
