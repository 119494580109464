import axios from "axios";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { WeatherOverrideResultsCodeDto } from "./SafeOverrideModels";

export const activateWeatherOverride = async (
  siteId: string,
  expirationTimeUtc: number
): Promise<WeatherOverrideResultsCodeDto> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiActivateSafeOverride;
  const params = {
    siteId,
    expirationTimeUtc,
  };
  const response = await axios.post<WeatherOverrideResultsCodeDto>(url, params);
  return response.data;
};

export const deactivateWeatherOverride = async (
  siteId: string
): Promise<WeatherOverrideResultsCodeDto> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiDeactivateSafeOverride;
  const params = {
    siteId,
  };
  const response = await axios.delete<WeatherOverrideResultsCodeDto>(url, {
    params,
  });
  return response.data;
};

export const getWeatherOverrideRemainingTime = async (
  siteId: string
): Promise<number> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiGetWeatherSafetyOverrideRemainingTime;
  const params = {
    siteId,
  };
  const response = await axios.get<number>(url, {
    params,
  });
  return response.data;
};

export const getIsWeatherOverrideActive = async (
  siteId: string
): Promise<boolean> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiGetIsWeatherSafetyOverrideActive;
  const params = {
    siteId,
  };
  const response = await axios.request<boolean>({
    url: url,
    params: params,
  });
  return response.data;
};
