import { FC } from "react";

import { Popover } from "@mui/material";

import PopoverHeader from "../popover/PopoverHeader";
import { PopoverPlacementHorizontal } from "../popover/PopoverModels";
interface PopperProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
  title: string;
  placementHorizontal?: PopoverPlacementHorizontal;
  isOpen: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
}
const Dialog: FC<PopperProp> = ({
  children,
  title,
  isOpen,
  handleClose,
  anchorEl,
  placementHorizontal = "center",
}) => {
  const id = isOpen ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: placementHorizontal,
      }}
      sx={{
        "& .MuiPopover-paper": {
          fontFamily: "var(--font-family)",
          boxShadow: "var(--tooltip-box-shadow)",
          backgroundColor: "var(--card-background-color)",
          borderRadius: "var(--tooltip-border-radius)",
          color: "var(--blue-color-primary)",
          marginTop: "10px",
        },

        "& .MuiPopover-paper > div": {
          overflowY: "auto",
          maxHeight: "60vh",
          padding: "20px",
        },
      }}
    >
      <PopoverHeader title={title} />
      <div>{children}</div>
    </Popover>
  );
};

export default Dialog;
