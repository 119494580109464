import { FC, useEffect, useState } from "react";
import { differenceInMinutes, format, formatDistanceStrict } from "date-fns";
import { useSelector } from "react-redux";
import { TagValueType } from "./models/TagsModels";
import LightTooltipContent from "../../SolarGikLib/tooltip/LightTooltipContent";
import APP_CONFIG from "../app/configuration/AppConfig";
import {
  formatDateToDayMonthYearHourMinuteSeconds,
  getCurrentDateInSiteTimeZone,
  toDateInSiteTimeZone,
} from "../app/DateTimeUtils";
import { RootState } from "../app/Store";
import { selectSiteTag, selectSiteTagDataStr } from "../app/store/multisiteTagsStore";
import { selectSiteTagMetaData } from "../app/store/TagsMetadataStore";
import { UserAccessType } from "../user/UserStore";

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 60 * 24;
const MINUTES_TILL_DATE_IS_SHOWN = 60 * 12;
const NO_DATA_FOR_TIME = "No data";

function formatTimeForTooltip(
  siteTimeAtSample: Date,
  shiftInMin: number,
  useTimeFormat: boolean
) {
  const siteTimeNow = getCurrentDateInSiteTimeZone(shiftInMin);

  if (
    format(siteTimeAtSample, "yyyy") == "0001" ||
    format(siteTimeAtSample, "yyyy") == "1970"
  ) {
    return NO_DATA_FOR_TIME;
  } else if (useTimeFormat) {
    return formatDateToDayMonthYearHourMinuteSeconds(siteTimeAtSample);
  } else if (
    Math.abs(differenceInMinutes(siteTimeNow, siteTimeAtSample)) >=
    MINUTES_TILL_DATE_IS_SHOWN
  ) {
    let timeDescription = MINUTES_TILL_DATE_IS_SHOWN + " minutes ago";

    if (
      MINUTES_TILL_DATE_IS_SHOWN > MINUTES_IN_DAY &&
      MINUTES_TILL_DATE_IS_SHOWN % MINUTES_IN_DAY === 0
    ) {
      timeDescription =
        MINUTES_TILL_DATE_IS_SHOWN / MINUTES_IN_DAY + " days ago";
    } else if (
      MINUTES_TILL_DATE_IS_SHOWN > MINUTES_IN_HOUR &&
      MINUTES_TILL_DATE_IS_SHOWN % MINUTES_IN_HOUR === 0
    ) {
      timeDescription =
        MINUTES_TILL_DATE_IS_SHOWN / MINUTES_IN_HOUR + " hours ago";
    }
    return "More then " + timeDescription;
  } else {
    return formatDistanceStrict(siteTimeAtSample, siteTimeNow) + " ago";
  }
}

export interface TooltipContentProps {
  tagName: string;
  siteId: string;
  isShowDateOnly: boolean;
  isShowTagMetadataOnly: boolean;
  displayName?: string;
  descriptionOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
}

const DataPointTooltipContent: FC<TooltipContentProps> = ({
  displayName = null,
  tagName,
  isShowDateOnly,
  isShowTagMetadataOnly,
  siteId,
  valueToStringFuncOverride,
  descriptionOverride,
}) => {
  const tagMetaData = useSelector((state: RootState) =>
    selectSiteTagMetaData(state.multiSitesTagsMetadata, siteId, tagName));
  const tagValuesStr = useSelector((state: RootState) => selectSiteTagDataStr(state, siteId, tagName));
  const userType = useSelector((state: RootState) => state.user.userType);
  const tag = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName));
  const shiftInMin = useSelector((state: RootState) =>
    state.multiSitesMetadata[siteId].timeZoneOffsetInMinutes);

  const [siteTimeAtSample, setSiteTimeAtSample] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSiteTimeAtSample(
        toDateInSiteTimeZone(tagValuesStr.timestamp, shiftInMin)
      );
    }, APP_CONFIG.onHoverUpdateIntervalInMilliseconds);

    setSiteTimeAtSample(
      toDateInSiteTimeZone(tagValuesStr.timestamp, shiftInMin)
    );

    return () => clearInterval(intervalId);
  }, [tag]);

  let valueAsString: string;
  if (valueToStringFuncOverride != null) {
    valueAsString = valueToStringFuncOverride(tag.value);
  } else if (tagMetaData.toStringConverter == null) {
    valueAsString = tagValuesStr.value;
  } else {
    valueAsString = tagMetaData.toStringConverter(tag.value);
  }
  const showValueWithEnum =
    tagMetaData.tagValueType === (TagValueType.Enum as number);

  if (displayName == null) {
    displayName = tagMetaData.displayName ?? tagName;
  }

  const hasUnit = tagMetaData.unitName != null && tagMetaData.unitName !== "";
  const formattedTime = formatTimeForTooltip(
    siteTimeAtSample,
    shiftInMin,
    false
  );

  const isEngineer = userType >= UserAccessType.Engineer;

  const tooltipInfo: string[] = [];

  function addTagMetadataInfo() {
    tooltipInfo.push(descriptionOverride ?? tagMetaData.description);
    if (isEngineer) {
      tooltipInfo.push("Tag: " + tagName);
      tooltipInfo.push("Type: " + TagValueType[tagMetaData.tagValueType]);
    }
  }

  function addTagValueInfo() {
    if (isEngineer) {
      let valueInfo = "";
      showValueWithEnum && (valueInfo += "Value: ");
      valueInfo += valueAsString;
      showValueWithEnum && (valueInfo += ` (${tag.value})`);
      hasUnit && (valueInfo += ` (${tagMetaData.unitName})`);
      tooltipInfo.push(valueInfo);
    }
    tooltipInfo.push(formattedTime);
    if (isEngineer && formattedTime !== NO_DATA_FOR_TIME) {
      tooltipInfo.push(
        formatTimeForTooltip(siteTimeAtSample, shiftInMin, true)
      );
    }
  }

  if (!isShowDateOnly) {
    addTagMetadataInfo();
  }
  if (!isShowTagMetadataOnly) {
    addTagValueInfo();
  }

  return (
    <LightTooltipContent
      name={!isShowDateOnly ? displayName : undefined}
      info={tooltipInfo}
    />
  );
};

export default DataPointTooltipContent;
