import React, { useState, useEffect } from "react";

import Skeleton from "@mui/material/Skeleton";

import APP_CONFIG from "../../features/app/configuration/AppConfig";

interface ITableCellSkeletonWrapperProps {
  loading: boolean;
  children: React.ReactNode;
  variant?: "text" | "circular";
}

export const TableCellSkeletonWrapper: React.FC<
  ITableCellSkeletonWrapperProps
> = ({ loading, children, variant = "text" }) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (loading) {
      const timeoutId = setTimeout(() => {
        setShowChildren(true);
      }, APP_CONFIG.milliSecLoaderTimeOut);

      return () => clearTimeout(timeoutId);
    } else {
      setShowChildren(true);
    }
  }, [loading]);

  if (!showChildren) {
    return <Skeleton variant={variant} width="40%" height={15} />;
  }

  return <>{children}</>;
};
