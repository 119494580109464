import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import classes from "./TimeComponent.module.css";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import {
  UNDEFINED_STR_FOR_DATE,
  UNDEFINED_STR_FOR_TIME,
  formatDateToWeekdayDayMonth,
  formatTime,
  getCurrentDateInSiteTimeZone,
} from "../app/DateTimeUtils";
import { RootState } from "../app/Store";

const TimeComponent = () => {
  const zoneOffsetTime = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes
  );
  const [siteDate, setSiteDate] = useState(
    getCurrentDateInSiteTimeZone(zoneOffsetTime)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSiteDate(getCurrentDateInSiteTimeZone(zoneOffsetTime));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [zoneOffsetTime]);

  return (
    <div className={`${classes["clock-group"]} ${TextEnum.h4}`}>
      <div>
        {!siteDate && UNDEFINED_STR_FOR_DATE}
        {siteDate && formatDateToWeekdayDayMonth(siteDate)}
      </div>
      <div className={classes["time"]}>
        {!siteDate && UNDEFINED_STR_FOR_TIME}
        {siteDate && formatTime(siteDate)}
      </div>
    </div>
  );
};
export default TimeComponent;
