export interface ISitesMetadataDictionary {
  [siteId: string]: ISiteMetadata;
}
export enum SiteTrackerTypeEnum {
  GPT = "GPT",
  SPT = "SPT",
}

export type TrackerNamesMap = { [trackerId: number]: string };

export interface ISiteMetadata {
  siteId: string;
  timeZoneOffsetInMinutes: number;
  trackerIds: number[];
  trackerNamesMap?: TrackerNamesMap;
  weatherDeviceIds: number[];
  inverterIds: number[];
  powerMeterIds: number[];
  ianaTimeZoneName: string;
  upsExists: boolean;
  nrtImageUrl?: string;
  cloudTypeImageUrl?: string;
  trackerType?: SiteTrackerTypeEnum;
}

export interface ISiteVisualInfo {
  siteId: string;
  siteUniqueDisplayName: string;
  isAgriPageVisible: boolean;
  isEnableUserRecordsFeature: boolean;
  isEnableSprinklersFeature: boolean;
  isEnableWeatherFeature: boolean;
  isEnableQueryHistoryForMoreThanOneWeek: boolean;
  isEnableIssuesFeature: boolean;
}

export interface ISitesVisualInfoState {
  [siteId: string]: ISiteVisualInfo;
}
