import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum FaultsTabOptions {
  Alerts = "Alerts",
  Issues = "Issues",
}

export enum AlertTabOptions {
  Active = "Active",
  History = "History",
}

const defaultState = {
  activeTab: FaultsTabOptions.Alerts,
  alertDropdownTabOption: AlertTabOptions.Active,
};

export const faultsPageSlice = createSlice({
  name: "faultsPage",
  initialState: defaultState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<FaultsTabOptions>) => {
      state.activeTab = action.payload;
    },
    setAlertDropdownTabOption: (
      state,
      action: PayloadAction<AlertTabOptions>
    ) => {
      state.alertDropdownTabOption = action.payload;
    },
  },
});

export const { setActiveTab, setAlertDropdownTabOption } =
  faultsPageSlice.actions;
export const faultsPageReducer = faultsPageSlice.reducer;
