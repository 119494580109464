import { FC } from "react";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import classes from "./TableDataPoint.module.css";

interface ITableStaticDataPoint {
  title: string;
  value: number;
  unit: string;
}
const TableStaticDataPoint: FC<ITableStaticDataPoint> = ({ title, value, unit }) => {
  return (
    <div className={classes["table-data-point-container"]}>
      <div className={`${TextEnum.h4} ${classes.header}`}>
        <div className={`${classes["title"]} ${TextEnum.h5}`}>{title}</div>
      </div>
      <div className={classes["table-data-point-body"]}>
        <span className={`${classes["value"]} ${TextEnum.h1}`}>{value}</span>
        <span className={`${classes["unit"]} ${TextEnum.h7}`}>{unit}</span>
      </div>
    </div>
  );
};

export default TableStaticDataPoint;
