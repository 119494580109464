import { FC } from "react";

import { Skeleton } from "@mui/material";
import { Collapse } from "antd";
import { useSelector } from "react-redux";

import { Issues } from "./IssuesModels";
import classes from "./IssuesSection.module.css";
import { selectCurrentSiteActiveIssuesCountByComponentType } from "../../app/store/IssuesStore";
import { StyledPanel } from "./issuesStyle";
import { issusTableColumns } from "./IssuesTableColumnConfig";
import Table from "../../../common/table/Table";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { ComponentTypeEnum, SeverityEnum } from "../faultsModels";

interface IIssuesSectionProps {
  defaultExpandedKey: number;
  componentTypeEnum: number;
  issuesList: Issues[];
  isLoading: boolean;
}

const IssuesSection: FC<IIssuesSectionProps> = ({
  defaultExpandedKey,
  componentTypeEnum,
  issuesList,
  isLoading,
}) => {
  const issuesSeveritiesByComponentType = useSelector(selectCurrentSiteActiveIssuesCountByComponentType);
  const title = ComponentTypeEnum[componentTypeEnum];
  const componentIssues = issuesSeveritiesByComponentType[componentTypeEnum];
  const header =
    componentIssues.totalIssuesCount !== 0 ? (
      <div className={`${classes["panel-header"]} ${TextEnum.h4}`}>
        <div>
          {title}
          <div className={`${classes["title-info"]} ${TextEnum.h5}`}>
            {componentIssues.totalIssuesCount + " Issues"}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["title-info"]}>
            {componentIssues[SeverityEnum.High] + " High"}
          </div>
          <div className={classes["title-info"]}>
            {componentIssues[SeverityEnum.Medium] + " Medium"}
          </div>
          <div className={classes["title-info"]}>
            {componentIssues[SeverityEnum.Low] + " Low"}
          </div>
        </div>
      </div>
    ) : (
      <div className={`${classes["panel-header"]} ${TextEnum.h4}`}>
        {isLoading ? (
          <Skeleton variant="rounded" width="100%" height={50} />
        ) : (
          <>
            <div>
              {title}
              <div className={`${classes["title-info"]} ${TextEnum.h5}`}>
                {componentIssues.totalIssuesCount + " Issues"}
              </div>
            </div>
            <div className={`${classes["no-issues-info"]} ${TextEnum.h2}`}>
              <div>
                <span className={TextEnum.h3}> Congratulations! </span>
                {componentTypeEnum === ComponentTypeEnum.System ? (
                  <span>{title} has no issues!</span>
                ) : (
                  <span>{title} have no issues!</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  const table = (groupedIssuesList: Issues[]) => (
    <Table
      rows={groupedIssuesList}
      columns={issusTableColumns}
      hideFooter
      hideToolbar
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "severity",
              sort: "desc",
            },
          ],
        },
      }}
    />
  );
  return (
    <div className={classes["issues-section"]}>
      <Collapse
        className={classes.collapse}
        defaultActiveKey={ComponentTypeEnum[defaultExpandedKey]}
      >
        <StyledPanel className={classes.panel} key={title} header={header}>
          {componentIssues.totalIssuesCount !== 0 && table(issuesList)}
        </StyledPanel>
      </Collapse>
    </div>
  );
};
export default IssuesSection;
