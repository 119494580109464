import { FC } from "react";

import classes from "./TableCellItem.module.css";
import { NO_VALUE_PLACEHOLDER } from "../ConstantValues";
interface ITableCellItemStringProps {
  value: string | null;
  extraClassName?: string;
}
const TableCellItemString: FC<ITableCellItemStringProps> = ({ value, extraClassName }) => {
  const text = value != null ? value : NO_VALUE_PLACEHOLDER;
  return <div className={classes.center + " " + extraClassName}>{text}</div>;
};
export default TableCellItemString;
