import { useSelector } from "react-redux";
import classes from "./AgriDataPointCard.module.css";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory, SomaIcons } from "../../../SolarGikLib/icons/IconsModels";
import TagsNames from "../../data_point/TagsNames";
import VerticalIconDataPoint from "../../data_point/wrapper/VerticalIconDataPoint";
import { selectSiteId } from "../../sites/SiteStore";

const AgiDataPointCard = () => {
  const siteId = useSelector(selectSiteId);
  const PlantGrowthIcon = getIcons(SomaIcons.PlantGrowth, IconCategory.Soma);
  const AcPowerIcon = getIcons(SomaIcons.ACPower, IconCategory.Soma);
  const PhotosynIcon = getIcons(SomaIcons.Photosyn, IconCategory.Soma);
  const BarometricPressureIcon = getIcons(SomaIcons.BarometricPressure, IconCategory.Soma);
  const RainGougeIcon = getIcons(SomaIcons.RainGouge, IconCategory.Soma);
  const Separator = getIcons(GeneralIcons.Separator, IconCategory.General);
  const PlantGrowthInvalidIcon = getIcons(SomaIcons.PlantGrowthInvalid, IconCategory.Soma);
  const AcPowerInvalidIcon = getIcons(SomaIcons.ACPowerInvalid, IconCategory.Soma);
  const PhotosynInvalidIcon = getIcons(SomaIcons.PhotosynInvalid, IconCategory.Soma);
  const BarometricPressureInvalidIcon = getIcons(SomaIcons.BarometricPressureInvalid, IconCategory.Soma);
  const RainGougeInvalidIcon = getIcons(SomaIcons.RainGougeInvalid, IconCategory.Soma);
  return (
    <ul className={classes["list-container"]}>
      <VerticalIconDataPoint
        tagName={TagsNames.SOIL_STEM_GROWTH}
        siteId={siteId}
        titleOverride="Plant Growth"
        Icon={PlantGrowthIcon}
        InvalidIcon={PlantGrowthInvalidIcon}
      />
      <Separator/>
      <VerticalIconDataPoint
        tagName={TagsNames.SITE_AC_CURRENT_POWER}
        siteId={siteId}
        titleOverride="Daily Power"
        Icon={AcPowerIcon}
        InvalidIcon={AcPowerInvalidIcon}
      />
      <Separator/>
      <VerticalIconDataPoint
        tagName={TagsNames.SOIL_PHOTOSYN}
        siteId={siteId}
        titleOverride="Daily Photosyn"
        Icon={PhotosynIcon}
        InvalidIcon={PhotosynInvalidIcon}
      />
       <Separator/>
      <VerticalIconDataPoint
        tagName={TagsNames.BAROMETRIC_PRESSURE}
        siteId={siteId}
        titleOverride="Barometric Pressure"
        Icon={BarometricPressureIcon}
        InvalidIcon={BarometricPressureInvalidIcon}
      />
      <Separator/>
      <VerticalIconDataPoint
        tagName={TagsNames.SOIL_RAIN_FALL}
        siteId={siteId}
        titleOverride="Rain Gauge"
        Icon={RainGougeIcon}
        InvalidIcon={RainGougeInvalidIcon}
      />
    </ul>
  );
};
export default AgiDataPointCard;
