import {
  IPublicClientApplication,
  EndSessionRequest,
} from "@azure/msal-browser";
import axios from "axios";

export async function acquireAccessToken(
  instance: IPublicClientApplication,
  scopes: string[]
): Promise<string> {
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw Error("User is not logged in...");
  }
  const request = {
    scopes: scopes,
    account: activeAccount || accounts[0],
  };
  let accessToken = "";
  try {
    const authResult = await instance.acquireTokenSilent(request);
    accessToken = authResult.accessToken;
  } catch (error) {
    console.warn(error);
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    } as EndSessionRequest);
  }
  return accessToken;
}

export function registerAxiosAuthorizationHeaderInterceptor(
  msalInstance: IPublicClientApplication,
  scopes: string[]
) {
  axios.interceptors.request.use(async (config) => {
    const token = await acquireAccessToken(msalInstance, scopes);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
}
