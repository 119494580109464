import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ITrackersState,
  ITrackerStatus,
} from "../../trackers/TrackersModels";

const defaultTrackersState: ITrackersState = {};

export const multiSitesTrackersSlice = createSlice({
  name: "multiSitesTrackers",
  initialState: defaultTrackersState,
  reducers: {
    updateTrackerStatusData: (
      state: ITrackersState,
      action: PayloadAction<{
        site: string;
        trackerResponse: ITrackerStatus[];
      }>
    ) => {
      const { site, trackerResponse } = action.payload;
      state[site] = trackerResponse;
    },
  },
});

export const multiSitesTrackersReducer = multiSitesTrackersSlice.reducer;
