import { EmergencyReason, IEmergencyListitem } from "./EmergencyModel";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  ReasonIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { UserAccessType } from "../../user/UserStore";
const emergencyOptionListConfig: IEmergencyListitem[] = [
  {
    title: "Wind Safe",
    icon: getIcons(ReasonIcons.Wind, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: EmergencyReason.WeatherProjectionWind,
  },
  {
    title: "Snow Safe",
    icon: getIcons(ReasonIcons.Snow, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: EmergencyReason.WeatherProjectionSnow,
  },
  {
    title: "Panel Washing",
    icon: getIcons(ReasonIcons.Sprinklers, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: EmergencyReason.PanelWashing,
  },
  {
    title: "Tracker Maintenance",
    icon: getIcons(ReasonIcons.Tracker, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: EmergencyReason.TrackerMaintenance,
  },
  {
    title: "User Maintenance",
    icon: getIcons(ReasonIcons.Maintenance, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: EmergencyReason.UserMaintenance,
  },
  {
    title: "Software Update",
    icon: getIcons(ReasonIcons.Software, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: EmergencyReason.SoftwareUpdate,
  },
];
export const getEmergencyOptionListConfig = (userAccess: UserAccessType) => {
  return emergencyOptionListConfig.filter(
    (item) => item.accessType <= userAccess
  );
};
