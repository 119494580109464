import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../features/app/Store";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { fetchActiveIssues } from "../features/app/store/IssuesStore";
import { selectSiteId } from "../features/sites/SiteStore";

const IssuesPoller = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siteId = useSelector(selectSiteId);

  useEffect(() => {
    dispatch(fetchActiveIssues(siteId));
    const interval = setInterval(
      () => dispatch(fetchActiveIssues(siteId)),
      APP_CONFIG.milliSecBetweenGetIssues
    );
    return () => clearInterval(interval);
  }, [siteId]);
  return <></>;
};

export default IssuesPoller;
