import getIcons from "../../../../SolarGikLib/icons/Icons";
import {
  FieldIcons,
  IconCategory,
} from "../../../../SolarGikLib/icons/IconsModels";
import classes from "./TimeOpenedHeader.module.css";

const TimeOpenedHeader: React.FC = () => {
  const Icon = getIcons(FieldIcons.Calender, IconCategory.Fields);
  return (
    <div className={classes.container}>
      <Icon className={classes.icon} />
      {"Time Opened"}
    </div>
  );
};
export default TimeOpenedHeader;
