import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";
import {
  ISiteToTagToMetaDataDictionary,
  ITagMetaData,
} from "../../data_point/models/TagsModels";
const invalidTagMetaData = {
  displayName: NO_VALUE_PLACEHOLDER,
  unitName: NO_VALUE_PLACEHOLDER,
  description: NO_VALUE_PLACEHOLDER,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toStringConverter: (_) => NO_VALUE_PLACEHOLDER,
} as ITagMetaData;
const tagsMetadataTable: ISiteToTagToMetaDataDictionary = {};

export const multiTagsMetadataSlice = createSlice({
  name: "multiSitesTagsMetadata",
  initialState: tagsMetadataTable,
  reducers: {
    setTagsMetaData: (
      state: ISiteToTagToMetaDataDictionary,
      action: PayloadAction<ISiteToTagToMetaDataDictionary>
    ) => {
      // Check if each tag's metadata in the action payload exists in the state.
      // If not, add it to the state.
      const updatedState = { ...state };
      Object.keys(action.payload).forEach((site) => {
        if (!updatedState[site]) {
          updatedState[site] = {};
        }
        const tagMetaData = action.payload[site];
        Object.keys(tagMetaData).forEach((tag) => {
          if (!updatedState[site][tag]) {
            updatedState[site][tag] = tagMetaData[tag];
          }
        });
      });
      return updatedState;
    },
  },
});

export const selectSiteTagMetaData = createSelector(
  [
    (
      tagsMetadata: ISiteToTagToMetaDataDictionary,
      siteId: string,
      tagName: string
    ) => ({
      tagsMetadata,
      siteId,
      tagName,
    }),
  ],
  ({ tagsMetadata, siteId, tagName }) => {
    return tagsMetadata[siteId] && Object.hasOwn(tagsMetadata[siteId], tagName)
      ? tagsMetadata[siteId][tagName]
      : invalidTagMetaData;
  }
);

export const multiSitesTagsMetadataReducer = multiTagsMetadataSlice.reducer;
