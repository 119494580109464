import { TrackersCommandType } from "../TrackersModels";

interface ITrackersCommandResult {
  commandType: TrackersCommandType;
  elevation?: number;
}
type TGetTitleMessageFn = (commandResult: ITrackersCommandResult) => string;
const commandToTitleMessage: Record<TrackersCommandType, TGetTitleMessageFn> = {
  [TrackersCommandType.SendTrackersToMaintenance]: (commandResult) => `${"Maintenance"} ${commandResult.elevation}`,
  [TrackersCommandType.ReleaseTrackersFromMaintenance]: () => "Released",
  [TrackersCommandType.SendTrackersToFreeze]: () => "Freeze",
};
export const getResultTitleMessage = (
  trackersCommands: ITrackersCommandResult
): string => {
  const getTitleMessage = commandToTitleMessage[trackersCommands.commandType];
  if (getTitleMessage) {
    return getTitleMessage(trackersCommands);
  } else {
    return "Unknown Command";
  }
};