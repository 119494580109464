import { FC } from "react";

import classes from "./TableCellItem.module.css";
import { NO_VALUE_PLACEHOLDER } from "../ConstantValues";
import { valueToFixedNumber } from "../StringUtils";
interface TableCellItemNumberProps {
  value: number;
}
const TableCellItemNumber: FC<TableCellItemNumberProps> = ({ value }) => {
  const text = (value === undefined || value === null) ? NO_VALUE_PLACEHOLDER : valueToFixedNumber(value, 2);

  return <div className={classes.center}>{text}</div>;
};
export default TableCellItemNumber;
