import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";

import { useSelector } from "react-redux";
import classes from "./DasDeploy.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import {
  getModuleStatusOnDevice,
  addOrUpdateTagInIotDevice,
  updateMcsToUseDas,
  getIotDevicesIdList,
  ensureSiteModuleTypeExists,
} from "../SiteControlAppsDeployApi";
import { SiteAppType } from "../AppConfigModels";
import { selectSiteId } from "../../sites/SiteStore";

const siteApp = SiteAppType.Das;

const DasDeploy = () => {
  const [tagVersion, setTagVersion] = useState<string>("");
  const [status, setStatus] = useState<string>("Click to check status");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [deviceIdList, setDeviceIdList] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<string>("");
  const siteId = useSelector(selectSiteId);
  const siteIdPrefix = siteId.split("-")[0];

  useEffect(() => {
    async function fetchData() {
      await callGetIotDeviceIdAsync();
    }
    fetchData();
  }, []);

  const isReady =
    status !== "NotExist" && status !== "Click to check status" ? true : false;

  const handleSelectDeviceId = (value: string | null) => {
    if (value === null) {
      return;
    }
    if (value.startsWith(siteIdPrefix)) {
      setDeviceId(value);
    } else if (value.length > 0) {
      if (
        window.confirm(
          `The device id is ${value} and the site is ${siteId}, are you sure to continue?`
        )
      ) {
        setDeviceId(value);
      }
    }
  };

  const handleCheckDeployStatus = async () => {
    try {
      const status = await getModuleStatusOnDevice(
        siteId,
        deviceId,
        siteApp
      );
      setStatus(status);
    } catch (error) {
      setAlertMessage({
        text: `Failed to get module status on device ${deviceId}`,
        severity: "error",
      });
    }
  };

  const handleStartDeploy = async () => {
    await callEnsureSiteModuleTypeExists();
    await callUpdateDeviceTagVersion();
  };

  const callEnsureSiteModuleTypeExists = async () => {
    try {
      await ensureSiteModuleTypeExists(siteId, siteApp, deviceId);
    } catch (error) {
      setAlertMessage({
        text: "Failed to ensure site module type das exist",
        severity: "error",
      });
      throw new Error("Failed to ensureSiteModuleTypeExist");
    }
  };

  const callGetIotDeviceIdAsync = async () => {
    try {
      const deviceList = await getIotDevicesIdList();
      if (deviceList.length === 0) {
        setAlertMessage({
          text: "No Iot Device Id found",
          severity: "error",
        });
      } else {
        setDeviceIdList(deviceList);
      }
    } catch (error) {
      setAlertMessage({
        text: "Failed to get Iot Device Id",
        severity: "error",
      });
      throw new Error("Failed to getIotDeviceId");
    }
  };

  const callUpdateDeviceTagVersion = async () => {
    try {
      await addOrUpdateTagInIotDevice(deviceId, "das_version", tagVersion);
    } catch (error) {
      setAlertMessage({
        text: "Failed to add or update tag In Iot device",
        severity: "error",
      });
      throw new Error("Failed to addOrUpdateTagInIotDevice");
    }
  };

  const handleUseDas = async () => {
    try {
      await updateMcsToUseDas(siteId);
    } catch (error) {
      setAlertMessage({
        text: "Failed to update Mcs to use Das",
        severity: "error",
      });
      throw new Error("Failed to updateMcsToUseDas");
    }
    setAlertMessage({
      text: "Mcs configuration applied successfully",
      severity: "success",
    });
  };

  const filterDeviceIdList: string[] = deviceIdList.filter((x) =>
    x.startsWith(siteIdPrefix)
  );

  const isDeviceIdAndSiteIsNotMatch =
    filterDeviceIdList.length === 0 && deviceIdList.length > 0;

  return (
    <div className={classes["root"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes["row"]}>
        {isDeviceIdAndSiteIsNotMatch ? (
          <SolarGikInput
            type="text"
            label="Device Id"
            placeholder="Device Id"
            value={deviceId}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setDeviceId(e.target.value)}
          />
        ) : (
          <SolarGikAutocomplete
            size="small"
            options={filterDeviceIdList}
            renderInputFunc={(params) => (
              <TextField {...params} label="Device Id" sx={inputStyle} />
            )}
            onSelect={(value: { target: { value: string } }) => {
              handleSelectDeviceId(value.target.value);
            }}
            key={"device Id"}
            id={"device Id"}
          />
        )}
      </div>

      <div className={classes["row"]}>
        <SolarGikInput
          type="text"
          label="Version number"
          placeholder="Version number"
          value={tagVersion}
          disabled={deviceId === ""}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setTagVersion(e.target.value)
          }
        />
        <SolarGikButton
          text="start deploy"
          style={outlinedWhiteButton}
          onClickFunc={handleStartDeploy}
          isDisabled={tagVersion === ""}
        />
      </div>

      <div className={classes["row"]}>
        <SolarGikButton
          text={`Check deploy status - ${status}`}
          onClickFunc={handleCheckDeployStatus}
          style={{
            ...outlinedWhiteButton,
            backgroundColor: isReady ? "var(--ok-color)" : "",
          }}
        />
      </div>

      <div className={classes["row"]}>
        <SolarGikButton
          text="Use das"
          style={outlinedWhiteButton}
          isDisabled={!isReady}
          onClickFunc={handleUseDas}
        />
      </div>
    </div>
  );
};
export default DasDeploy;
