import { useEffect, useState, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classes from "./Trackers.module.css";
import { repetitivePolling } from "../common/AsyncUtils";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { RootState } from "../features/app/Store";
import { multiSitesTrackersSlice } from "../features/app/store/TrackersStore";
import CardSiteStyledLineChartWrapper from "../features/charts_ui/CardSiteStyledLineChartWrapper";
import {
  CHART_DEFAULT_HEIGHT,
  ChartParams,
  createTrackersChartConfig,
  TRACKER_ELEVATION_TITLE,
} from "../features/common_chart_config/ChartConfig";
import { IRangeTime } from "../features/data_point/charts/ChartModel";
import { getAllLatestTrackersAsync } from "../features/trackers/TrackersAPI";
import { ITrackerStatus } from "../features/trackers/TrackersModels";
import TrackersStatusTable from "../features/trackers/TrackersStatusTable";
import Card from "../SolarGikLib/cards/Card";
import TrackersCommand from "../features/trackers/TrackersCommand/TrackersCommand";
import { selectSiteId } from "../features/sites/SiteStore";

const Trackers = () => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteId);
  const zoneOffsetTimeInMinutes = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes,
    shallowEqual
  );
  const trackers = useSelector(
    (state: RootState) => state.multiSitesTrackers[siteId] || []
  );
  const trackerIds = useSelector((state: RootState) => state.site.trackerIds);
  const [isLoading, setIsLoading] = useState(Object.keys(trackers).length == 0);

  const [selectedTrackerIds, setSelectedTrackerIds] = useState<number[]>([]);

  const getTrackerChartConfig = () =>
    createTrackersChartConfig(
      trackerIds.slice(0, 50),
      zoneOffsetTimeInMinutes,
      siteId
    );

  const trackerChartConfig = useMemo<ChartParams>(getTrackerChartConfig, [
    siteId,
    zoneOffsetTimeInMinutes,
    trackerIds,
  ]);

  const timeRange = useMemo<IRangeTime>(() => {
    return {
      start: trackerChartConfig.fromDate,
      end: trackerChartConfig.toDate,
    };
  }, [trackerChartConfig.fromDate, trackerChartConfig.toDate]);

  const pollingTrackers = async () => {
    const trackersInfo: ITrackerStatus[] = await getAllLatestTrackersAsync(siteId);
    dispatch(
      multiSitesTrackersSlice.actions.updateTrackerStatusData({
        site: siteId,
        trackerResponse: trackersInfo,
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const cntrlr = new AbortController();
    repetitivePolling(
      'pollingTrackers',
      pollingTrackers,
      APP_CONFIG.milliSecBetweenGetTrackers,
      APP_CONFIG.milliSecBetweenGetTrackers,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, [siteId]);

  return (
    <div className={classes["grid-container"]}>
      <div className={`${classes["table-card"]}`}>
        <Card contentStyle={{ marginBottom: 0 }}>
          <TrackersStatusTable
            handleRowsSelected={setSelectedTrackerIds}
            isLoading={isLoading}
          />
        </Card>
      </div>
      <div className={`${classes["chart-card"]}`}>
        {trackerChartConfig.tags.length > 0 && (
          <CardSiteStyledLineChartWrapper
            siteId={trackerChartConfig.siteId}
            title={TRACKER_ELEVATION_TITLE}
            rangeTimeInZoneTime={timeRange}
            samplingInterval={trackerChartConfig.samplingInterval}
            chartHeight={CHART_DEFAULT_HEIGHT}
            tagInfo={trackerChartConfig.tags}
            isRepetitiveRequest={true}
            overrideShowLegend={true}
          />
        )}
      </div>

      <div className={`${classes["side-card"]}`}>
        <Card title="Command" contentStyle={{ marginBottom: 0, alignItems: "flex-start", marginTop: "5px" }}>
          <TrackersCommand selectedTrackerIds={selectedTrackerIds} />
        </Card>
      </div>
    </div>
  );
};
export default Trackers;
