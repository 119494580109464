import { Collapse } from "antd";
import styled from "styled-components";
export const StyledPanel = styled(Collapse.Panel)`
&&& {
  border: none;
}
.ant-collapse-content {
  border: none;
}
.ant-collapse-expand-icon {
  color: #4EA9FF;
  line-weight: 10;
  weight: 600;
`;