import { useSelector } from "react-redux";
import DasDeploy from "../features/sites_configuration/das/DasDeploy";
import TagsHarvesterDeploy from "../features/sites_configuration/TagsHarvester/TagsHarvesterDeploy";
import Card from "../SolarGikLib/cards/Card";
import McsConfiguration from "../features/sites_configuration/mcs/McsConfiguration";
import DasConfiguration from "../features/sites_configuration/das/DasConfiguration";
import { RootState } from "../features/app/Store";
import SafeOverride from "../features/sites_configuration/safeOverride/SafeOverride";
import ModuleMetadataBindingTable from "../features/sites_configuration/moduleMetadata/ModuleMetadataBindingTable";
import { SimpleSgTabs } from "../SolarGikLib/tabs/SgTabs";

const tabs = [
  {
    label: "MCS Config",
    key: "mcsConfig",
    content: <McsConfiguration />,
    isSingleItem: false,
  },
  {
    label: "SiteGateway",
    key: "SiteGateway",
    content: <ModuleMetadataBindingTable />,
    isSingleItem: true,
  },
  {
    label: "DAS Config",
    key: "dasConfig",
    content: <DasConfiguration />,
    isSingleItem: false,
  },
  {
    label: "Das Deploy",
    key: "dasDeploy",
    content: (
      <Card>
        <DasDeploy />
      </Card>
    ),
    isSingleItem: true,
  },
  {
    label: "Tags Harvester Deploy",
    key: "tagsHarvesterDeploy",
    content: (
      <Card>
        <TagsHarvesterDeploy />
      </Card>
    ),
    isSingleItem: true,
  },
  {
    label: "Override Safe Mode",
    key: "safeModeOverride",
    content: (
      <Card wrapperStyle={{ width: "100%", height: "500px" }}>
        <SafeOverride />
      </Card>
    ),
    isSingleItem: true,
    shouldRender: (state: RootState) => state.user.sudoMode,
  },
];

const SiteConfiguration = () => {
  const tabsToRender = tabs.filter(
    (tab) => tab.shouldRender === undefined || useSelector(tab.shouldRender)
  );

  return <SimpleSgTabs tabs={tabsToRender} />;
};

export default SiteConfiguration;
