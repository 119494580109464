import { FC } from "react";

import classes from "./IconAndTextCellItem.module.css";

interface IconAndTextCellItemProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

const IconAndTextCellItem: FC<IconAndTextCellItemProps> = ({ Icon, text }) => {
  return (
    <div className={classes.row}>
      <Icon />
      <span>{text}</span>
    </div>
  );
};
export default IconAndTextCellItem;
