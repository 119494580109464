import axios from "axios";

import { IUserRecord, IUserRecordDto } from "./UserRecordsModels";
import APP_CONFIG from "../app/configuration/AppConfig";


const convertUserRecordDtoToUserRecord = (
  userRecordDto: IUserRecordDto
): IUserRecord => {
  return {
    eventDate: userRecordDto.EventDate,
    title: userRecordDto.Title,
    reporter: userRecordDto.Reporter,
    componentType: userRecordDto.ComponentType,
    componentName: userRecordDto.ComponentName,
    recordType: userRecordDto.RecordType,
    description: userRecordDto.Description,
    id: userRecordDto.Id,
    isEngineerOnly: userRecordDto.IsEngineerOnly
  };
};

export const addNewUserRecord = async (
  siteId: string,
  record: IUserRecordDto
): Promise<IUserRecord> => {
  const url =
    APP_CONFIG.serviceUrls.userRecordsUrlPrefix +
    APP_CONFIG.apiAddNewUserRecord(siteId);
  const httpResponse = await axios.post<IUserRecordDto>(url, record);
  const res = convertUserRecordDtoToUserRecord(httpResponse.data);
  return res;
};
export const queryUserRecords = async (
  siteId: string,
  creationTimeStart: Date,
  creationTimeEnd: Date
): Promise<IUserRecord[]> => {
  const url =
    APP_CONFIG.serviceUrls.userRecordsUrlPrefix +
    APP_CONFIG.apiQueryUserRecords(siteId);
  const config = {
    method: "GET",
    url: url,
    params: {
      creationTimeStart: creationTimeStart.getTime(),
      creationTimeEnd: creationTimeEnd.getTime(),
    },
  };
  const httpResponse = await axios.request<IUserRecordDto[]>(config);
  const userRecords = httpResponse.data.map((userRecordDto: IUserRecordDto) =>
    convertUserRecordDtoToUserRecord(userRecordDto)
  );
  return userRecords;
};
export const deleteUserRecord = async (
  siteId: string,
  recordId: number
): Promise<void> => {
  const url =
    APP_CONFIG.serviceUrls.userRecordsUrlPrefix +
    APP_CONFIG.apiDeleteUserRecord(siteId, recordId);
  await axios.delete(url);
};
