import { FC } from "react";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { CSSObject } from "@mui/material";

interface LightTooltipProps extends TooltipProps {
  customTooltipSx?: CSSObject;
}

const LightTooltip: FC<LightTooltipProps> = ({ children, customTooltipSx, ...props }) => {
  return (
    <Tooltip
      arrow
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            lineHeight: 1.5,
            cursor: "default",
            fontFamily: "var(--font-family)",
            boxShadow: "var(--tooltip-box-shadow)",
            backgroundColor: "var(--card-background-color)",
            borderRadius: "var(--tooltip-border-radius)",
            color: "var(--blue-color-primary)",
            flexWrap: "wrap",
            display: "inline-table",
            padding: "25px",
            ...customTooltipSx
          },
          "& .MuiTooltip-arrow": {
            color: "var(--white)",
            fontSize: "large",
          }
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default LightTooltip;
