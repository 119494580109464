import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ISiteMetadata,
  ISitesMetadataDictionary,
} from "../../sites/SiteModels";

export const defaultProperties: ISitesMetadataDictionary = {};
export const sitesMetadataSlice = createSlice({
  name: "multiSitesMetadata",
  initialState: defaultProperties,
  reducers: {
    setSiteMetadata: (
      state: ISitesMetadataDictionary,
      action: PayloadAction<ISiteMetadata>
    ) => {
      return {
        ...state,
        [action.payload.siteId]: { ...action.payload },
      };
    },
    setSitesMetadata: (
      state: ISitesMetadataDictionary,
      action: PayloadAction<ISitesMetadataDictionary>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const selectSitesTimeZoneOffsetInMinutes = createSelector(
  [
    (multiSitesMetadataState: ISitesMetadataDictionary, siteId: string) => ({
      multiSitesMetadataState,
      siteId,
    }),
  ],
  ({ multiSitesMetadataState, siteId }) => {
    if (multiSitesMetadataState[siteId]) {
      return multiSitesMetadataState[siteId].timeZoneOffsetInMinutes;
    } else {
      throw new Error(
        `siteMetadata store does not have the desired site: ${siteId}`
      );
    }
  }
);

export const sitesMetadataReducer = sitesMetadataSlice.reducer;
