import { FC, useState } from "react";

import { Send, Edit } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";

import ConfigItem from "./ConfigItem";
import classes from "./ItemConfig.module.css";
import { getConfigResourceAsync } from "./AppConfigAPI";
import { AppVersionViewModel } from "./AppConfigModels";
import { errorHandlerFunc } from "../../common/CommunicationErrorHandling";
import {
  COMMUNICATION_ZIP_HEADER,
  ZIP_FILE_SUFFIX,
} from "../../common/ConstantValues";
import { createAndDownloadBlobFile } from "../../common/DownloadFileUtils";
import { AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import { SiteAppType } from "./AppConfigModels";

interface ItemConfigProps {
  versionElement: AppVersionViewModel;
  isDisable: boolean;
  setAlertMessage: (message: AlertMessage | undefined) => void;
  activateVersionHandler: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  updateRemoteDescription: (
    path: string,
    description: string
  ) => Promise<boolean>;
  siteApp: SiteAppType;
}
const ItemConfig: FC<ItemConfigProps> = ({
  versionElement,
  isDisable,
  setAlertMessage,
  activateVersionHandler,
  updateRemoteDescription,
  siteApp,
}) => {
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [description, setDescription] = useState<string>(
    versionElement.description || ""
  );

  const [isDescriptionUpdated, setIsDescriptionUpdated] = useState(true);

  const [editing, setEditing] = useState(false);

  const updateRemoteDescriptionFunction = async (
    path: string,
    description: string
  ) => {
    setIsDescriptionUpdated(false);
    const res = await updateRemoteDescription(path, description);
    if (res) {
      setIsDescriptionUpdated(true);
    }
    return res;
  };

  const descriptionAdornmentClickHandler = async () => {
    if (!editing) {
      setEditing(true);
      return;
    }
    const success = await updateRemoteDescriptionFunction(
      versionElement.dtoVersion,
      description
    );
    if (success) {
      setEditing(false);
    }
  };

  const downloadFile = async (version: AppVersionViewModel) => {
    setDisableButton(true);
    try {
      setAlertMessage(undefined);
    } catch (error) {
      console.error(error);
    }
    try {
      const data = await getConfigResourceAsync(version.dtoVersion, siteApp);
      createAndDownloadBlobFile(
        data,
        version.dtoVersion,
        COMMUNICATION_ZIP_HEADER,
        ZIP_FILE_SUFFIX
      );
    } catch (error) {
      setAlertMessage({
        text: errorHandlerFunc(error, "Download file"),
        severity: "error",
      });
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <li key={versionElement.uiVersion} className={classes.version}>
      <ConfigItem
        isDisable={isDisable || disableButton}
        isActive={versionElement.isActive === true}
        configName={versionElement.uiVersion}
        downloadHandler={() => downloadFile(versionElement)}
        activateHandler={activateVersionHandler}
      />
      <TextField
        className={classes.description}
        size="small"
        label="Description"
        value={description}
        disabled={!editing}
        onChange={(e) => setDescription(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton onClick={descriptionAdornmentClickHandler}>
              {!editing && (
                <Edit style={{ color: "var(--blue-color-primary)" }} />
              )}
              {editing && (
                <Send
                  style={{
                    color: isDescriptionUpdated
                      ? "var(--blue-color-primary)"
                      : "grey",
                  }}
                />
              )}
            </IconButton>
          ),
        }}
      />
    </li>
  );
};
export default ItemConfig;
