import { useSelector } from "react-redux";

import classes from "./TableCellItem.module.css";
import {
  UNDEFINED_STR_FOR_DATE_TIME,
  formatDateToDayMonthYearHourMinuteSeconds,
  toDateInSiteTimeZone,
} from "../../features/app/DateTimeUtils";
import { RootState } from "../../features/app/Store";

const TableCellItemDate = (props: { value: number }) => {
  const siteTimeOffset = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes
  );
  const siteDate = props.value
    ? toDateInSiteTimeZone(new Date(props.value), siteTimeOffset)
    : null;
  const formattedDateTime = siteDate ? formatDateToDayMonthYearHourMinuteSeconds(siteDate) : UNDEFINED_STR_FOR_DATE_TIME;

  return <div className={classes.center}>{formattedDateTime}</div>;
};
export default TableCellItemDate;
