import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { repetitivePolling } from "../common/AsyncUtils";
import { RootState } from "../features/app/Store";
import { getSpecificTagsLatestAsync } from "../features/app/TagsAPI";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { multiSiteTagsActions } from "../features/app/store/multisiteTagsStore";
import { gaugeTagNames } from "../features/data_point/TagsNames";
import { selectSiteId } from "../features/sites/SiteStore";

const TagsPoller = () => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteId);
  const tagsMetadata = useSelector((state: RootState) => state.multiSitesTagsMetadata[siteId]);

  useEffect(() => {
    if (!tagsMetadata) {
      return;
    }

    const tagNamesToPoll = gaugeTagNames.filter(
      (tagName) => Object.hasOwn(tagsMetadata, tagName));

    if (!siteId || tagNamesToPoll.length == 0) {
      return;
    }

    const cntrlr = new AbortController();
    repetitivePolling(
      'pollTags',
      async () => {
        if (cntrlr.signal.aborted) {
          return;
        }
        const response = await getSpecificTagsLatestAsync(siteId, tagNamesToPoll);
        dispatch(
          multiSiteTagsActions.updateSiteTags({
            siteId: siteId,
            data: response,
          })
        )
      },
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenGetTags,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, [siteId, tagsMetadata]);

  return <></>;
};

export default TagsPoller;
