import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Agriculture.module.css";
import { repetitivePolling } from "../common/AsyncUtils";
import { valueToFixedNumber } from "../common/StringUtils";
import AgriAverageShadingImg from "../features/app/agri/AgriAverageShadingImg";
import AgriDataPointCard from "../features/app/agri/AgriDataPointCard";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { RootState } from "../features/app/Store";
import { getSpecificTagsLatestAsync } from "../features/app/TagsAPI";
import CardSiteStyledLineChartWrapper from "../features/charts_ui/CardSiteStyledLineChartWrapper";
import {
  CHART_DEFAULT_HEIGHT,
  createShadingPercentChartConfig,
  createSoilChartConfig,
  createWeatherForAgriChartConfig,
  SHADING_TITLE,
  SOIL_TITLE,
  WEATHER_TITLE,
} from "../features/common_chart_config/ChartConfig";
import { IStringToTimeValueDictionary } from "../features/data_point/models/TagsModels";
import Card from "../SolarGikLib/cards/Card";
import AgriPlan from "../features/app/agri/agriPlan/AgriPlan";
import { selectSiteId } from "../features/sites/SiteStore";

const Agriculture = () => {
  const siteId = useSelector(selectSiteId);
  const zoneOffsetTimeInMinutes = useSelector(
    (state: RootState) => state.site.timeZoneOffsetInMinutes
  );
  const trackerIds = useSelector((state: RootState) => state.site.trackerIds);
  const shadingChartConfig = createShadingPercentChartConfig(
    trackerIds,
    zoneOffsetTimeInMinutes,
    siteId
  );

  const weatherChartConfig = createWeatherForAgriChartConfig(
    zoneOffsetTimeInMinutes,
    siteId
  );

  const soilChartConfig = createSoilChartConfig(
    zoneOffsetTimeInMinutes,
    siteId
  );
  const [averageShadingPercent, setAverageShadingPercent] = useState("");

  const fetchShadingPercent = async () => {
    const shadingTags: string[] = shadingChartConfig.tags.map((tag) => tag.id);
    const response: IStringToTimeValueDictionary =
      await getSpecificTagsLatestAsync(siteId, shadingTags);
    if (response) {
      const values = Object.values(response);
      const sum = values.reduce((acc, curr) => acc + curr.value, 0);
      //add to Fixed
      const average = sum / values.length;
      const shadingPercent = valueToFixedNumber(average, 2);
      setAverageShadingPercent(shadingPercent);
    }
  };

  useEffect(() => {
    const cntrlr = new AbortController();
    repetitivePolling(
      "fetchShadingPercent",
      fetchShadingPercent,
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, [siteId]);
  return (
    <div className={classes.content}>
      <div className={classes["grid-container"]}>
        <div className={`${classes["agri-table"]}`}>
          <AgriPlan />
        </div>
        <div className={`${classes["actual-shading"]}`}>
          <CardSiteStyledLineChartWrapper
            siteId={shadingChartConfig.siteId}
            title={SHADING_TITLE}
            rangeTimeInZoneTime={{
              start: shadingChartConfig.fromDate,
              end: shadingChartConfig.toDate,
            }}
            samplingInterval={shadingChartConfig.samplingInterval}
            chartHeight={CHART_DEFAULT_HEIGHT}
            tagInfo={shadingChartConfig.tags}
            isRepetitiveRequest={true}
          />
        </div>
        <div className={`${classes["average-shading"]}`}>
          <Card title={"Average shading"}>
            <AgriAverageShadingImg shadingPercent={+averageShadingPercent} />
          </Card>
        </div>

        <div className={`${classes["weather-chart"]}`}>
          <CardSiteStyledLineChartWrapper
            siteId={siteId}
            title={WEATHER_TITLE}
            tagInfo={weatherChartConfig.tags}
            samplingInterval={weatherChartConfig.samplingInterval}
            rangeTimeInZoneTime={{
              start: weatherChartConfig.fromDate,
              end: weatherChartConfig.toDate,
            }}
            chartHeight={CHART_DEFAULT_HEIGHT}
            isRepetitiveRequest={true}
          />
        </div>
        <div className={`${classes["soil-chart"]}`}>
          <CardSiteStyledLineChartWrapper
            siteId={soilChartConfig.siteId}
            title={SOIL_TITLE}
            rangeTimeInZoneTime={{
              start: soilChartConfig.fromDate,
              end: soilChartConfig.toDate,
            }}
            samplingInterval={soilChartConfig.samplingInterval}
            chartHeight={CHART_DEFAULT_HEIGHT}
            tagInfo={soilChartConfig.tags}
            isRepetitiveRequest={true}
            showChartTitle={false}
          />
        </div>
        <div className={`${classes["agri-sum"]}`}>
          <Card>
            <AgriDataPointCard />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Agriculture;
