import React from "react";

import { Close } from "@mui/icons-material";
import { Dialog, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { convertEnumResultCodeToString } from "./TrackersCommandResultCodeEnumToStringMapper";
import classes from "./TrackersCommandResultPopUp.module.css";
import { getResultTitleMessage } from "./UiTrackersCommandsUtils";
import { dialogStyling } from "../../../common/Mui/MuiStyling";
import { selectCurrentTrackerCommands } from "../../app/store/TrackersCommandsStore";
interface TrackersCommandResultPopUpProps {
  open: boolean;
  onClose: () => void;
}
const TrackersCommandResultPopUp: React.FC<TrackersCommandResultPopUpProps> = ({
  open,
  onClose,
}) => {
  const trackersCommands = useSelector(selectCurrentTrackerCommands);
  return (
    <Dialog open={open} onClose={onClose} sx={dialogStyling}>
      <div className={classes["dialog-header"]}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <div className={classes["result-title"]}>
        <Typography variant="h6">
          {getResultTitleMessage({
            commandType: trackersCommands.commandType,
            elevation: trackersCommands.elevation
              ? trackersCommands.elevation
              : undefined,
          })}
        </Typography>
      </div>
      <div className={classes.results}>
        {Object.keys(trackersCommands.result).length > 0 &&
          Object.keys(trackersCommands.result).map((trackerId: string) => {
            const resultMessage = trackersCommands.result[trackerId];
            return (
              <div className={classes["result-tracker"]} key={trackerId}>
                <div className={classes["result-item"]}>
                  <Typography variant="subtitle1">
                    Tracker Id: {trackerId}
                  </Typography>
                </div>
                <div className={classes["result-item"]}>
                  <Typography variant="subtitle1">
                    Result:{" "}
                    {convertEnumResultCodeToString(
                      resultMessage,
                      trackersCommands.commandType
                    )}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    </Dialog>
  );
};
export default TrackersCommandResultPopUp;
