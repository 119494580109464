import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../app/Store";
import { initializeClarity } from "./clarity";
import { UserInitializationState } from "../user/UserStore";
import appConfig from "../app/configuration/AppConfig";

export const ClarityInit = () => {
  const location = useLocation();
  const userData = useSelector((state: RootState) => state.user);
  // when navigation occurs, we need to re-identify the user at clarity (clarity requriement)
  useEffect(() => {
    if (!appConfig.clarityProjectId) {
      return;
    }
    if (userData.initializationState === UserInitializationState.Initialized) {
      initializeClarity(appConfig.clarityProjectId, appConfig.portalVersion, userData);
    }
  }, [location, userData]);
  return <></>
}