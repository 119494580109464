import {
  IStringToTagDataDictionary,
  ITagToMetaDataDictionary,
  TagTimeValidity,
} from "../data_point/models/TagsModels";

export interface ISiteToTagsMetadata {
  [key: string]: ITagToMetaDataDictionary;
}
export interface ISiteToTags {
  [key: string]: IStringToTagDataDictionary;
}
export interface ITagDataWithMetaUnit {
  value: number | undefined;
  valueAsStr: string;
  unit: string | null;
  time: Date;
  valueExist: boolean;
  timeValidity: TagTimeValidity;
}

export interface IIssuesSeverityCounts {
  amountOfIssuesLowSeverity?: number;
  amountOfIssuesMildSeverity?: number;
  amountOfIssuesHighSeverity?: number;
}

export interface ISiteUIRowData extends IIssuesSeverityCounts {
  [key: string]: ITagDataWithMetaUnit | string | undefined | number;
  siteId: string;
}

export interface ITableTagData {
  value: string;
  unit: string;
  time: Date;
  tagName: string;
}

export enum ErrorType {
  High = "high",
  Mild = "mild",
  Low = "low",
}
