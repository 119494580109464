import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMultipleTagsMetaData } from "../app/TagsAPI";
import { multiSitesIssuesSlice } from "../app/store/IssuesStore";
import { multiSitesVisualInfoSlice } from "../app/store/SiteVisualInfoStore";
import { sitesMetadataSlice } from "../app/store/SitesStore";
import { multiTagsMetadataSlice } from "../app/store/TagsMetadataStore";
import { getAllSitesIssuesMetadataAsync } from "../faults/issues/IssuesAPI";
import { getSitesVisualInformation } from "../site_visual_info/SiteVisualInfoApi";
import { getSitesMetadataAsync } from "../sites/SiteAPI";
import { fetchAlertMetadata } from "../app/store/AlertsStore";
import { AppDispatch } from "../app/Store";

const useSetupTagsInfo = ({ siteIds }: { siteIds: string[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const fetchTasks = [];
      fetchTasks.push(
        getMultipleTagsMetaData(siteIds).then((tagsMetaDataResponse) => {
          dispatch(
            multiTagsMetadataSlice.actions.setTagsMetaData(tagsMetaDataResponse)
          );
        })
      );
      fetchTasks.push(
        getSitesMetadataAsync(siteIds).then((siteMetaData) => {
          dispatch(sitesMetadataSlice.actions.setSitesMetadata(siteMetaData));
        })
      );
      fetchTasks.push(
        getSitesVisualInformation(siteIds).then((sitesVisualInformation) => {
          dispatch(
            multiSitesVisualInfoSlice.actions.setSitesVisualInfo(
              sitesVisualInformation
            )
          );
        })
      );
      fetchTasks.push(
        getAllSitesIssuesMetadataAsync().then((response) => {
          dispatch(
            multiSitesIssuesSlice.actions.setIssuesMetaData({
              issuesMetadata: response,
              sites: siteIds,
            })
          );
        })
      );
      try {
        dispatch(fetchAlertMetadata());
        await Promise.all(fetchTasks);
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    };

    if (siteIds.length === 0) {
      return;
    }
    fetchData();
  }, [siteIds]);
  return { isLoading, error };
};
export default useSetupTagsInfo;
